import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../layouts/Header'
import Sidebar from '../layouts/Sidebar'
import '../_components/antd.css'
import { Pagination, Table } from 'antd'
import Popup from 'reactjs-popup'
import Authcontrollers from '../../src/api/authController'
import { onShowSizeChange, itemRender } from '../_components/paginationfunction'
import AddVendor from '../vendors/addVendor'
import AddUsersVendor from '../_components/usersrolefiltter'
// import Select from 'react-select'
// import Data from '../assets/jsons/user'
// import FeatherIcon from "feather-icons-react";

const Users = () => {
  const [shows, setShows] = useState(false)

  const [menu, setMenu] = useState(false)
  const [show, setShow] = useState(false)

  const toggleMobileMenu = () => {
    setMenu(!menu)
  }

  const userRole = [
    {
      label: 'PARENT',
      value: 'PARENT',
    },
    {
      label: 'STUDENT',
      value: 'STUDENT',
    },
    {
      label: 'COMPANY',
      value: 'COMPANY',
    },
    {
      label: 'COMPANY STAFF',
      value: 'COMPANY STAFF',
    },
    {
      label: 'VROAR STAFF',
      value: 'VROAR STAFF',
    },
  ]
  const [role, setRole] = useState()
  const selecthandler = (e) => {
    const { value } = e.target
    // setRole(value)
    Users_data(value)
  }

  const [UsersList, setUsersList] = useState([])
  const [userSelect, setUserSelect] = useState([])
  const Users_data = (value) => {
    let body = value
      ? {
          group: value,
        }
      : ''

    Authcontrollers.getUsers(body)
      .then((res) => {
        console.log('hello', res)
        setUsersList(res.data.data.docs)
        setUserSelect(res.data.data.docs)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // const datasource = Data?.Data;
  // console.log(datasource);

  // {
  //   title: "#",
  //   dataIndex: "id",
  //   sorter: (a, b) => a.id.length - b.id.length,
  // },
  const columns = [
    {
      title: 'userName',
      dataIndex: 'userName',
      render: (userName) =>
        userName && userName ? userName : 'No userName found ',
      key: 'userName',
      sorter: (a, b) => a.userName.length - b.userName.length,
    },
    {
      title: 'salutation',
      dataIndex: 'salutation',
      render: (salutation) =>
        salutation && salutation ? salutation : 'No salutation found ',
      key: 'salutation',
      sorter: (a, b) => a.salutation.length - b.salutation.length,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name) => (name && name ? name : 'No name found '),
      key: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
    },
    // {
    //   title: "User Name",
    //   dataIndex: "userName",
    //   render: (text, record) => (
    //     <>
    //       <h2 className="table-avatar">
    //         <Link to="/profile" className="avatar avatar-sm me-2">
    //           <img
    //             className="avatar-img rounded-circle"
    //             src={record.Img}
    //             alt="User Image"
    //           />
    //         </Link>
    //         <Link to="/profile">
    //           {record.Name} <span>{record.Email}</span>
    //         </Link>
    //       </h2>
    //     </>
    //   ),
    //   sorter: (a, b) => a.Name.length - b.Name.length,
    // },
    {
      title: 'Mobile Number',
      dataIndex: 'phoneNo',
      render: (phoneNo) => (phoneNo && phoneNo ? phoneNo : 'No phoneNo found '),
      key: 'phoneNo',
      sorter: (a, b) => a.phoneNo.length - b.phoneNo.length,
    },
    {
      title: 'Role',
      dataIndex: 'roleName',
      render: (roleName) =>
        roleName && roleName ? roleName : 'No roleName  found ',
      key: 'roleName',

      sorter: (a, b) => a.roleName.length - b.roleName.length,
    },
    // {
    //   title: 'PhoneNo',
    //   dataIndex: 'name',
    //   render: (text, record) => (
    //     <div>
    //       {text === 'Online' && (
    //         <span className="badge badge-pill bg-success-light">{text}</span>
    //       )}
    //       {text === '2 days ago' && (
    //         <span className="badge badge-pill bg-ash-gray text-gray-light">
    //           {text}
    //         </span>
    //       )}
    //       {text === '1 hour ago' && (
    //         <span className="badge badge-pill bg-ash-gray text-gray-light">
    //           {text}
    //         </span>
    //       )}
    //       {text === '10 mins ago' && (
    //         <span className="badge badge-pill bg-ash-gray text-gray-light">
    //           {text}
    //         </span>
    //       )}
    //     </div>
    //   ),
    //   sorter: (a, b) => a.Activity.length - b.Activity.length,
    // },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (email) => (email && email ? email : 'No email found '),
      key: 'email',
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: 'CreatedAt',
      dataIndex: 'createdAt',
      // sorter: (a, b) => a.createdAt.length - b.createdAt.length,
    },

    {
      title: 'Twitter socialLink',
      dataIndex: 'socialLink',
      render: (socialLink) =>
        socialLink && socialLink.twitter
          ? socialLink.twitter
          : 'No Twitter  found ',
      key: 'socialLink',

      // sorter: (a, b) => a.socialLink.twitter.length - b.socialLink.twitter.length,
    },
    {
      title: 'Facebook socialLink',
      dataIndex: 'socialLink',
      render: (socialLink) =>
        socialLink && socialLink.facebook
          ? socialLink.facebook
          : 'No facebook  found ',
      key: 'socialLink',

      // sorter: (a, b) => a.socialLink.twitter.length - b.socialLink.twitter.length,
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'Status',
    //   render: (text, record) => (
    //     <div>
    //       {text === 'Active' && (
    //         <span className="badge badge-pill bg-success-light">{text}</span>
    //       )}
    //       {text === 'Restricted' && (
    //         <span className="badge badge-pill bg-ash-gray text-gray-light">
    //           {text}
    //         </span>
    //       )}
    //     </div>
    //   ),
    //   sorter: (a, b) => a.Status.length - b.Status.length,
    // },
    // {
    //   title: "Action",
    //   dataIndex: "Action",
    //   render: (text, record) => (
    //     <>
    //       <div className="d-flex align-items-center">
    //         <div className="dropdown dropdown-action">
    //           <Link
    //             to="#"
    //             className=" btn-action-icon "
    //             data-bs-toggle="dropdown"
    //             aria-expanded="false"
    //           >
    //             <i className="fas fa-ellipsis-v" />
    //           </Link>
    //           <div className="dropdown-menu dropdown-menu-right">
    //             <ul>
    //               <li>
    //                 <Link className="dropdown-item" to="/edit-users">
    //                   <i className="far fa-eye me-2" />
    //                   Edit
    //                 </Link>
    //               </li>
    //               <li>
    //                 <Link
    //                   className="dropdown-item"
    //                   to="#"
    //                   data-bs-toggle="modal"
    //                   data-bs-target="#delete_modal"
    //                 >
    //                   <i className="far fa-trash-alt me-2" />
    //                   Delete
    //                 </Link>
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //     </>
    //   ),
    //   sorter: (a, b) => a.Action.length - b.Action.length,
    // },
  ]

  useEffect(() => {
    Users_data()
  }, [])

  return (
    <>
      <div className={`main-wrapper ${menu ? 'slide-nav' : ''}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />

        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Page Header */}
            <div className="page-header">
              {/* {/* <div className="content-page-header ">
                <h5>Users</h5> */}
              {/* <div className="list-btn">
                  <ul className="filter-list">  */}
              {/* <li>
                      <Link className="btn btn-filters w-auto popup-toggle"
                      onClick={() => setShow(!show)}
                      >
                        <span className="me-2">
                          {/* <i className="fe fe-filter" /> */}
              {/* <FeatherIcon icon="filter"/> */}
              {/* </span>
                        Filter{" "}
                      </Link>
                    </li> */}
              {/* <li>
                      <Link className="btn-filters" to="#">
                        <span>
                          {/* <i className="fe fe-grid" /> */}
              {/* <FeatherIcon icon="grid"/>
                        </span>{" "}
                      </Link>
                    </li> */}
              {/* <li>
                      <Link
                        className="active btn-filters"
                        to="#"
                      >
                        <span>
                          {/* <i className="fe fe-list" /> */}
              {/* <FeatherIcon icon="list"/> */}
              {/* </span>{" "}
                      </Link>
                    </li> */}
              {/* <li>
                      <Link className="btn login-bttn" to="/add-user">
                        <i
                          className="fa fa-plus-circle me-2"
                          aria-hidden="true"
                        />
                        Add user
                      </Link>
                    </li> */}
              {/* </ul>
                </div> */}
              {/* </div> */}
            </div>
            {/* /Page Header */}
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ marginBottom: '30px', width: '100%' }}
            >
              <div className="color-dark">
                <h2> Users</h2>
              </div>

              <div>
                <div className="list-btn">
                  <ul className="filter-list">
                    <li>
                      <Link
                        onClick={() => setShows(!shows)}
                        className="btn btn-primary me-2"
                      >
                        Filter
                      </Link>
                    </li>
                  </ul>
                </div>
                <AddUsersVendor setShow={setShows} show={shows} />
              </div>

              {/* <div>
            <Popup trigger=
                {<button>Filter</button>}
                position="right right">
                <div>
                    
                </div>
            </Popup>
        </div> */}

              {/* <div className="ms-4 mb-1">
                <select
                  onChange={selecthandler}
                  className="Organisation_selectbar py-1"
                >
                  <option selected hidden>
                    Select..
                  </option>
                  {userRole.map((val, i) => (
                    <option value={val.value} key={`${i}`}>
                      {val.label}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body">
                    <div className="table-responsive table-hover">
                      <Table
                        pagination={{
                          total: UsersList.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[10]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        columns={columns}
                        dataSource={UsersList}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AddVendor setShow={setShow} show={show} />

        {/* <div className="modal custom-modal fade" id="delete_modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Delete Purchases</h3>
                  <p>Are you sure want to delete?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <button
                        type="reset"
                        data-bs-dismiss="modal"
                        className="w-100 btn login-bttn paid-continue-btn"
                      >
                        Delete
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        type="submit"
                        data-bs-dismiss="modal"
                        className="w-100 btn login-bttn paid-cancel-btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default Users
