import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import CustomButton from "../_components/buttons/customButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Loading from "../_components/Loading";
import { roleController } from "../api/roleManagement";
import { getActionsbyModuleName } from "../utils/functions";
import Input from "../_components/input";

const RoleManagement = () => {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const navigate = useNavigate();
  const [actions, setActions] = useState({});
  const [loading, setLoading] = useState(true);
  const [roleList, setRoleList] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);

  const fetchRoles = () => {
    roleController
      .getRoles()
      .then((res) => {
        const roles = res.data.data.map((role) => ({
          label: role.roleName,
          value: role.roleName,
        }));
        setRoleList(res.data.data);
        setRoleOptions(roles);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const fetchActions = () => {
    const moduleActions = getActionsbyModuleName("Role Management");
    setActions(moduleActions);
  };


  useEffect(() => {
    fetchRoles();
    fetchActions();
  }, []);

  const roleHeader =
    actions?.ADD && actions?.UPDATE
      ? [{ name: "Role Name" }, { name: "Created On" }, { name: "Actions" }]
      : [{ name: "Role Name" }, { name: "Created On" }];

  const AddRole = () => {
    const [show, setShow] = useState(false);
    const roleHandler = (e) => {
      if (e.value === "Add Role") {
        setShow(true);
      } else {
        setShow(false);
      }
    };

    return (
      <div className="mt-4" style={{ width: "500px" }}>
        <div className="container">
          <div className="row my-2">
            <div className="col-sm-6">
              {show ? (
                <Input
                  placeholder="Role Name"
                  width="100%"
                  padding="8px"
                  border="1px solid #000"
                  rounded="5px"
                />
              ) : (
                <Select
                  styles={{
                    control: (baseStyle) => ({
                      ...baseStyle,
                      border: "1px solid #000",
                      background: "#fff",
                      fontSize: "12px",
                    }),
                  }}
                  options={[
                    { label: "Add Role", value: "Add Role" },
                    ...roleOptions,
                  ]}
                  placeholder="Select Roles"
                  onChange={(e) => roleHandler(e)}
                />
              )}
            </div>
            <div className="col-sm-6">
              <Select
                styles={{
                  control: (baseStyle) => ({
                    ...baseStyle,
                    border: "1px solid #000",
                    background: "#fff",
                    fontSize: "12px",
                  }),
                }}
                placeholder="Select Modules"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={toggleMobileMenu} />
      <Sidebar />

      <div className="page-wrapper">
        <div className="p-2">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <CustomButton
                className="btn btn-primary"
                width="20px"
                height="20px"
                padding="0px"
                rounded="0px"
              ></CustomButton>
              <h5 className="ml-2">Role Management</h5>
            </div>
            {actions?.ADD && (
              <CustomButton
                className="btn btn-primary"
                onClick={() => navigate("/add-role")}
              >
                Add Role <FontAwesomeIcon icon={faAdd} />
              </CustomButton>
            )}
          </div>

          {loading ? (
            <Loading
              type="bars"
              color="#f15d17"
              width={25}
              height={25}
              className="m-auto"
            />
          ) : roleList.length > 0 ? (
            <div className="mt-4">
              <Table hover bordered bgcolor="#fff">
                <thead>
                  <tr style={{ background: "#eee" }}>
                    {roleHeader.map((val, i) => (
                      <th className="fw-semibold f-15" key={i}>
                        {val.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {roleList.map((val, i) => (
                    <tr key={i}>
                      <td>{val.roleName}</td>
                      <td>
                        {val.createdAt &&
                          moment(val.createdAt).format("MMM-DD-YYYY")}
                      </td>
                      {actions?.UPDATE && (
                        <td>
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() =>
                              navigate("/role-details", {
                                state: { data: val },
                              })
                            }
                            className="pointer"
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <p className="mt-4 text-center">No roles added yet</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoleManagement;
