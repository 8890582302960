import {
  DISCOUNT_TYPE,
  INTERNSHIP_STATUS,
  PLAN_DURATION_TYPE,
  PROMOTION_SCENARIOS,
  PROMOTION_TYPE,
  SALARY_PAY_DURATION,
  SUBSCRIPTION_TIER,
  USER_STATUS,
  WORK_MODE,
} from "./enum";

export const promotionList = [
  {
    label: PROMOTION_TYPE.DISCOUNT,
  },
  {
    label: PROMOTION_TYPE.OFFER,
  },
  {
    label: PROMOTION_TYPE.PROMOTION,
  },
  {
    label: PROMOTION_TYPE.VOUCHER,
  },
];

export const discountType = [
  {
    label: DISCOUNT_TYPE.PERCENTAGE,
  },
  {
    label: DISCOUNT_TYPE.FIXED,
  },
];

export const promotionScenariosList = [
  {
    label: PROMOTION_SCENARIOS.ALL,
  },
  {
    label: PROMOTION_SCENARIOS.FIRST_TIME_LOGIN,
  },
  {
    label: PROMOTION_SCENARIOS.SUBSCRIPTION,
  },
];

export const tabs = [
  {
    label: USER_STATUS.ALL,
  },
  {
    label: USER_STATUS.ACTIVE,
  },
  {
    label: USER_STATUS.InActive,
  },
];

export const tableHeader = [
  {
    name: "Name",
    icon: true,
  },

  {
    name: "Phone No",
    icon: false,
  },

  {
    name: "Registered On",
    icon: true,
  },
  {
    name: "Last Login",
    icon: false,
  },
  {
    name: "Status",
    icon: false,
  },
  // {
  //   name: "Actions",
  //   icon: false,
  // },
];

export const subscriptionStatus = [
  { label: USER_STATUS.ACTIVE },
  { label: USER_STATUS.InActive },
];

export const subscriptionTierList = [
  {
    label: SUBSCRIPTION_TIER.BASIC,
  },
  {
    label: SUBSCRIPTION_TIER.PREMIUM,
  },
];

export const subscriptioDurationPlans = [
  {
    label: PLAN_DURATION_TYPE.MONTHLY,
  },
  {
    label: PLAN_DURATION_TYPE.YEARLY,
  },
];

export const INTERNSHIPSTATUSARRAY = [
  {
    label: INTERNSHIP_STATUS.ALL,
  },

  {
    label: INTERNSHIP_STATUS.AWAITING,
  },
  {
    label: INTERNSHIP_STATUS.PUBLISHED,
  },
];

export const INTERNSHIP_WORK_MODE = [
  {
    label: WORK_MODE.ON_SITE,
  },
  {
    label: WORK_MODE.REMOTE,
  },
  {
    label: WORK_MODE.Hybrid,
  },
];

export const SALARYPAYDURATION_ARRAY = [
  {
    label: SALARY_PAY_DURATION.HOUR,
  },
  {
    label: SALARY_PAY_DURATION.ANNUM,
  },
];

export const skillsOptions = [
  {
    value: "HTML",
    label: "HTML",
  },
  {
    value: "CSS",
    label: "CSS",
  },
  {
    value: "JAVA",
    label: "JAVA",
  },
  {
    value: "JAVASCRIPT",
    label: "JAVASCRIPT",
  },
  {
    value: "NODE JS",
    label: "NODE JS",
  },
  {
    value: "REACT JS",
    label: "REACT JS",
  },
  {
    value: "ANGULAR",
    label: "ANGULAR",
  },
  {
    value: "VUE JS",
    label: "VUE JS",
  },
  {
    value: "GO LANG",
    label: "GO LANG",
  },
  {
    value: "REACT NATIVE",
    label: "REACT NATIVE",
  },
  {
    value: "FLUTTER",
    label: "FLUTTER",
  },
  {
    value: "PHP",
    label: "PHP",
  },
  {
    value: "Software Developer",
    label: "Software Developer",
  },
  {
    value: "Product Manager",
    label: "Product Manager",
  },
  {
    value: "Data Science",
    label: "Data Science",
  },
  {
    value: "Python",
    label: "Python",
  },
  {
    value: "Magento",
    label: "Magento",
  },
  {
    value: "Shopify",
    label: "Shopify",
  },
  {
    value: "Wordpress",
    label: "Wordpress",
  },
  {
    value: "SEO",
    label: "SEO",
  },
  {
    value: "Adobe Photoshop",
    label: "Adobe Photoshop",
  },
  {
    value: "Figma",
    label: "Figma",
  },
  {
    value: "Adobe XD",
    label: "Adobe XD",
  },
  {
    value: "AI",
    label: "AI",
  },
  {
    value: "LLB",
    label: "LLB",
  },
  { value: "Programming Languages", label: "Programming Languages" },
  { value: "Software Development", label: "Software Development" },
  { value: "Web Development", label: "Web Development" },
  { value: "Database Management", label: "Database Management" },
  { value: "Version Control", label: "Version Control" },
  { value: "Problem Solving", label: "Problem Solving" },
  { value: "Teamwork", label: "Teamwork" },
  { value: "Communication", label: "Communication" },
  { value: "Medical Skills", label: "Medical Skills" },
  { value: "Critical Thinking", label: "Critical Thinking" },
  { value: "Digital Marketing", label: "Digital Marketing" },
  { value: "Data Analysis", label: "Data Analysis" },
  { value: "Mechanical design", label: "Mechanical design" },
  { value: "Thermodynamics", label: "Thermodynamics" },
  { value: "Fluid dynamics", label: "Fluid dynamics" },
  { value: "AutoCAD", label: "AutoCAD" },
  { value: "SolidWorks", label: "SolidWorks" },
  { value: "CATIA", label: "CATIA" },
  { value: "Campaign ideation", label: "Campaign ideation" },
  { value: "Content development", label: "Content development" },
  { value: "Market research", label: "Market research" },
  { value: "Branding", label: "Branding" },
  {
    value: "Electronic health records (EHR)",
    label: "Electronic health records (EHR)",
  },
  { value: "Medical devices", label: "Medical devices" },
  { value: "Compassion", label: "Compassion" },
  { value: "Attention to Detail", label: "Attention to Detail" },
  { value: "Leadership", label: "Leadership" },
  { value: "Negotiation", label: "Negotiation" },
  { value: "Conflict resolution", label: "Conflict resolution" },
  { value: "Project Management", label: "Project Management" },
  { value: "Creative Thinking", label: "Creative Thinking" },
  { value: "Public speaking", label: "Public speaking" },
  { value: "Foreign Languages", label: "Foreign Languages" },
  { value: "Financial Literacy", label: "Financial Literacy" },
  { value: "Resource allocation", label: "Resource allocation" },
  { value: "Design optimization", label: "Design optimization" },
  { value: "Instructional design", label: "Instructional design" },
  { value: "Training development", label: "Training development" },
  { value: "Market research", label: "Market research" },
  { value: "Email marketing", label: "Email marketing" },
  { value: "Data research", label: "Data research" },
  { value: "Information literacy", label: "Information literacy" },
  { value: "Physical fitness", label: "Physical fitness" },
  { value: "Nutrition knowledge", label: "Nutrition knowledge" },
  { value: "Stress management", label: "Stress management" },
  { value: "Adaptability", label: "Adaptability" },
  { value: "Self-awareness", label: "Self-awareness" },
  { value: "Relationship management", label: "Relationship management" },
  {
    value: "Moral and ethical decision-making",
    label: "Moral and ethical decision-making",
  },
  { value: "Product management", label: "Product management" },
  { value: "Market analysis", label: "Market analysis" },
  { value: "Market segmentation", label: "Market segmentation" },
  { value: "Statistical analysis", label: "Statistical analysis" },
  { value: "Database administration", label: "Database administration" },
  { value: "Quality control", label: "Quality control" },
  { value: "Supply chain management", label: "Supply chain management" },
  { value: "Digital design", label: "Digital design" },
  {
    value: "User experience (UX) design",
    label: "User experience (UX) design",
  },
  {
    value: "User interface (UI) design",
    label: "User interface (UI) design",
  },
  {
    value: "Content management systems (CMS)",
    label: "Content management systems (CMS)",
  },
  {
    value: "Search engine optimization (SEO)",
    label: "Search engine optimization (SEO)",
  },
  { value: "Social media management", label: "Social media management" },
  { value: "Data visualization", label: "Data visualization" },
  { value: "Market research analysis", label: "Market research analysis" },
  { value: "Business analysis", label: "Business analysis" },
  { value: "Financial modeling", label: "Financial modeling" },
  { value: "Forecasting", label: "Forecasting" },
  { value: "Product development", label: "Product development" },
  { value: "Market trends analysis", label: "Market trends analysis" },
  {
    value: "Customer relationship management (CRM)",
    label: "Customer relationship management (CRM)",
  },
  { value: "Public relations", label: "Public relations" },
  { value: "Time management", label: "Time management" },
  {
    value: "Information technology (IT) support",
    label: "Information technology (IT) support",
  },
  { value: "Troubleshooting", label: "Troubleshooting" },
  { value: "Network security", label: "Network security" },
  { value: "Data security", label: "Data security" },
  { value: "Cloud computing", label: "Cloud computing" },
  { value: "Machine learning", label: "Machine learning" },
  { value: "Artificial intelligence", label: "Artificial intelligence" },
  { value: "Data science", label: "Data science" },
  { value: "Big data analytics", label: "Big data analytics" },
  {
    value: "Environmental sustainability",
    label: "Environmental sustainability",
  },
  { value: "Data mining", label: "Data mining" },
];

export const waitlistTableHeader = [
  {
    label: "Name",
  },
  {
    label: "Email",
  },
  {
    label: "Phone No",
  },
  {
    label: "School",
  },
  {
    label: "Grade",
  },
  {
    label: "Role",
  },
  {
    label: "Joined On",
  },
];
