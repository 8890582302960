import React from "react";
import Sidebar from "../layouts/Sidebar";
import Header from "../layouts/Header";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

const SubscriptionDetails = () => {
  const {subscriptionId} = useParams();


  return (
    <div>
      <Sidebar />
      <Header />
      <div className="page-wrapper">
        <Box sx={{ p: 2 }}>
          <Typography>Hello</Typography>
        </Box>
      </div>
    </div>
  );
};

export default SubscriptionDetails;
