import {
  IconButton,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { USER_STATUS } from "../utils/enum";
import Loading from "react-loading";
import { Edit } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Visibility } from "@mui/icons-material";

const tableHeader = [
  { label: "Title" },
  { label: "Start Date" },
  { label: "End Date" },
  { label: "Monthly Price" },
  { label: "Annual Price" },
  { label: "Status" },
  { label: "Actions" },
];

const subscriptionStatus = [
  { label: USER_STATUS.ACTIVE },
  { label: USER_STATUS.InActive },
];

const SubscriptionTable = ({
  data,
  setSubscriptionStatuses,
  subscriptionStatuses,
  onStatusChange,
  loading,
}) => {
  useEffect(() => {
    if (data && data.docs) {
      const initialStatuses = data.docs.reduce((acc, curr) => {
        acc[curr._id] = curr.status;
        return acc;
      }, {});
      setSubscriptionStatuses(initialStatuses);
    }
  }, [data]);

  const navigate = useNavigate();
  const editInternship = (id) => {
    navigate(`/subscription-management/edit-subscription/${id}`);
  };

  const selectHandler = (id) => (e) => {
    setSubscriptionStatuses((prev) => ({
      ...prev,
      [id]: e.target.value,
    }));

    onStatusChange({ id, status: e.target.value });
  };

  if (loading) {
    return (
      <Loading
        type="bars"
        className="m-auto"
        width={20}
        height={20}
        color="#000"
      />
    );
  }

  if (data?.docs?.length === 0) {
    return (
      <Typography sx={{ fontSize: 14, textAlign: "center", my: 3 }}>
        No data Found
      </Typography>
    );
  }

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#d7d7d7" }}>
              {tableHeader.map((val, i) => (
                <TableCell key={i}>
                  <Typography fontSize={15} fontWeight={550}>
                    {val.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.docs.map((val) => (
              <TableRow key={val.id}>
                <TableCell>
                  <Typography fontSize={14} textTransform={"capitalize"}>
                    {val.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontSize={14}>
                    {moment.unix(val.startTimestamp).format("DD-MM-YYYY")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontSize={14}>
                    {val.endTimestamp
                      ? moment.unix(val.endTimestamp).format("DD-MM-YYYY")
                      : "--"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontSize={14}>$ {val.monthlyPrice}</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontSize={14}>$ {val.yearlyPrice}</Typography>
                </TableCell>
                <TableCell>
                  <Select
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        backgroundColor:
                          subscriptionStatuses[val._id] === USER_STATUS.ACTIVE
                            ? "#00800049"
                            : "#ff000049",
                        color:
                          subscriptionStatuses[val._id] === USER_STATUS.ACTIVE
                            ? "#008000"
                            : "#ff0000",
                        width: 80,
                        padding: "4px",
                        fontSize: 12,
                      },
                      "& fieldset": {
                        border: "none",
                      },

                      "& .MuiSvgIcon-root": {
                        color:
                          subscriptionStatuses[val._id] === USER_STATUS.ACTIVE
                            ? "#008000"
                            : "#ff0000",
                      },
                      "& .MuiInputBase-input": {
                        "&  .MuiInputBase-input": {
                          paddingRight: "10px",
                        },
                      },
                    }}
                    onChange={selectHandler(val._id)}
                    value={subscriptionStatuses[val._id] || ""}
                  >
                    {subscriptionStatus.map((item, index) => (
                      <MenuItem
                        value={item.label}
                        key={index}
                        sx={{ fontSize: 14 }}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <IconButton onClick={() => editInternship(val._id)}>
                      <Edit />
                    </IconButton>
                    {/* <IconButton onClick={()=>viewDetails(val._id)}>
                      <Visibility />
                    </IconButton> */}
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SubscriptionTable;
