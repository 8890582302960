import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Sidebar from "../layouts/Sidebar";
import Header from "../layouts/Header";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Discount } from "@mui/icons-material";
import { colors, USER_STATUS } from "../utils/enum";
import { loginTextField, tabSection } from "../utils/styles";
import { useNavigate } from "react-router-dom";
import { subscriptionController } from "../api/subscriptionController";
import { promotionList, tabs } from "../utils/defaultArray";
import CouponCard from "./couponCard";
import Loading from "react-loading";

const PromotionManagement = () => {
  const navigate = useNavigate();

  const handleRoute = () => {
    navigate("/coupon-management/add-coupon");
  };
  const [toast, setToast] = useState({ message: "", variant: "", open: false });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(9);
  const [couponList, setCouponList] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [couponStatus, setCouponStatus] = useState({});
  const [couponLoading, setCouponLoading] = useState(true);
  const [tabStatus, setTabStatus] = useState("");
  const couponStatusHandler = (id, status) => {
    setCouponStatus((prev) => ({
      ...prev,
      [id]: status,
    }));
    let body = {
      id: id,
      status: status,
    };
    subscriptionController
      .changeCouponStatus(body)
      .then((res) => {
        let data = {
          page: page,
          pageSize: pageSize,
        };
        getCouponList(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const TabChangeHandler = (e, newValue) => {
    setTabValue(newValue);
    const text = e.target.textContent;
    setCouponLoading(true);
    let body = {
      page: page,
      pageSize: pageSize,
    };

    if (text === USER_STATUS.ACTIVE) {
      setTabStatus(text);
      body.status = text;
    }
    if (text === USER_STATUS.InActive) {
      setTabStatus(text);
      body.status = text;
    }

    getCouponList(body);
  };

  const getCouponList = (body) => {
    subscriptionController
      .getCouponList(body)
      .then((res) => {
        setCouponList(res.data.data);
        setCouponLoading(false);
      })
      .catch((err) => {
        let errMessage =
          (err.response && err.response.data.message) || err.message;
        setToast({
          ...toast,
          message: errMessage,
          variant: "error",
          open: true,
        });
        setCouponLoading(true);
      });
  };

  useEffect(() => {
    let body = {
      page: page,
      pageSize: pageSize,
    };
    getCouponList(body);
  }, []);

  useEffect(() => {
    if (couponList && couponList.docs) {
      const initialStatuses = couponList.docs.reduce((acc, curr) => {
        acc[curr._id] = curr.status;
        return acc;
      }, {});
      setCouponStatus(initialStatuses);
    }
  }, [couponList]);

  return (
    <div>
      <Helmet>
        <title>Promotion Management</title>
      </Helmet>
      <Sidebar />
      <Header />
      <Box className="page-wrapper">
        <Box sx={{ p: 3 }}>
          <Card sx={{ boxShadow: "0px 0px 1px 1px #d7d7d7" }}>
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent={"space-between"}
              px={2}
              py={0.5}
            >
              <Stack direction={"row"} alignItems="center" spacing={2}>
                <Discount />
                <Typography fontSize={18} fontWeight={550}>
                  Coupon Management
                </Typography>
              </Stack>
              <Tabs
                sx={{ ...tabSection }}
                value={tabValue}
                onChange={TabChangeHandler}
              >
                {tabs.map((val, i) => (
                  <Tab
                    label={
                      <Typography key={i} fontSize={12}>
                        {val.label}
                      </Typography>
                    }
                  />
                ))}
              </Tabs>
              <Button
                sx={{
                  background: colors.linearGradient,
                  fontSize: 12,
                  color: "#000",
                }}
                startIcon={<Add />}
                onClick={handleRoute}
              >
                Add Coupon
              </Button>
            </Stack>
            <Divider />
            <Grid container p={2} spacing={3}>
              <Grid item lg={4}>
                <Autocomplete
                  multiple
                  options={promotionList}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    );
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "4px",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Coupon type"
                      sx={{
                        ...loginTextField,
                        "& .MuiOutlinedInput-input": {
                          padding: "12px",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={8}>
                <TextField
                  sx={{
                    ...loginTextField,
                    "& .MuiOutlinedInput-input": {
                      padding: "12px",
                    },
                  }}
                  fullWidth
                  label="Search"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} p={2}>
              {couponLoading ? (
                <Loading
                  type="bars"
                  width={20}
                  height={20}
                  className="m-auto"
                  color="#000"
                />
              ) : (
                couponList?.docs.map((val, i) => (
                  <Grid item lg={3} key={i}>
                    <CouponCard
                      val={val}
                      status={couponStatus}
                      statusHandler={couponStatusHandler}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          </Card>
        </Box>
      </Box>
    </div>
  );
};

export default PromotionManagement;
