import React, { useEffect } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { useState } from "react";
import CustomButton from "../_components/buttons/customButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { showModal } from "../redux/reducers/modal";
import Input from "../_components/input";
import Select from "react-select";
import { roleController } from "../api/roleManagement";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Loading from "../_components/Loading";
import { USER_PERMISSION } from "../utils/enum";
import { toast } from "react-toastify";

const RoleDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  
  //   const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [permission, setPermission] = useState({});
  const [modules, setModules] = useState([]);
  const getAllModules = () => {
    roleController
      .getModules()
      .then((res) => {
        setModules(res.data.data.modules);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getRoleDetails = () => {
    const body = {
      roleName:
        location &&
        location.state &&
        location.state.data &&
        location.state.data.roleName,
    };
    roleController
      .getPermissionByRoleName(body)
      .then((res) => {
        getAllModules();
        // setData(res.data.data);
        let permissionObj = {};
        res.data.data.permissions.map((apiPermission, i) => {
          permissionObj[apiPermission.moduleName] = apiPermission.action;
        });
        setPermission(permissionObj);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(true);
      });
  };
  const permissionHandler = (e, modules) => {
    let { id, checked } = e.target;
    let prev = permission[modules];
    if (prev) {
      prev[id] = checked;

      setPermission({ ...permission, [modules]: prev });
    } else {
      let unChecked = {
        [USER_PERMISSION.ADD]: false,
        [USER_PERMISSION.READ]: false,
        [USER_PERMISSION.UPDATE]: false,
        [USER_PERMISSION.DELETE]: false,
      };
      unChecked[id] = checked;
      setPermission({ ...permission, [modules]: unChecked });
    }
  };
  const [roleName, setRoleName] = useState(
    location &&
      location.state &&
      location.state.data &&
      location.state.data.roleName
  );
  const inputHandler = (e) => {
    setRoleName(e.target.value);
  };
  //   const [moduleName, setModuleName] = useState([]);
  //   const selectModuleHandler = (e) => {
  //     e.map((val) => {
  //       let prev = permission[val.value];
  //       if (!prev) {
  //         let unChecked = {
  //           [USER_PERMISSION.ADD]: false,
  //           [USER_PERMISSION.READ]: false,
  //           [USER_PERMISSION.UPDATE]: false,
  //           [USER_PERMISSION.DELETE]: false,
  //         };
  //         setPermission({ ...permission, [val.value]: unChecked });
  //       }
  //     });
  //   };
  const [selectedModules, setSelectedModules] = useState([]);

  const selectModuleHandler = (selectedValues) => {
    setSelectedModules(selectedValues);

    Object.keys(permission).forEach((moduleName) => {
      if (!selectedValues.find((val) => val.value === moduleName)) {
        let updatedPermissions = { ...permission };
        delete updatedPermissions[moduleName];
        setPermission(updatedPermissions);
      }
    });

    selectedValues.forEach((val) => {
      if (!permission[val.value]) {
        let unChecked = {
          [USER_PERMISSION.ADD]: false,
          [USER_PERMISSION.READ]: false,
          [USER_PERMISSION.UPDATE]: false,
          [USER_PERMISSION.DELETE]: false,
        };
        setPermission({ ...permission, [val.value]: unChecked });
      }
    });
  };

  const tableHeader = [
    {
      name: "Modules",
    },
    {
      name: "Read",
    },
    {
      name: "Write",
    },
    {
      name: "Update",
    },
    {
      name: "Delete",
    },
  ];

  const editPermissionRole = () => {
    let body = {
      roleName: roleName,
      permissions: permission,
      permissionId:
        location &&
        location.state &&
        location.state.data &&
        location.state.data._id,
    };
    roleController
      .updatePermission(body)
      .then((res) => {
        toast.success(res.data.message);
        navigate("/role-management");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    editPermissionRole();
  };

  useEffect(() => {
    getRoleDetails();
  }, []);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />

      <div className="page-wrapper">
        <div className="table-container mt-2">
          {loading ? (
            <Loading color="#f15d17" />
          ) : (
            <div>
              <div className="row">
                <div className="col-sm-6">
                  <Input
                    value={roleName}
                    onChange={inputHandler}
                    width="100%"
                    padding="6px"
                    bg="#fff"
                    border="1px solid #000"
                    rounded="5px"
                  />
                </div>
                <div className="col-sm-6">
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: "1px solid #eee",
                        backgroundColor: "#fff",
                        width: "100%",
                      }),
                    }}
                    options={modules.map((val) => {
                      return {
                        value: val,
                        label: val,
                      };
                    })}
                    defaultValue={Object.keys(permission).map((val) => {
                      return {
                        value: val,
                        label: val,
                      };
                    })}
                    isMulti={true}
                    onChange={selectModuleHandler}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="table-container mt-3">
          {loading ? (
            <Loading
              type="bars"
              width={25}
              height={25}
              className="m-auto"
              color="#f15d17"
            />
          ) : (
            <form onSubmit={submitHandler}>
              <Table hover bordered bgcolor="#ffffff">
                <thead style={{ backgroundColor: "#eee" }}>
                  <tr>
                    {tableHeader.map((val, i) => (
                      <th key={i}>{val.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(permission).map((val, i) => {
                    return (
                      <tr key={i}>
                        <td>{val}</td>
                        <td>
                          <input
                            defaultChecked={permission[val].READ}
                            type="checkbox"
                            className="input_checkbox"
                            onChange={(e) => permissionHandler(e, val)}
                            id={USER_PERMISSION.READ}
                          />
                        </td>
                        <td>
                          <input
                            defaultChecked={permission[val].ADD}
                            type="checkbox"
                            className="input_checkbox"
                            onChange={(e) => permissionHandler(e, val)}
                            id={USER_PERMISSION.ADD}
                          />
                        </td>
                        <td>
                          <input
                            defaultChecked={permission[val].UPDATE}
                            type="checkbox"
                            className="input_checkbox"
                            id={USER_PERMISSION.UPDATE}
                            onChange={(e) => permissionHandler(e, val)}
                          />
                        </td>
                        <td>
                          <input
                            defaultChecked={permission[val].DELETE}
                            type="checkbox"
                            className="input_checkbox"
                            id={USER_PERMISSION.DELETE}
                            onChange={(e) => permissionHandler(e, val)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="text-center mt-3">
                <CustomButton className="btn btn-primary">Submit</CustomButton>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoleDetails;
