import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AppRouter from "./App";
import "react-toastify/dist/ReactToastify.css";
import reportWebVitals from "./reportWebVitals";

// boostrap
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/js/bootstrap.bundle.min.js";
import "./assets/css/feather.css";
// import "./assets/plugins/icons/ionic/ionicons.css";
import bootstrap from "bootstrap";

//Jquery

//Datatable Modules
import DataTable from "datatables.net";
import Datatable4 from "datatables.net-bs4";

//fontawesome
import "./assets/plugins/fontawesome/css/all.css";
import "./assets/plugins/fontawesome/css/all.min.css";
import "./assets/plugins/fontawesome/css/fontawesome.min.css";
import "react-select2-wrapper/css/select2.css";
import "react-datepicker/dist/react-datepicker.css";

//style
import "./assets/css/style.css";
import "./assets/plugins/datatables/datatables.min.css";
import "./assets/plugins/jvectormap/jquery-jvectormap-2.0.3.css";
import "./assets/js/bootstrap.min.js";
// import './assets/js/script.js';

import { jQuery } from "jquery";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import store from "./redux/store";
import "react-quill/dist/quill.snow.css";
// export for others scripts to use
window.$ = "$";
window.jQuery = jQuery;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ToastContainer />
    <AppRouter />
  </Provider>
);
if (module.hot) {
  // enables hot module replacement if plugin is installed
  module.hot.accept();
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
