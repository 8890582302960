import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Scrollbars } from "react-custom-scrollbars";
import {
  LogoImg,
  LogoSmallImg,
  logowhite as Logo,
} from "../_components/imagepath";
import $ from "jquery";
import { roleController } from "../api/roleManagement";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  ListItemButton,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Checklist,
  Discount,
  Description,
  ListAlt,
} from "@mui/icons-material";
import { Loyalty } from "@material-ui/icons";

const Sidebar = (props) => {
  let location = useLocation();
  const [isSideMenu, setSideMenu] = useState("");
  const [routes, setRoutes] = useState([]);
  let pathName = location.pathname;

  const toggleSidebar = (value) => {
    setSideMenu(value === isSideMenu ? "" : value);
  };

  useEffect(() => {
    function handleMouseOver(e) {
      e.stopPropagation();
      if (
        document.body.classList.contains("mini-sidebar") &&
        document.querySelector("#toggle_btn").offsetParent !== null
      ) {
        var targ = e.target.closest(".sidebar");
        if (targ) {
          document.body.classList.add("expand-menu");
          document
            .querySelectorAll(".subdrop + ul")
            .forEach((ul) => (ul.style.display = "block"));
        } else {
          document.body.classList.remove("expand-menu");
          document
            .querySelectorAll(".subdrop + ul")
            .forEach((ul) => (ul.style.display = "none"));
        }
        return false;
      }
    }

    document.addEventListener("mouseover", handleMouseOver);

    return () => {
      document.removeEventListener("mouseover", handleMouseOver);
    };
  }, []);

  const getMyPermission = () => {
    roleController
      .getMyPermission()
      .then((res) => {
        setRoutes(res.data.data.permissions);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let sideBar = [];
  let userReport = [];
  let vroarManagement = [];

  let dashBoardRoute = {
    routeName: "Dashboard",
    url: "/dashboard",
    icon: <FeatherIcon icon="home" />,
    isSideMenu: "index",
  };

  const minimalRouteObj = (route) => ({
    routeName: route.routeName,
    url: route.url,
    action: route.action,
  });

  if (dashBoardRoute.url === pathName) {
    localStorage.setItem(
      "routes",
      JSON.stringify(minimalRouteObj(dashBoardRoute))
    );
  }
  sideBar.unshift(dashBoardRoute);

  routes.map((value) => {
    switch (value.moduleName) {
      case "Registered Students":
        if (value?.action) {
          let routesObj = {
            routeName: "Registered Students",
            url: "/student",
            action: {
              READ: value.action.READ || false,
              ADD: value.action.ADD || false,
              UPDATE: value.action.UPDATE || false,
              DELETE: value.action.DELETE || false,
            },
          };
          userReport.unshift(routesObj);
          if (routesObj.url === pathName) {
            localStorage.setItem("routes", JSON.stringify(routesObj));
          }
        }
        break;

      case "Registered Companies":
        if (value?.action) {
          let routesObj = {
            routeName: "Registered Companies",
            url: "/company",
            action: {
              READ: value.action.READ || false,
              ADD: value.action.ADD || false,
              UPDATE: value.action.UPDATE || false,
              DELETE: value.action.DELETE || false,
            },
          };
          userReport.push(routesObj);
          if (routesObj.url === pathName) {
            localStorage.setItem("routes", JSON.stringify(routesObj));
          }
        }
        break;

      case "Registered Parents": // Add this case
        if (value?.action) {
          let routesObj = {
            routeName: "Registered Parents",
            url: "/parent",
            action: {
              READ: value.action.READ || false,
              ADD: value.action.ADD || false,
              UPDATE: value.action.UPDATE || false,
              DELETE: value.action.DELETE || false,
            },
          };
          userReport.push(routesObj);
          if (routesObj.url === pathName) {
            localStorage.setItem("routes", JSON.stringify(routesObj));
          }
        }
        break;

      case "Role Management":
        if (value?.action) {
          let routesObj = {
            routeName: "Role Management",
            url: "/role-management",
            action: {
              READ: value.action.READ || false,
              ADD: value.action.ADD || false,
              UPDATE: value.action.UPDATE || false,
              DELETE: value.action.DELETE || false,
            },
          };
          vroarManagement.push(routesObj);
          if (routesObj.url === pathName) {
            localStorage.setItem("routes", JSON.stringify(routesObj));
          }
        }
        break;

      case "Staff Management":
        if (value?.action) {
          let routesObj = {
            routeName: "Staff Management",
            url: "/staff-management",
            action: {
              READ: value.action.READ || false,
              ADD: value.action.ADD || false,
              UPDATE: value.action.UPDATE || false,
              DELETE: value.action.DELETE || false,
            },
          };
          vroarManagement.push(routesObj);
          if (routesObj.url === pathName) {
            localStorage.setItem("routes", JSON.stringify(routesObj));
          }
        }
        break;

      default:
        break;
    }
  });

  if (userReport.length > 0) {
    sideBar.push({
      heading: "User Reports",
      icon: <Checklist sx={{ fontSize: 20 }} />,
      isSideMenu: "list",
      routes: userReport,
    });
  }

  if (vroarManagement.length > 0) {
    sideBar.push({
      heading: "Vroar Management",
      icon: <FeatherIcon icon="user" />,
      isSideMenu: "manage-user",
      routes: vroarManagement,
    });
  }

  useEffect(() => {
    getMyPermission();
  }, []);

  return (
    <>
      <div className="sidebar" id="sidebar">
        <div className="sidebar-header">
          <div className="sidebar-logo">
            <Link to="/dashboard">
              <img
                src={
                  "https://vroar-prod.s3.us-west-1.amazonaws.com/images/logo/vroar_logo.png"
                }
                alt=""
                width="100px !important"
                height="100% !important"
                style={{
                  objectFit: "cover",
                }}
              />
            </Link>
          </div>
        </div>
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax="95vh"
          thumbMinSize={30}
          universal={false}
          hideTracksWhenNotNeeded={true}
        >
          <List>
            {sideBar.map((item, i) => (
              <React.Fragment key={i}>
                <ListItem
                  button
                  component={Link}
                  to={item.url}
                  selected={item.url === pathName}
                  onClick={() => toggleSidebar(item.isSideMenu)}
                >
                  <ListItemIcon sx={{ minWidth: 30 }}>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography fontSize={14}>
                        {item.heading || item.routeName}
                      </Typography>
                    }
                  />
                  {item.routes && (
                    <>
                      {isSideMenu === item.isSideMenu ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </>
                  )}
                </ListItem>

                {item.routes && (
                  <Collapse
                    in={isSideMenu === item.isSideMenu}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item.routes.map((subItem) => (
                        <ListItem
                          button
                          component={Link}
                          to={subItem.url}
                          key={subItem.routeName}
                          selected={subItem.url === pathName}
                        >
                          <ListItemText
                            inset
                            primary={
                              <Typography sx={{ fontSize: 12 }}>
                                {subItem.routeName}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
            <ListItem
              button
              component={Link}
              to={"/curriculum-management"}
              selected={"/curriculum-management" === pathName}
            >
              <ListItemIcon sx={{ minWidth: 30 }}>
                <Checklist sx={{ fontSize: 20 }} />
              </ListItemIcon>
              <ListItemText
                // inset
                primary={
                  <Typography sx={{ fontSize: 14 }}>
                    Curriculum Management
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={"/subscription-management"}
              selected={"/subscription-management" === pathName}
            >
              <ListItemIcon sx={{ minWidth: 30 }}>
                <Loyalty sx={{ fontSize: 20 }} />
              </ListItemIcon>
              <ListItemText
                // inset
                primary={
                  <Typography sx={{ fontSize: 14 }}>
                    Subscription Management
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={"/coupon-management"}
              selected={"/coupon-management" === pathName}
            >
              <ListItemIcon sx={{ minWidth: 30 }}>
                <Discount sx={{ fontSize: 20 }} />
              </ListItemIcon>
              <ListItemText
                // inset
                primary={
                  <Typography sx={{ fontSize: 14 }}>
                    Coupon Management
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={"/internship-management"}
              selected={"/internship-management" === pathName}
            >
              <ListItemIcon sx={{ minWidth: 30 }}>
                <Description sx={{ fontSize: 20 }} />
              </ListItemIcon>
              <ListItemText
                // inset
                primary={
                  <Typography sx={{ fontSize: 14 }}>
                    Internship Management
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              component={Link}
              to={"/waitlist"}
              selected={"/waitlist" === pathName}
            >
              <ListItemIcon sx={{ minWidth: 30 }}>
                <ListAlt sx={{ fontSize: 20 }} />
              </ListItemIcon>
              <ListItemText
                // inset
                primary={
                  <Typography sx={{ fontSize: 14 }}>Waitlist</Typography>
                }
              />
            </ListItem>
          </List>
        </Scrollbars>
      </div>
    </>
  );
};

export default Sidebar;
