import React, { useCallback, useEffect, useState } from "react";
import Sidebar from "../layouts/Sidebar";
import Header from "../layouts/Header";
import {
  Box,
  Card,
  Grid,
  Stack,
  Tab,
  TablePagination,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { fontFamily, INTERNSHIP_STATUS } from "../utils/enum";
import { Description, Search } from "@mui/icons-material";
import { internshipController } from "../api/internship";
import { loginTextField, tabSection } from "../utils/styles";
import InternshipCard from "./internshipCard";
import Loading from "react-loading";
import { debounce } from "lodash";
import { INTERNSHIPSTATUSARRAY } from "../utils/defaultArray";
const InternshipManagement = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const [data, setData] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const getAllJobs = (body) => {
    internshipController
      .getAllInternships(body)
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(true);
      });
  };

  const pageChangeHandler = (e, newPage) => {
    setPage(newPage);
    setLoading(true);
    let body = {
      pageSize: pageSize,
    };
    if (newPage) {
      body.page = newPage + 1;
    }
    if (search) {
      body.search = search;
    }
    getAllJobs(body);
  };

  const [value, setValue] = useState(0);
  const [status, setStatus] = useState("");
  const tabChangeHandler = (e, newValue) => {
    setValue(newValue);
    setLoading(true);
    const textContent = e.target.textContent;
    const body = {
      page: page,
      pageSize: pageSize,
    };
    if (textContent === INTERNSHIP_STATUS.AWAITING) {
      setStatus(textContent);
      body.status = textContent;
    }
    if (textContent === INTERNSHIP_STATUS.PUBLISHED) {
      setStatus(textContent);
      body.status = textContent;
    }
    getAllJobs(body);
  };

  const rowsPerChangeHandler = (e) => {
    setPageSize(e.target.value);
    setLoading(true);
    let body = {
      page: page,
    };
    if (e.target.value) {
      body.pageSize = e.target.value;
    }
    if (search) {
      body.search = search;
    }
    getAllJobs(body);
  };
  const getDebouncedList = useCallback(
    debounce((body) => {
      getAllJobs(body);
    }, 300),
    []
  );

  const searchChangeHandler = (e) => {
    setSearch(e.target.value);
    setLoading(true);
    let body = {
      page: page,
      pageSize: pageSize,
    };
    if (e.target.value) {
      body.search = e.target.value;
    }
    getDebouncedList(body);
  };

  useEffect(() => {
    let body = {
      page: page,
      pageSize: pageSize,
    };
    getAllJobs(body);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Internship Management</title>
      </Helmet>
      <Sidebar />
      <Header />
      <Box className="page-wrapper">
        <Box sx={{ p: 5 }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Description />
              <Typography
                sx={{
                  fontSize: 20,
                  fontFamily: fontFamily.roboto,
                  fontWeight: 600,
                }}
              >
                Internship Management
              </Typography>
            </Stack>
            <Tabs
              sx={{ ...tabSection }}
              value={value}
              onChange={tabChangeHandler}
            >
              {INTERNSHIPSTATUSARRAY.map((val, i) => (
                <Tab
                  label={
                    <Typography
                      fontSize={12}
                      sx={{ ...loginTextField }}
                      fontWeight={550}
                      fontFamily={fontFamily.roboto}
                    >
                      {val.label}
                    </Typography>
                  }
                  key={i}
                />
              ))}
            </Tabs>
          </Stack>

          <TextField
            label="Search Internship"
            sx={{ ...loginTextField, mt: 3 }}
            InputProps={{
              startAdornment: <Search />,
            }}
            onChange={searchChangeHandler}
            fullWidth
          />

          <Grid container spacing={3} mt={2}>
            {loading ? (
              <Grid item lg={12}>
                <Loading
                  type="bars"
                  width={20}
                  height={20}
                  className="m-auto"
                  color="#000"
                />
              </Grid>
            ) : data?.docs.length ? (
              data?.docs.map((val, i) => (
                <Grid item lg={3} key={i}>
                  <InternshipCard data={val} />
                </Grid>
              ))
            ) : (
              <Grid item lg={12}>
                <Typography
                  fontSize={14}
                  fontFamily={fontFamily.roboto}
                  textAlign={"center"}
                >
                  No Internship Found
                </Typography>
              </Grid>
            )}
            <Grid item lg={12}>
              <TablePagination
                component={"div"}
                rowsPerPage={pageSize}
                page={page}
                rowsPerPageOptions={[12, 16, 20, 24, 28]}
                count={data?.totalDocs}
                onPageChange={pageChangeHandler}
                onRowsPerPageChange={rowsPerChangeHandler}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default InternshipManagement;
