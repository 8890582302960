import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Input from "../_components/input";
import { roleController } from "../api/roleManagement";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";
import "./index.css";
import { USER_GROUP, USER_PERMISSION } from "../utils/enum";
import CustomButton from "../_components/buttons/customButton";
import Loading from "../_components/Loading";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const AddRole = () => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [roleList, setRoleList] = useState([]);
  const getRole = () => {
    roleController
      .getRoles()
      .then((res) => {
        setRoleList([
          ...res.data.data,
          { roleName: "Add Role", roleId: "roleName" },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [role, setRole] = useState("");
  const [show, setShow] = useState(false);
  const roleHandler = (e) => {
    if (e.value === "Add Role") {
      setShow(true);
    } else {
      setShow(false);
      setRole(e.value);
    }
  };
  const [modules, setModules] = useState([]);
  const getAllModules = () => {
    roleController
      .getModules()
      .then((res) => {
        setModules(res.data.data.modules);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addRoleHandler = (e) => {
    setRole(e.target.value);
  };
  const [moduleList, setModuleList] = useState([]);
  const [module, setModule] = useState([]);
  const moduleHandler = (e) => {
    setModuleList(e);
    setModule({ ...module, moduleName: e.map((val) => val.value) });
  };

  const styles = {
    wrapper_div: {
      border: "1px solid #000",
      width: "100%",
      padding: "8px",
      borderRadius: "5px",
      backgroundColor: "#fff",
      display: "flex",
      alignItems: "center",
    },
  };

  const permissionHeader = [
    {
      name: "Modules",
    },
    {
      name: "Read",
    },
    {
      name: "Write",
    },
    {
      name: "Update",
    },
    {
      name: "Delete",
    },
  ];

  const [student, setStudent] = useState({
    READ: false,
    WRITE: false,
    UPDATE: false,
    DELETE: false,
  });
  const [permission, setPermission] = useState({});

  const permissionHandler = (e, modules) => {
    let { id, checked } = e.target;
    let prev = permission[modules];
    if (prev) {
      prev[id] = checked;

      setPermission({ ...permission, [modules]: prev });
    } else {
      let unChecked = {
        [USER_PERMISSION.ADD]: false,
        [USER_PERMISSION.READ]: false,
        [USER_PERMISSION.UPDATE]: false,
        [USER_PERMISSION.DELETE]: false,
      };
      unChecked[id] = checked;
      setPermission({ ...permission, [modules]: unChecked });
    }
  };

  const [loading, setLoading] = useState(false);
  const addUserPermission = () => {
    setLoading(true);
    let body = {
      roleName: role,
      permissions: permission,
    };

    roleController
      .addPermission(body)
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        navigate("/role-management");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    addUserPermission();
  };

  useEffect(() => {
    getRole();
    getAllModules();
  }, []);
  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        <div className="p-4">
          <h4>Add Role</h4>
        </div>
        <form onSubmit={submitHandler}>
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                {show ? (
                  <div style={styles.wrapper_div}>
                    <Input
                      width="100%"
                      placeholder="Enter Role Name"
                      border="none"
                      onChange={addRoleHandler}
                    />
                    <FontAwesomeIcon
                      icon={faClose}
                      onClick={() => {
                        setShow(false);
                        setRole("");
                      }}
                      className="pointer"
                    />
                  </div>
                ) : (
                  <Select
                    options={roleList.map((val) => {
                      return {
                        label: val.roleName,
                        value: val.roleName,
                      };
                    })}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        boxShadow: "none",
                      }),
                    }}
                    onChange={(e) => roleHandler(e)}
                    placeholder="Select Role"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    id="roleName"
                  />
                )}
              </div>
              <div className="col-sm-6">
                <Select
                  options={modules.map((val) => {
                    return {
                      label: val,
                      value: val,
                    };
                  })}
                  isMulti={true}
                  onChange={(e) => moduleHandler(e)}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder="Select Modules"
                  id="moduleName"
                />
              </div>
            </div>
            {moduleList.length ? (
              <Table bordered bgcolor="#ffffff">
                <thead style={{ backgroundColor: "#eee" }}>
                  <tr>
                    {permissionHeader.map((val, i) => (
                      <th key={i}>{val.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {moduleList.map((val, i) => (
                    <tr key={i}>
                      <td>{val.label}</td>
                      <td>
                        <input
                          type="checkbox"
                          className="permission_box"
                          onChange={(e) => permissionHandler(e, val.label)}
                          id={USER_PERMISSION.READ}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="permission_box"
                          id={USER_PERMISSION.ADD}
                          onChange={(e) => permissionHandler(e, val.label)}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="permission_box"
                          id={USER_PERMISSION.UPDATE}
                          onChange={(e) => permissionHandler(e, val.label)}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="permission_box"
                          id={USER_PERMISSION.DELETE}
                          onChange={(e) => permissionHandler(e, val.label)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <></>
            )}
          </div>
          <div className="text-center mt-3">
            <CustomButton className="btn btn-primary" width={100}>
              {loading ? (
                <Loading
                  type="bars"
                  width={25}
                  height={25}
                  className="m-auto"
                />
              ) : (
                "Submit"
              )}
            </CustomButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddRole;
