import React, { useState, useEffect } from 'react'
import Header from '../../layouts/Header'
import Sidebar from '../../layouts/Sidebar'
import Cover_img from '../../assets/img/background/Cover_Banner.jpg'
import Avatar from '../../assets/img/avatar.jpg'
import FeatherIcon from 'feather-icons-react'

const Profilepage = () => {
  const [menu, setMenu] = useState(false)

  const toggleMobileMenu = () => {
    setMenu(!menu)
  }
  return (
    <>
      <div className={`main-wrapper ${menu ? 'slide-nav' : ''}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />

        <div style={{ marginLeft: '248px' }}>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ marginTop: '31px', marginBottom: '30px', width: '100%' }}
          ></div>
          <div className="profile-cover">
            <div className="profile-cover-wrap">
              <img
                className="profile-cover-img"
                src={Cover_img}
                alt="Profile Cover"
                id="cover-image"
              />
              <div className="cover-content">
                <div className="custom-file-btn">
                  <input
                    type="file"
                    className="custom-file-btn-input"
                    id="cover_upload"
                  />
                  <label
                    className="custom-file-btn-label btn btn-sm btn-white"
                    htmlFor="cover_upload"
                  >
                    <i className="fas fa-camera" />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div>
                <img
                  className="avatarprofile-img pointer"
                  src={Avatar}
                  alt="Profile Image"
                  id="blah"
                />
                <div>
                  <label onChange="file" htmlFor="avatar_upload">
                    <input
                      type="file"
                      className="custom-file-btn-input"
                      id="avatar_upload"
                    />
                    <span className="avatar_update">
                      <FeatherIcon
                        icon="edit"
                        className="avatar-uploader-icon shadow-soft"
                      />
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <div
              style={{
                marginTop: '150px',
                marginLeft: '40px',
                padding: '5px',
              }}
            >
              <h6>Student</h6>
            </div>
            <div>
              <h6>student@gmail.com</h6>
            </div>
          </div>
          <div className='col-sm-5'>

          <div className="progress progress-md mt-2 shadow">
            <div
              className="progress-bar bg-danger "
              role="progressbar"
              style={{ width: '20px'}}
              aria-valuenow="2"
              aria-valuemin="0"
              aria-valuemax="100"
              
              ></div>
              </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Profilepage
