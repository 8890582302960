import {
  Avatar,
  Card,
  CardActionArea,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { fontFamily } from "../utils/enum";
import {
  AttachMoney,
  BusinessCenter,
  WatchLaterOutlined,
  WorkHistory,
} from "@mui/icons-material";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const InternshipCard = ({ data }) => {
  // console.log("data", data);
  const navigate = useNavigate();
  const { title, workMode, stipend, duration, createdAt, status } = data;
  const { logo, companyName, companyType } = data?.company;
  const startDate = moment.unix(duration?.from);
  const endDate = moment.unix(duration?.to);
  const jobInfo = [
    {
      icon: <BusinessCenter sx={{ fontSize: 12 }} />,
      label: workMode,
    },
    {
      icon: <AttachMoney sx={{ fontSize: 12 }} />,
      label: stipend?.amount
        ? stipend?.rate
          ? `${stipend.amount} ${stipend.rate}`
          : `${stipend.amount}`
        : "Not Disclosed",
    },
    {
      icon: <WorkHistory sx={{ fontSize: 12 }} />,
      label: `${endDate.diff(startDate, "weeks")} weeks`,
    },
    {
      icon: <WatchLaterOutlined sx={{ fontSize: 12 }} />,
      label: status || "Not Disclosed",
    },
  ];

  const viewDetails = (id) => {
    navigate(`/internship-management/internship-detail/${id}`);
  };
  return (
    <div>
      <Card
        sx={{
          backgroundColor: "transparent",
          height: 215,
          position: "relative",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        }}
      >
        <CardActionArea
          sx={{
            p: 2,
            ":hover": {
              backgroundColor: "initial",
            },
            height: "100%",
          }}
          onClick={() => viewDetails(data._id)}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontFamily: fontFamily.roboto,
                fontWeight: 550,
                textTransform: "capitalize",
              }}
            >
              {title}
            </Typography>
            <Avatar sx={{ width: 40, height: 40 }}>
              <img alt="" src={logo} />
            </Avatar>
          </Stack>
          <Typography fontSize={12} fontFamily={fontFamily.plus}>
            {companyName}
          </Typography>
          {companyType !== "undefined" && (
            <Typography fontSize={12} fontFamily={fontFamily.plus}>
              {companyType}
            </Typography>
          )}
          <List>
            {jobInfo.map((val, i) => (
              <ListItem disablePadding key={i}>
                <ListItemAvatar sx={{ minWidth: 20 }}>
                  {val.icon}
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography fontSize={12} fontFamily={fontFamily.plus}>
                      {val.label}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
          <Typography
            textAlign={"end"}
            fontSize={10}
            fontFamily={fontFamily.plus}
            position={"absolute"}
            bottom={5}
            right={10}
            fontWeight={600}
          >
            {moment(createdAt).fromNow()}
          </Typography>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default InternshipCard;
