import {
  Box,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React from "react";
import { subscriptionStatus, tableHeader } from "../../utils/defaultArray";
import { fontFamily, USER_STATUS } from "../../utils/enum";
import moment from "moment";

const ParentTable = ({
  data,
  parentStatus,
  onStatusChange,
  setParentStatus,
  page,
  pageSize,
  pageChangeHandler,
}) => {
  console.log("test", data);

  const selectHandler = (id) => (e) => {
    setParentStatus((prev) => ({
      ...prev,
      [id]: e.target.value,
    }));

    onStatusChange({ id, status: e.target.value });
  };
  return (
    <div>
      <TableContainer sx={{ p: 1 }}>
        <Table>
          <TableHead sx={{ backgroundColor: "#d7d7d7" }}>
            <TableRow>
              {tableHeader.map((val, i) =>
                val.icon ? (
                  <TableSortLabel
                    sx={{
                      padding: "12px",
                      "& .MuiTableSortLabel-icon": {
                        fill: "#000",
                        opacity: 1,
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontFamily: fontFamily.roboto,
                        fontWeight: 550,
                      }}
                    >
                      {val.name}
                    </Typography>
                  </TableSortLabel>
                ) : (
                  <TableCell sx={{ padding: "12px" }}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontFamily: fontFamily.roboto,
                        fontWeight: 550,
                      }}
                    >
                      {val.name}
                    </Typography>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.docs.map((val, i) => (
              <TableRow>
                <TableCell>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: 13,
                        fontFamily: fontFamily.plus,
                        fontWeight: 550,
                        textTransform: "capitalize",
                      }}
                    >
                      {val.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 12,
                        fontFamily: fontFamily.plus,
                        fontWeight: 500,
                      }}
                    >
                      {val.email}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontFamily: fontFamily.plus,
                      fontWeight: 550,
                    }}
                  >
                    {val.phoneNo || "--"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontFamily: fontFamily.plus,
                      fontWeight: 550,
                    }}
                  >
                    {moment(val.createdAt).format("DD-MM-YYYY")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontFamily: fontFamily.plus,
                      fontWeight: 550,
                    }}
                  >
                    {moment(val.lastLogin).format("DD-MM-YYYY")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Select
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        backgroundColor:
                          parentStatus[val._id] === USER_STATUS.ACTIVE
                            ? "#00800049"
                            : "#ff000049",
                        color:
                          parentStatus[val._id] === USER_STATUS.ACTIVE
                            ? "#008000"
                            : "#ff0000",
                        width: 80,
                        padding: "4px",
                        fontSize: 12,
                      },
                      "& fieldset": {
                        border: "none",
                      },

                      "& .MuiSvgIcon-root": {
                        color:
                          parentStatus[val._id] === USER_STATUS.ACTIVE
                            ? "#008000"
                            : "#ff0000",
                      },
                      "& .MuiInputBase-input": {
                        "&  .MuiInputBase-input": {
                          paddingRight: "10px",
                        },
                      },
                    }}
                    onChange={selectHandler(val._id)}
                    value={parentStatus[val._id] || ""}
                  >
                    {subscriptionStatus.map((item, index) => (
                      <MenuItem
                        value={item.label}
                        key={index}
                        sx={{ fontSize: 14 }}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component={"div"}
          page={page}
          rowsPerPage={pageSize}
          count={data?.totalDocs}
          onPageChange={pageChangeHandler}
        />
      </TableContainer>
    </div>
  );
};

export default ParentTable;
