import React, { useCallback, useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";

import { Business } from "@material-ui/icons";
import { DownloadForOffline } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import userController from "../../api/users";
import { colors, USER_GROUP, USER_STATUS } from "../../utils/enum";
import { handleToastClose } from "../../utils/functions";
import { loginTextField, tabSection } from "../../utils/styles";
import CustomToast from "../customToast";
import CompanyTable from "./companyTable";
import "./index.css";
import { debounce } from "lodash";
import TabPanel from "../tabPanel";

const tableHeader = [
  {
    name: "Name",
    icon: true,
  },

  {
    name: "Phone No",
    icon: false,
  },

  {
    name: "Registered On",
    icon: true,
  },
  {
    name: "Last Login",
    icon: false,
  },
  {
    name: "Status",
    icon: false,
  },
  {
    name: "Actions",
    icon: false,
  },
];

const tabs = [
  {
    label: USER_STATUS.ALL,
  },
  {
    label: USER_STATUS.ACTIVE,
  },
  {
    label: USER_STATUS.InActive,
  },
];

const Company_list = () => {
  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const [tabValue, setTabValue] = useState(0);

  const tabValueChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const [companyList, setCompanyList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const companyListing = (value) => {
    userController
      .getUsers(value)
      .then((res) => {
        setCompanyList(res.data.data);

        setLoading(false);
      })
      .then((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const [snackBar, setSnackBar] = useState({
    message: "",
    variant: "",
    open: false,
  });
  const [csvLoading, setCsvLoading] = useState(false);
  const exportCsv = (user) => {
    const a = document.createElement("a");
    setCsvLoading(true);
    userController
      .csvDownload(user)
      .then((res) => {
        const blob = new Blob([res.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `${user}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

        document.body.removeChild(a);
        setCsvLoading(false);
      })
      .catch((err) => {
        let errMessage =
          (err.response && err.response.data.message) || err.message;
        setSnackBar({
          ...snackBar,
          open: true,
          message: errMessage,
          variant: "error",
        });
      });
  };

  //

  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState({ field: "", order: "asc" });
  const getCompanySearchList = useCallback(
    debounce((body) => {
      companyListing(body);
    }, 300),
    []
  );

  const searchHandler = (e) => {
    setLoading(true);
    setSearch(e.target.value);
    let body = {
      page: page,
      pageSize: pageSize,
    };
    if (e.target.value) {
      body.search = e.target.value;
    }
    if (sortBy?.field) {
      body.sortBy = { [sortBy.field]: sortBy.order.toUpperCase() };
    }
    getCompanySearchList(body);
  };

  const sortingHandler = (e, name) => {
    let newOrder = "asc";
    setLoading(true);

    if (sortBy.field === name) {
      newOrder = sortBy.order === "asc" ? "desc" : "asc";
    }

    setSortBy({ field: name, order: newOrder });

    const body = {
      group: USER_GROUP.STUDENT,
      page: page,
      pageSize: pageSize,
      sortBy: { [name]: newOrder.toUpperCase() },
    };

    if (search) {
      body.search = search;
    }

    companyListing(body);
  };

  useEffect(() => {
    let body = { group: USER_GROUP.COMPANY, page: 1, pageSize: 10 };
    companyListing(body);
  }, []);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        {/* <div className=" pb-2">
          <div className="d-flex align-items-center justify-content-between mt-2 ">
            <div
              className="d-flex align-items-center"
              style={{ marginLeft: "15px" }}
            >
              <div
                className="square-box"
                style={{ width: "20px", height: "20px" }}
              ></div>
              <h5>Registered Companies</h5>
            </div>

            <button
              className="f-13 pointer btn btn-primary"
              style={{ marginRight: "15px" }}
              onClick={() => exportCsv(USER_GROUP.COMPANY)}
            >
              Export CSV
            </button>
          </div>
        </div> */}
        {/* {loading ? (
          <Loading
            type="bars"
            height={25}
            width={25}
            className="m-auto"
            color="#f15d17"
          />
        ) : (
          <div className="table-container ">
            <Table bordered hover responsive>
              <thead>
                <tr style={{ backgroundColor: "#eee", padding: "5px" }}>
                  {tableHeader.map((val, i) => (
                    <th key={i}>
                      <div className="d-flex align-items-end justify-content-between">
                        {val.name}
                        {val.icon ? (
                          <div className="icon__filter">
                            <FontAwesomeIcon
                              icon={faCaretUp}
                              size={2}
                              onClick={() => sortingHandler("ASC", val.name)}
                              className="filter_up"
                            />
                            <br />
                            <FontAwesomeIcon
                              icon={faCaretDown}
                              size={2}
                              onClick={() => sortingHandler("DESC", val.name)}
                            />
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody style={{ backgroundColor: "#ffffff" }}>
                {CompanyList.map((val, i) => (
                  <tr key={i}>
                    <td className="text-capitalize">
                      {val.company && val.company.companyName
                        ? val.company.companyName
                        : "--"}
                    </td>
                    <td>
                      {val.company && val.company.companyEmail
                        ? val.company.companyEmail
                        : "--"}
                    </td>
                    <td>
                      {val.company && val.company.companyPhoneNo
                        ? val.company.companyPhoneNo.slice(0, 10)
                        : "--"}
                    </td>

                    <td>
                      {val.createdAt
                        ? moment(val.createdAt).format("DD-MM-YYYY")
                        : "--"}
                    </td>
                    <td>
                      {val.lastLogin
                        ? moment(val.lastLogin).format("DD-MM-YYYY")
                        : "--"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination
              pageCount={totalPages}
              onPageChange={(e) =>
                companyListing({
                  group: USER_GROUP.COMPANY,
                  page: e.selected + 1,
                  pageSize: 20,
                })
              }
            />
          </div>
        )} */}
        <Box sx={{ p: 1, mt: 3 }}>
          <Card>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ px: 1 }}
            >
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Business />
                <Typography fontSize={18} fontWeight={600}>
                  Registered Companies
                </Typography>
              </Stack>
              <Tabs
                sx={{ ...tabSection }}
                onChange={tabValueChange}
                value={tabValue}
              >
                {tabs.map((val, i) => (
                  <Tab
                    label={
                      <Typography sx={{ fontSize: 12 }}>{val.label}</Typography>
                    }
                    key={i}
                  />
                ))}
              </Tabs>
              <Button
                startIcon={
                  csvLoading ? (
                    <CircularProgress sx={{ color: "#000" }} size={15} />
                  ) : (
                    <DownloadForOffline />
                  )
                }
                sx={{
                  background: colors.linearGradient,
                  color: "#000",
                  fontSize: 12,
                  width: 150,
                  p: 1,
                }}
                onClick={() => exportCsv(USER_GROUP.COMPANY)}
              >
                {csvLoading ? "Exporting CSV..." : "Export CSV"}
              </Button>
            </Stack>
            <Divider />
            <Box sx={{ p: 1 }}>
              <TextField
                sx={{ ...loginTextField }}
                label="Search"
                fullWidth
                onChange={searchHandler}
              />
            </Box>
            {tabs.map((val, i) => (
              <TabPanel value={tabValue} index={i}>
                <CompanyTable
                  header={tableHeader}
                  data={companyList}
                  loading={loading}
                  page={page}
                  pageSize={pageSize}
                  getCompanyList={companyListing}
                  setPage={setPage}
                  setPageSize={setPageSize}
                  setLoading={setLoading}
                  sortingHandler={sortingHandler}
                  sortBy={sortBy}
                />
              </TabPanel>
            ))}
          </Card>
        </Box>
      </div>
      <CustomToast
        open={snackBar.open}
        message={snackBar.message}
        variant={snackBar.variant}
        handleClose={() =>
          handleToastClose({ toast: snackBar, setToast: setSnackBar })
        }
      />
    </div>
  );
};

export default Company_list;
