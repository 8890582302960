export const generateAlphanumericPassword = (setPassword) => {
  let length = 8;
  const characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"; // Alphanumeric characters

  let password = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    password += characters.charAt(randomIndex);
  }
  setPassword(password);
  return password;
};

export const getActionsbyModuleName = () => {
  try {
    const serializedData = localStorage.getItem("routes");
    console.log("test", serializedData);
    if (serializedData === null) {
      return null;
    }
    const data = JSON.parse(serializedData).action;
    return data;
  } catch (error) {
    console.error("Error getting data from local storage:", error);
    return null;
  }
};

export const handleToastClose = ({ toast, setToast }) => {
  setToast({ ...toast, open: false });
};
