import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { img10 } from "../_components/imagepath";
import Select2 from "react-select2-wrapper";
import FeatherIcon from "feather-icons-react";
import Input from "../_components/input";
import Select from "react-select";
import { roleController } from "../api/roleManagement";
import CustomButton from "../_components/buttons/customButton";
import { generateAlphanumericPassword } from "../utils/functions";
import { Table } from "react-bootstrap";
import { USER_PERMISSION } from "../utils/enum";
import { toast } from "react-toastify";
import Loading from "../_components/Loading";

const AddUser = () => {
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate();
  const tableHeader = [
    {
      name: "MODULE NAME",
    },
    {
      name: USER_PERMISSION.READ,
    },
    {
      name: "WRITE",
    },
    {
      name: USER_PERMISSION.UPDATE,
    },
    {
      name: USER_PERMISSION.DELETE,
    },
  ];
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const [roleList, setRoleList] = useState([]);
  const getRole = () => {
    roleController
      .getRoles()
      .then((res) => {
        setRoleList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [password, setPassword] = useState("");
  const [state, setState] = useState({
    name: "",
    roleName: "",
    email: "",
  });

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };
  const InputHandler = (e) => {
    let { id, value } = e.target;
    setState({ ...state, [id]: value });
  };
  const [modules, setModules] = useState([]);
  const getPermissionbyRoleName = (roleName) => {
    let body = {
      roleName: roleName,
    };
    roleController
      .getPermissionByRoleName(body)
      .then((res) => {
        setModules(res.data.data.permissions);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const roleHandler = (e) => {
    setState({ ...state, roleName: e.value });
    getPermissionbyRoleName(e.label);
  };

  const [loading, setLoading] = useState(false);
  const addStaffMember = () => {
    setLoading(true);
    let body = {
      email: state.email,
      password: password,
      name: state.name,
      permissionId: state.roleName,
    };
    roleController
      .addStaffMember(body)
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        navigate("/staff-management");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    addStaffMember();
  };
  useEffect(() => {
    getRole();
  }, []);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />

        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="content-page-header">
              <h5>Add User</h5>
            </div>
            <div className="row align-items-center">
              <div className="col-md-12">
                <form onSubmit={submitHandler}>
                  <div className="row mb-4 align-items-center">
                    <div className="col-sm-5">
                      <Input
                        type="text"
                        placeholder="Full Name"
                        padding="12px"
                        rounded="8px"
                        width="100%"
                        border="1px solid #d7d7d7"
                        bg="#fff"
                        onChange={InputHandler}
                        id="name"
                      />
                    </div>

                    <div className="col-sm-5">
                      <div className="d-flex align-items-center">
                        <Input
                          type="password"
                          placeholder="Password"
                          padding="12px"
                          rounded="8px"
                          width="100%"
                          border="none"
                          bg="#fff"
                          value={password}
                          minLength={8}
                          onChange={passwordHandler}
                          id="password"
                        />
                      </div>{" "}
                    </div>
                    <div className="col-sm-2">
                      <CustomButton
                        className="btn btn-primary"
                        onClick={() =>
                          generateAlphanumericPassword(setPassword)
                        }
                        type="button"
                      >
                        Auto Generate
                      </CustomButton>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-sm-5">
                      <Input
                        type="email"
                        placeholder="Email"
                        padding="12px"
                        rounded="8px"
                        width="100%"
                        border="1px solid #d7d7d7"
                        bg="#fff"
                        onChange={InputHandler}
                        id="email"
                      />
                    </div>

                    <div className="col-sm-5">
                      {" "}
                      {/* <Select2
                        data={status}
                        options={{
                          placeholder: "Select Status",
                        }}
                      /> */}
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            boxShadow: "none",
                            padding: "3px",
                            borderRadius: "8px",
                          }),
                        }}
                        options={roleList.map((val, i) => {
                          return { value: val._id, label: val.roleName };
                        })}
                        placeholder="Select Role"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        onChange={roleHandler}
                      />
                    </div>
                  </div>
                  {modules.length ? (
                    <Table bgcolor="#ffffff" hover bordered>
                      <thead>
                        <tr>
                          {tableHeader.map((val, i) => (
                            <th key={i}>{val.name}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {modules.map((val, i) => {
                          return (
                            <tr key={i}>
                              <td>{val.moduleName ? val.moduleName : "--"}</td>
                              <td>
                                <input
                                  checked={val.action ? val.action.READ : false}
                                  type="checkbox"
                                  className="input_checkbox"
                                />
                              </td>
                              <td>
                                <input
                                  checked={val.action ? val.action.ADD : false}
                                  type="checkbox"
                                  className="input_checkbox"
                                />
                              </td>
                              <td>
                                <input
                                  checked={
                                    val.action ? val.action.UPDATE : false
                                  }
                                  type="checkbox"
                                  className="input_checkbox"
                                />
                              </td>
                              <td>
                                <input
                                  checked={
                                    val.action ? val.action.DELETE : false
                                  }
                                  type="checkbox"
                                  className="input_checkbox"
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <></>
                  )}
                  <div className="text-center">
                    <CustomButton
                      className="btn btn-primary mt-3"
                      type="submit"
                      width={100}
                    >
                      {loading ? (
                        <Loading
                          type="bars"
                          width={20}
                          height={20}
                          className="m-auto"
                        />
                      ) : (
                        "Submit"
                      )}
                    </CustomButton>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
