import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../layouts/Header'
import Sidebar from '../layouts/Sidebar'
import '../_components/antd.css'
import { Pagination, Table } from 'antd'
import Data from '../assets/jsons/deleteAccount'
import FeatherIcon from 'feather-icons-react'
import { onShowSizeChange, itemRender } from '../_components/paginationfunction'
import AddVendor from '../vendors/addVendor'

const DeleteAccount = () => {
  const [menu, setMenu] = useState(false)
  const [show, setShow] = useState(false)

  const toggleMobileMenu = () => {
    setMenu(!menu)
  }

  const datasource = Data?.Data
  console.log(datasource)

  const columns = [
    {
      title: '#',
      dataIndex: 'Id',
      // sorter: (a, b) => a.Id.length - b.Id.length,
    },
    {
      title: 'User Name',
      dataIndex: 'Name',
      render: (text, record) => (
        <>
          <h2 className="table-avatar">
            <Link to="/Profilepage" className="avatar avatar-sm me-2">
              <img
                className="avatar-img rounded-circle"
                src={record.Img}
                alt="User Image"
              />
            </Link>
            <Link to="/Profilepage">
              {record.Name} <span>{record.Email}</span>
            </Link>
          </h2>
        </>
      ),
      sorter: (a, b) => a.Name.length - b.Name.length,
    },
    {
      title: 'Requisition Date',
      dataIndex: 'Requisition',
      sorter: (a, b) => a.Requisition.length - b.Requisition.length,
    },
    {
      title: 'Delete Request Date',
      dataIndex: 'Delete',
      sorter: (a, b) => a.Delete.length - b.Delete.length,
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      render: (text, record) => (
        <>
          <div className="d-flex align-items-center">
            {/* <div className="dropdown dropdown-action">
              <Link
                to="#"
                className=" btn-action-icon "
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-right">
                <ul>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_modal"
                    >
                      <i className="far fa-trash-alt me-2" />
                      Delete
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
            <Link
              to="#"
              className="btn btn-greys ms-2"
              data-bs-toggle="modal"
              data-bs-target="#delete_modal"
            >
              Confirm
            </Link>
          </div>
        </>
      ),
      // sorter: (a, b) => a.Action.length - b.Action.length,
    },
  ]

  return (
    <>
      <div className={`main-wrapper ${menu ? 'slide-nav' : ''}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />

        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Page Header */}
            <div className="page-header">
              <div className="content-page-header ">
                <h5>Delete Account Request</h5>
                <div className="list-btn">
                  <ul className="filter-list">
                    <li>
                      <Link onClick={() => setShow(!show)} className="btn btn-primary me-2">
                        Filter
                      </Link>
                    </li>
                    {/* <li>
                      <Link className="btn-filters" to="#">
                        <span>
                          <i className="fe fe-grid" />
                          <FeatherIcon icon="grid" />
                        </span>{" "}
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link className="active btn-filters" to="#">
                        <span>
                          <i className="fe fe-list" />
                          <FeatherIcon icon="list" />
                        </span>{" "}
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {/* Search Filter */}
            <div id="filter_inputs" className="card filter-card">
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-sm-6 col-md-3">
                    <div className="form-group">
                      <label>Name</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="form-group">
                      <label>Email</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="form-group">
                      <label>Phone</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Search Filter */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body deleteAccount">
                    <div className="table-responsive table-hover">
                      <Table
                        pagination={{
                          total: datasource.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        columns={columns}
                        dataSource={datasource}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AddVendor setShow={setShow} show={show} />

        <div
          className="modal custom-modal fade"
          id="delete_modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Delete Account Request</h3>
                  <p>Are you sure want to delete?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <a href="#" className="btn btn-primary paid-continue-btn">
                        Delete
                      </a>
                    </div>
                    <div className="col-6">
                      <a
                        href="#"
                        data-bs-dismiss="modal"
                        className="btn btn-primary paid-cancel-btn"
                      >
                        Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeleteAccount
