import {
  Avatar,
  Box,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React from "react";
import Loading from "react-loading";
import business from "../../assets/img/icons/business.png";
import moment from "moment";
import { USER_GROUP, USER_STATUS } from "../../utils/enum";
const CompanyTable = ({
  header,
  data,
  loading,
  page,
  pageSize,
  getCompanyList,
  setPage,
  setPageSize,
  setLoading,
  sortingHandler,
  sortBy,
}) => {
  const pageHandler = (e, newPage) => {
    setPage(newPage);
    setLoading(true);
    let body = {
      pageSize: pageSize,
      group: USER_GROUP.COMPANY,
    };
    if (newPage) {
      body.page = newPage + 1;
    }
    if (sortBy.field) {
      body.sortBy = { [sortBy.field]: sortBy.order.toUpperCase() };
    }
    getCompanyList(body);
  };

  const rowsChangeHandler = (e) => {
    setPageSize(e.target.value);
    setLoading(true);
    let body = {
      page: page,
      group: USER_GROUP.COMPANY,
    };
    if (e.target.value) {
      body.pageSize = e.target.value;
    }
    if (sortBy.field) {
      body.sortBy = { [sortBy.field]: sortBy.order.toUpperCase() };
    }
    getCompanyList(body);
  };

  return (
    <div>
      <TableContainer sx={{ p: 1 }}>
        <Table>
          <TableHead sx={{ backgroundColor: "#d7d7d7" }}>
            <TableRow>
              {header.map((val, i) =>
                val.icon ? (
                  <TableSortLabel
                    key={i}
                    sx={{
                      padding: "12px",
                      "& .MuiTableSortLabel-icon": {
                        fill: "#000",
                        opacity: 1,
                      },
                    }}
                    active={sortBy.field === val.name}
                    direction={
                      sortBy.field === val.name
                        ? sortBy.order.toLowerCase()
                        : "asc"
                    }
                    onClick={(e) => sortingHandler(e, val.name)}
                  >
                    <Typography fontSize={14} fontWeight={550}>
                      {val.name}
                    </Typography>
                  </TableSortLabel>
                ) : (
                  <TableCell key={i} sx={{ padding: "12px" }}>
                    <Typography fontSize={14} fontWeight={550}>
                      {val.name}
                    </Typography>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          {loading ? (
            <Box textAlign={"center"}>
              <Loading
                type="bars"
                color="#000"
                width={20}
                height={20}
                className="m-auto"
              />
            </Box>
          ) : data?.docs.length === 0 ? (
            <Typography fontSize={14} textAlign={"center"}>
              No data Found
            </Typography>
          ) : (
            <TableBody>
              {data?.docs.map((item, index) => (
                <TableRow>
                  <TableCell sx={{ padding: "12px", width: "20%" }}>
                    <Stack direction={"row"} alignItems="center" spacing={1}>
                      <Avatar>
                        <img src={item?.avatar || business} alt="avatar" />
                      </Avatar>
                      <Box>
                        <Typography
                          fontSize={14}
                          sx={{
                            cursor: "pointer",
                            ":hover": {
                              color: "#f15d17",
                              textDecoration: "underline",
                            },
                            textDecoration: "underline",
                            textTransform: "capitalize",
                            fontWeight: 550,
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Typography fontSize={12}>{item.email}</Typography>
                      </Box>
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ padding: "12px" }}>
                    <Typography fontSize={14}>
                      {item.phoneNo || "--"}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "12px" }}>
                    <Typography fontSize={14}>
                      {moment(item.updatedAt).format("DD-MM-YYYY")}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "12px" }}>
                    <Typography fontSize={14}>
                      {moment(item.lastLogin).format("DD-MM-YYYY")}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "12px" }}>
                    <Box
                      sx={{
                        backgroundColor:
                          item.status === USER_STATUS.ACTIVE
                            ? "#00800049"
                            : "#ff000049",
                        color:
                          item.status === USER_STATUS.ACTIVE
                            ? "#008000"
                            : "#ff0000",
                        fontSize: 12,
                        width: 70,
                        p: 1,
                        borderRadius: 4,
                        textAlign: "center",
                      }}
                    >
                      {item.status}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ padding: "12px" }}>
                    <Switch
                      checked={item.status === USER_STATUS.ACTIVE}
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                          {
                            backgroundColor: "#008000",
                          },
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: "#008000",
                        },
                      }}
                      //   onChange={(e) => changeStatusHandler(e, item)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        <TablePagination
          count={data?.totalDocs}
          component={"div"}
          page={page}
          rowsPerPage={pageSize}
          onRowsPerPageChange={rowsChangeHandler}
          onPageChange={pageHandler}
        />
      </TableContainer>
    </div>
  );
};

export default CompanyTable;
