import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Loading from "react-loading";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import CustomToast from "../_components/customToast";
import { internshipController } from "../api/internship";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { colors } from "../utils/enum";
import { loginTextField } from "../utils/styles";
import { AddCurriculumValidation } from "../utils/validation";
import { useLocation, useNavigate } from "react-router-dom";
import { Edit } from "feather-icons-react/build/IconComponents";
import { Close, MoreVert } from "@mui/icons-material";
import "./index.css";
import { isValidURL } from "../utils/regex";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [{ color: [] }, { background: [] }],
    ["clean"],
  ],
};
const EditCurriculum = () => {
  const [state, setState] = useState({
    curriculumTitle: "",
    img: null,
    url: "",
    description: "",
    sessionTitle: "",
    sessionId: "",
  });

  const location = useLocation();

  const [error, setError] = useState({
    curriculumTitle: "",
    img: null,
    url: "",
    description: "",
    sessionTitle: "",
  });
  const [id, setId] = useState(location?.state?.curriculumId);
  const inputChangeHandler = (e) => {
    let { id, value } = e.target;
    setState({ ...state, [id]: value });
    setError({
      ...error,
      [id]:
        id === "url" ? (isValidURL(value) ? "" : "Please Enter Valid URL") : "",
    });
  };
  const [editorContent, setEditorContent] = useState("");
  const handleChange = (content, delta, source, editor) => {
    setEditorContent(editor.getHTML());
    setState({ ...state, description: editor.getHTML() });
    setError({ ...error, description: "" });
  };

  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackBar] = useState({
    open: false,
    message: "",
    variant: "",
  });
  const [curriculum, setCurriculum] = useState(null);
  const handleSnackbarClose = () => {
    setSnackBar({ ...snackbar, open: false });
  };

  const handleEditSession = (value) => {
    if (value) {
      setState({
        ...state,
        sessionTitle: value?.title,
        description: value?.description,
        url: value?.url,
        sessionId: value?._id,
      });
      setEditorContent(value?.description);
    }
  };

  const [isDisabled, setIsDisabled] = useState(false);
  const getCurriculumDetails = (id) => {
    internshipController
      .getCurriculumById(id)
      .then((res) => {
        const response = res.data.data;
        if (response) {
          setCurriculum(response);
          setState({ ...state, curriculumTitle: response.title });
          setIsDisabled(true);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const addCurriCulum = () => {
    setLoading(true);
    console.log("id", id);
    let body = {
      title: state.curriculumTitle,
      session: {
        title: state.sessionTitle,
        description: state.description,
        url: state.url,
      },
    };

    if (id) {
      body.id = id;
    }
    if (state.sessionId) {
      body.session.id = state.sessionId;
    }
    internshipController
      .addCurriculum(body)
      .then((res) => {
        localStorage.setItem("curriculumId", res.data.data.curriculumId);
        setLoading(false);
        setSnackBar({
          ...snackbar,
          open: true,
          message: res.data.message,
          variant: "success",
        });
        const id = res.data.data.curriculumId;
        getCurriculumDetails(id);
        setState({
          ...state,
          sessionTitle: "",
          description: "",
          url: "",
          curriculumTitle: "",
        });
        setEditorContent("");
      })
      .catch((err) => {
        let errMessage =
          (err.response && err.response.data.message) || err.message;
        setSnackBar({
          ...snackbar,
          open: true,
          message: errMessage,
          variant: "error",
        });
        setLoading(false);
      });
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const submitHandler = () => {
    if (AddCurriculumValidation({ state, error, setError })) {
      addCurriCulum();
    } else {
      toast.error("Please Enter All Fields");
    }
  };

  useEffect(() => {
    if (id) {
      getCurriculumDetails(id);
    }
  }, [id]);

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <Box sx={{ p: 3 }}>
        <Stack direction="row" alignItems={"center"} spacing={2}>
          <Box
            sx={{ background: colors.linearGradient, width: 20, height: 20 }}
          ></Box>
          <Typography fontSize={20} fontWeight={600} color="#000">
            Edit Curriculum
          </Typography>
        </Stack>

        <Box sx={{ mt: 2 }}>
          <Typography sx={{ fontSize: 12, color: "#ff0000" }}>
            * Indicates Required
          </Typography>
          <Grid container spacing={4}>
            <Grid item lg={7}>
              <Grid container mt={2} spacing={2}>
                <Grid item lg={12}>
                  <TextField
                    sx={{ ...loginTextField }}
                    label="Curriculum Title*"
                    fullWidth
                    id="curriculumTitle"
                    onChange={inputChangeHandler}
                    error={Boolean(error.curriculumTitle)}
                    helperText={error.curriculumTitle}
                    disabled={isDisabled}
                    value={state.curriculumTitle}
                    InputProps={{
                      endAdornment: Boolean(curriculum?.title) && (
                        <IconButton onClick={() => setIsDisabled(!isDisabled)}>
                          {isDisabled ? <Edit /> : <Close />}
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>

                <Grid item lg={12}>
                  <Typography fontSize={20} color="#000">
                    Session :
                  </Typography>
                  <Grid container spacing={2} mt={2}>
                    <Grid item lg={6}>
                      <TextField
                        label="Session Title*"
                        sx={{ ...loginTextField }}
                        fullWidth
                        id="sessionTitle"
                        error={Boolean(error.sessionTitle)}
                        helperText={error.sessionTitle}
                        onChange={inputChangeHandler}
                        value={state.sessionTitle}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <TextField
                        sx={{ ...loginTextField }}
                        label="URL*"
                        fullWidth
                        id="url"
                        error={Boolean(error.url)}
                        helperText={error.url}
                        onChange={inputChangeHandler}
                        value={state.url}
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ minHeight: 100, mt: 2 }}>
                    <ReactQuill
                      theme="snow"
                      value={editorContent}
                      onChange={handleChange}
                      modules={modules}
                    />
                    {error.description && (
                      <Typography fontSize={12} color="#ff0000">
                        {error.description}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ textAlign: "end", mt: 2 }}>
                    <Button
                      sx={{
                        background: colors.linearGradient,
                        color: "#000",
                        width: 150,
                      }}
                      onClick={submitHandler}
                      disabled={loading}
                    >
                      {loading ? (
                        <Loading
                          type="bars"
                          width={20}
                          height={20}
                          className="m-auto"
                          color="#000"
                        />
                      ) : (
                        "Add Session"
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={5} mt={3}>
              <Typography sx={{ color: "#000", fontSize: 20, mb: 2 }}>
                {curriculum?.title}
              </Typography>
              {curriculum?.sessions.map((val, i) => (
                <Accordion
                  sx={{ mb: 1 }}
                  onChange={handleAccordionChange(`panel${i}`)}
                  expanded={expanded === `panel${i}`}
                >
                  <AccordionSummary
                    sx={{
                      justifyContent: "space-between",
                      position: "relative",
                      borderBottom:
                        expanded === `panel${i}` && "1px solid #d7d7d7",
                    }}
                  >
                    <Typography
                      fontSize={12}
                      fontWeight={600}
                      sx={{ width: "80%" }}
                    >
                      Session {i + 1} : {val.title}
                    </Typography>
                    <IconButton
                      sx={{ position: "absolute", right: 0, bottom: "8px" }}
                      onClick={() => handleEditSession(val)}
                    >
                      <Edit sx={{ fontSize: 12 }} />
                    </IconButton>
                  </AccordionSummary>
                  <AccordionDetails sx={{ pl: 5 }}>
                    <div
                      dangerouslySetInnerHTML={{ __html: val.description }}
                      className="custom-description"
                    ></div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
          <CustomToast
            open={snackbar.open}
            message={snackbar.message}
            variant={snackbar.variant}
            handleClose={handleSnackbarClose}
          />
        </Box>
      </Box>
    </div>
  );
};

export default EditCurriculum;
