import React, { useState } from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";

const Feather = () => {
  const [menu, setMenu] = useState(false);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />

        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Page Header */}
            <div className="page-header">
              <div className="content-page-header">
                <h5>Feather Icon</h5>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              {/* Chart */}
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">Feather Icon</div>
                  </div>
                  <div className="card-body">
                    <div className="icons-items">
                      <ul className="icons-list">
                        <li>
                          <i
                            className="feather-activity"
                            data-bs-toggle="tooltip"
                            title="feather-activity"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-airplay"
                            data-bs-toggle="tooltip"
                            title="feather-airplay"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-alert-circle"
                            data-bs-toggle="tooltip"
                            title="feather-alert-circle"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-alert-octagon"
                            data-bs-toggle="tooltip"
                            title="feather-alert-octagon"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-alert-triangle"
                            data-bs-toggle="tooltip"
                            title="feather-alert-triangle"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-align-center"
                            data-bs-toggle="tooltip"
                            title="feather-align-center"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-align-justify"
                            data-bs-toggle="tooltip"
                            title="feather-align-justify"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-align-left"
                            data-bs-toggle="tooltip"
                            title="feather-align-left"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-align-right"
                            data-bs-toggle="tooltip"
                            title="feather-align-right"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-anchor"
                            data-bs-toggle="tooltip"
                            title="feather-anchor"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-aperture"
                            data-bs-toggle="tooltip"
                            title="feather-aperture"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-arrow-down"
                            data-bs-toggle="tooltip"
                            title="feather-arrow-down"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-arrow-down-circle"
                            data-bs-toggle="tooltip"
                            title="feather-arrow-down-circle"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-arrow-down-left"
                            data-bs-toggle="tooltip"
                            title="feather-arrow-down-left"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-arrow-down-right"
                            data-bs-toggle="tooltip"
                            title="feather-arrow-down-right"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-arrow-left"
                            data-bs-toggle="tooltip"
                            title="feather-arrow-left"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-arrow-left-circle"
                            data-bs-toggle="tooltip"
                            title="feather-arrow-left-circle"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-arrow-right"
                            data-bs-toggle="tooltip"
                            title="feather-arrow-right"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-arrow-right-circle"
                            data-bs-toggle="tooltip"
                            title="feather-arrow-right-circle"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-arrow-up"
                            data-bs-toggle="tooltip"
                            title="feather-arrow-up"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-arrow-up-circle"
                            data-bs-toggle="tooltip"
                            title="feather-arrow-up-circle"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-arrow-up-left"
                            data-bs-toggle="tooltip"
                            title="feather-arrow-up-left"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-arrow-up-right"
                            data-bs-toggle="tooltip"
                            title="feather-arrow-up-right"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-at-sign"
                            data-bs-toggle="tooltip"
                            title="feather-at-sign"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-award"
                            data-bs-toggle="tooltip"
                            title="feather-award"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-bar-chart"
                            data-bs-toggle="tooltip"
                            title="feather-bar-chart"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-bar-chart-2"
                            data-bs-toggle="tooltip"
                            title="feather-bar-chart-2"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-battery"
                            data-bs-toggle="tooltip"
                            title="feather-battery"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-battery-charging"
                            data-bs-toggle="tooltip"
                            title="feather-battery-charging"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-bell"
                            data-bs-toggle="tooltip"
                            title="feather-bell"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-bell-off"
                            data-bs-toggle="tooltip"
                            title="feather-bell-off"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-bluetooth"
                            data-bs-toggle="tooltip"
                            title="feather-bluetooth"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-bold"
                            data-bs-toggle="tooltip"
                            title="feather-bold"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-book"
                            data-bs-toggle="tooltip"
                            title="feather-book"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-book-open"
                            data-bs-toggle="tooltip"
                            title="feather-book-open"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-bookmark"
                            data-bs-toggle="tooltip"
                            title="feather-bookmark"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-box"
                            data-bs-toggle="tooltip"
                            title="feather-box"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-briefcase"
                            data-bs-toggle="tooltip"
                            title="feather-briefcase"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-calendar"
                            data-bs-toggle="tooltip"
                            title="feather-calendar"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-camera"
                            data-bs-toggle="tooltip"
                            title="feather-camera"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-camera-off"
                            data-bs-toggle="tooltip"
                            title="feather-camera-off"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-cast"
                            data-bs-toggle="tooltip"
                            title="feather-cast"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-check"
                            data-bs-toggle="tooltip"
                            title="feather-check"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-check-circle"
                            data-bs-toggle="tooltip"
                            title="feather-check-circle"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-check-square"
                            data-bs-toggle="tooltip"
                            title="feather-check-square"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-chevron-down"
                            data-bs-toggle="tooltip"
                            title="feather-chevron-down"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-chevron-left"
                            data-bs-toggle="tooltip"
                            title="feather-chevron-left"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-chevron-right"
                            data-bs-toggle="tooltip"
                            title="feather-chevron-right"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-chevron-up"
                            data-bs-toggle="tooltip"
                            title="feather-chevron-up"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-chevrons-down"
                            data-bs-toggle="tooltip"
                            title="feather-chevrons-down"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-chevrons-left"
                            data-bs-toggle="tooltip"
                            title="feather-chevrons-left"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-chevrons-right"
                            data-bs-toggle="tooltip"
                            title="feather-chevrons-right"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-chevrons-up"
                            data-bs-toggle="tooltip"
                            title="feather-chevrons-up"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-chrome"
                            data-bs-toggle="tooltip"
                            title="feather-chrome"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-circle"
                            data-bs-toggle="tooltip"
                            title="feather-circle"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-clipboard"
                            data-bs-toggle="tooltip"
                            title="feather-clipboard"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-clock"
                            data-bs-toggle="tooltip"
                            title="feather-clock"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-cloud"
                            data-bs-toggle="tooltip"
                            title="feather-cloud"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-cloud-drizzle"
                            data-bs-toggle="tooltip"
                            title="feather-cloud-drizzle"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-cloud-lightning"
                            data-bs-toggle="tooltip"
                            title="feather-cloud-lightning"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-cloud-off"
                            data-bs-toggle="tooltip"
                            title="feather-cloud-off"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-cloud-rain"
                            data-bs-toggle="tooltip"
                            title="feather-cloud-rain"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-cloud-snow"
                            data-bs-toggle="tooltip"
                            title="feather-cloud-snow"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-code"
                            data-bs-toggle="tooltip"
                            title="feather-code"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-codepen"
                            data-bs-toggle="tooltip"
                            title="feather-codepen"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-command"
                            data-bs-toggle="tooltip"
                            title="feather-command"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-compass"
                            data-bs-toggle="tooltip"
                            title="feather-compass"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-copy"
                            data-bs-toggle="tooltip"
                            title="feather-copy"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-corner-down-left"
                            data-bs-toggle="tooltip"
                            title="feather-corner-down-left"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-corner-down-right"
                            data-bs-toggle="tooltip"
                            title="feather-corner-down-right"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-corner-left-down"
                            data-bs-toggle="tooltip"
                            title="feather-corner-left-down"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-corner-left-up"
                            data-bs-toggle="tooltip"
                            title="feather-corner-left-up"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-corner-right-down"
                            data-bs-toggle="tooltip"
                            title="feather-corner-right-down"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-corner-right-up"
                            data-bs-toggle="tooltip"
                            title="feather-corner-right-up"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-corner-up-left"
                            data-bs-toggle="tooltip"
                            title="feather-corner-up-left"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-corner-up-right"
                            data-bs-toggle="tooltip"
                            title="feather-corner-up-right"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-cpu"
                            data-bs-toggle="tooltip"
                            title="feather-cpu"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-credit-card"
                            data-bs-toggle="tooltip"
                            title="feather-credit-card"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-crop"
                            data-bs-toggle="tooltip"
                            title="feather-crop"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-crosshair"
                            data-bs-toggle="tooltip"
                            title="feather-crosshair"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-database"
                            data-bs-toggle="tooltip"
                            title="feather-database"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-delete"
                            data-bs-toggle="tooltip"
                            title="feather-delete"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-disc"
                            data-bs-toggle="tooltip"
                            title="feather-disc"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-dollar-sign"
                            data-bs-toggle="tooltip"
                            title="feather-dollar-sign"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-download"
                            data-bs-toggle="tooltip"
                            title="feather-download"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-download-cloud"
                            data-bs-toggle="tooltip"
                            title="feather-download-cloud"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-droplet"
                            data-bs-toggle="tooltip"
                            title="feather-droplet"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-edit"
                            data-bs-toggle="tooltip"
                            title="feather-edit"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-edit-2"
                            data-bs-toggle="tooltip"
                            title="feather-edit-2"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-edit-3"
                            data-bs-toggle="tooltip"
                            title="feather-edit-3"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-external-link"
                            data-bs-toggle="tooltip"
                            title="feather-external-link"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-eye"
                            data-bs-toggle="tooltip"
                            title="feather-eye"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-eye-off"
                            data-bs-toggle="tooltip"
                            title="feather-eye-off"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-facebook"
                            data-bs-toggle="tooltip"
                            title="feather-facebook"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-fast-forward"
                            data-bs-toggle="tooltip"
                            title="feather-fast-forward"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-feather"
                            data-bs-toggle="tooltip"
                            title="feather-feather"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-file"
                            data-bs-toggle="tooltip"
                            title="feather-file"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-file-minus"
                            data-bs-toggle="tooltip"
                            title="feather-file-minus"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-file-plus"
                            data-bs-toggle="tooltip"
                            title="feather-file-plus"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-file-text"
                            data-bs-toggle="tooltip"
                            title="feather-file-text"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-film"
                            data-bs-toggle="tooltip"
                            title="feather-film"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-filter"
                            data-bs-toggle="tooltip"
                            title="feather-filter"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-flag"
                            data-bs-toggle="tooltip"
                            title="feather-flag"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-folder"
                            data-bs-toggle="tooltip"
                            title="feather-folder"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-folder-minus"
                            data-bs-toggle="tooltip"
                            title="feather-folder-minus"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-folder-plus"
                            data-bs-toggle="tooltip"
                            title="feather-folder-plus"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-git-branch"
                            data-bs-toggle="tooltip"
                            title="feather-git-branch"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-git-commit"
                            data-bs-toggle="tooltip"
                            title="feather-git-commit"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-git-merge"
                            data-bs-toggle="tooltip"
                            title="feather-git-merge"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-git-pull-request"
                            data-bs-toggle="tooltip"
                            title="feather-git-pull-request"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-github"
                            data-bs-toggle="tooltip"
                            title="feather-github"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-gitlab"
                            data-bs-toggle="tooltip"
                            title="feather-gitlab"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-globe"
                            data-bs-toggle="tooltip"
                            title="feather-globe"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-grid"
                            data-bs-toggle="tooltip"
                            title="feather-grid"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-hard-drive"
                            data-bs-toggle="tooltip"
                            title="feather-hard-drive"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-hash"
                            data-bs-toggle="tooltip"
                            title="feather-hash"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-headphones"
                            data-bs-toggle="tooltip"
                            title="feather-headphones"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-heart"
                            data-bs-toggle="tooltip"
                            title="feather-heart"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-help-circle"
                            data-bs-toggle="tooltip"
                            title="feather-help-circle"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-home"
                            data-bs-toggle="tooltip"
                            title="feather-home"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-image"
                            data-bs-toggle="tooltip"
                            title="feather-image"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-inbox"
                            data-bs-toggle="tooltip"
                            title="feather-inbox"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-info"
                            data-bs-toggle="tooltip"
                            title="feather-info"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-instagram"
                            data-bs-toggle="tooltip"
                            title="feather-instagram"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-italic"
                            data-bs-toggle="tooltip"
                            title="feather-italic"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-layers"
                            data-bs-toggle="tooltip"
                            title="feather-layers"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-layout"
                            data-bs-toggle="tooltip"
                            title="feather-layout"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-life-buoy"
                            data-bs-toggle="tooltip"
                            title="feather-life-buoy"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-link"
                            data-bs-toggle="tooltip"
                            title="feather-link"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-link-2"
                            data-bs-toggle="tooltip"
                            title="feather-link-2"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-linkedin"
                            data-bs-toggle="tooltip"
                            title="feather-linkedin"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-list"
                            data-bs-toggle="tooltip"
                            title="feather-list"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-loader"
                            data-bs-toggle="tooltip"
                            title="feather-loader"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-lock"
                            data-bs-toggle="tooltip"
                            title="feather-lock"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-log-in"
                            data-bs-toggle="tooltip"
                            title="feather-log-in"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-log-out"
                            data-bs-toggle="tooltip"
                            title="feather-log-out"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-mail"
                            data-bs-toggle="tooltip"
                            title="feather-mail"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-map"
                            data-bs-toggle="tooltip"
                            title="feather-map"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-map-pin"
                            data-bs-toggle="tooltip"
                            title="feather-map-pin"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-maximize"
                            data-bs-toggle="tooltip"
                            title="feather-maximize"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-maximize-2"
                            data-bs-toggle="tooltip"
                            title="feather-maximize-2"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-menu"
                            data-bs-toggle="tooltip"
                            title="feather-menu"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-message-circle"
                            data-bs-toggle="tooltip"
                            title="feather-message-circle"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-message-square"
                            data-bs-toggle="tooltip"
                            title="feather-message-square"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-mic"
                            data-bs-toggle="tooltip"
                            title="feather-mic"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-mic-off"
                            data-bs-toggle="tooltip"
                            title="feather-mic-off"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-minimize"
                            data-bs-toggle="tooltip"
                            title="feather-minimize"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-minimize-2"
                            data-bs-toggle="tooltip"
                            title="feather-minimize-2"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-minus"
                            data-bs-toggle="tooltip"
                            title="feather-minus"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-minus-circle"
                            data-bs-toggle="tooltip"
                            title="feather-minus-circle"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-minus-square"
                            data-bs-toggle="tooltip"
                            title="feather-minus-square"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-monitor"
                            data-bs-toggle="tooltip"
                            title="feather-monitor"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-moon"
                            data-bs-toggle="tooltip"
                            title="feather-moon"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-more-horizontal"
                            data-bs-toggle="tooltip"
                            title="feather-more-horizontal"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-more-vertical"
                            data-bs-toggle="tooltip"
                            title="feather-more-vertical"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-move"
                            data-bs-toggle="tooltip"
                            title="feather-move"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-music"
                            data-bs-toggle="tooltip"
                            title="feather-music"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-navigation"
                            data-bs-toggle="tooltip"
                            title="feather-navigation"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-navigation-2"
                            data-bs-toggle="tooltip"
                            title="feather-navigation-2"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-octagon"
                            data-bs-toggle="tooltip"
                            title="feather-octagon"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-package"
                            data-bs-toggle="tooltip"
                            title="feather-package"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-paperclip"
                            data-bs-toggle="tooltip"
                            title="feather-paperclip"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-pause"
                            data-bs-toggle="tooltip"
                            title="feather-pause"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-pause-circle"
                            data-bs-toggle="tooltip"
                            title="feather-pause-circle"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-percent"
                            data-bs-toggle="tooltip"
                            title="feather-percent"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-phone"
                            data-bs-toggle="tooltip"
                            title="feather-phone"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-phone-call"
                            data-bs-toggle="tooltip"
                            title="feather-phone-call"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-phone-forwarded"
                            data-bs-toggle="tooltip"
                            title="feather-phone-forwarded"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-phone-incoming"
                            data-bs-toggle="tooltip"
                            title="feather-phone-incoming"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-phone-missed"
                            data-bs-toggle="tooltip"
                            title="feather-phone-missed"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-phone-off"
                            data-bs-toggle="tooltip"
                            title="feather-phone-off"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-phone-outgoing"
                            data-bs-toggle="tooltip"
                            title="feather-phone-outgoing"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-pie-chart"
                            data-bs-toggle="tooltip"
                            title="feather-pie-chart"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-play"
                            data-bs-toggle="tooltip"
                            title="feather-play"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-play-circle"
                            data-bs-toggle="tooltip"
                            title="feather-play-circle"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-plus"
                            data-bs-toggle="tooltip"
                            title="feather-plus"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-plus-circle"
                            data-bs-toggle="tooltip"
                            title="feather-plus-circle"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-plus-square"
                            data-bs-toggle="tooltip"
                            title="feather-plus-square"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-pocket"
                            data-bs-toggle="tooltip"
                            title="feather-pocket"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-power"
                            data-bs-toggle="tooltip"
                            title="feather-power"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-printer"
                            data-bs-toggle="tooltip"
                            title="feather-printer"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-radio"
                            data-bs-toggle="tooltip"
                            title="feather-radio"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-refresh-ccw"
                            data-bs-toggle="tooltip"
                            title="feather-refresh-ccw"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-refresh-cw"
                            data-bs-toggle="tooltip"
                            title="feather-refresh-cw"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-repeat"
                            data-bs-toggle="tooltip"
                            title="feather-repeat"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-rewind"
                            data-bs-toggle="tooltip"
                            title="feather-rewind"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-rotate-ccw"
                            data-bs-toggle="tooltip"
                            title="feather-rotate-ccw"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-rotate-cw"
                            data-bs-toggle="tooltip"
                            title="feather-rotate-cw"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-rss"
                            data-bs-toggle="tooltip"
                            title="feather-rss"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-save"
                            data-bs-toggle="tooltip"
                            title="feather-save"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-scissors"
                            data-bs-toggle="tooltip"
                            title="feather-scissors"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-search"
                            data-bs-toggle="tooltip"
                            title="feather-search"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-send"
                            data-bs-toggle="tooltip"
                            title="feather-send"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-server"
                            data-bs-toggle="tooltip"
                            title="feather-server"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-settings"
                            data-bs-toggle="tooltip"
                            title="feather-settings"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-share"
                            data-bs-toggle="tooltip"
                            title="feather-share"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-share-2"
                            data-bs-toggle="tooltip"
                            title="feather-share-2"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-shield"
                            data-bs-toggle="tooltip"
                            title="feather-shield"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-shield-off"
                            data-bs-toggle="tooltip"
                            title="feather-shield-off"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-shopping-bag"
                            data-bs-toggle="tooltip"
                            title="feather-shopping-bag"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-shopping-cart"
                            data-bs-toggle="tooltip"
                            title="feather-shopping-cart"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-shuffle"
                            data-bs-toggle="tooltip"
                            title="feather-shuffle"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-sidebar"
                            data-bs-toggle="tooltip"
                            title="feather-sidebar"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-skip-back"
                            data-bs-toggle="tooltip"
                            title="feather-skip-back"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-skip-forward"
                            data-bs-toggle="tooltip"
                            title="feather-skip-forward"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-slack"
                            data-bs-toggle="tooltip"
                            title="feather-slack"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-slash"
                            data-bs-toggle="tooltip"
                            title="feather-slash"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-sliders"
                            data-bs-toggle="tooltip"
                            title="feather-sliders"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-smartphone"
                            data-bs-toggle="tooltip"
                            title="feather-smartphone"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-speaker"
                            data-bs-toggle="tooltip"
                            title="feather-speaker"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-square"
                            data-bs-toggle="tooltip"
                            title="feather-square"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-star"
                            data-bs-toggle="tooltip"
                            title="feather-star"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-stop-circle"
                            data-bs-toggle="tooltip"
                            title="feather-stop-circle"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-sun"
                            data-bs-toggle="tooltip"
                            title="feather-sun"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-sunrise"
                            data-bs-toggle="tooltip"
                            title="feather-sunrise"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-sunset"
                            data-bs-toggle="tooltip"
                            title="feather-sunset"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-tablet"
                            data-bs-toggle="tooltip"
                            title="feather-tablet"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-tag"
                            data-bs-toggle="tooltip"
                            title="feather-tag"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-target"
                            data-bs-toggle="tooltip"
                            title="feather-target"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-terminal"
                            data-bs-toggle="tooltip"
                            title="feather-terminal"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-thermometer"
                            data-bs-toggle="tooltip"
                            title="feather-thermometer"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-thumbs-down"
                            data-bs-toggle="tooltip"
                            title="feather-thumbs-down"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-thumbs-up"
                            data-bs-toggle="tooltip"
                            title="feather-thumbs-up"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-toggle-left"
                            data-bs-toggle="tooltip"
                            title="feather-toggle-left"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-toggle-right"
                            data-bs-toggle="tooltip"
                            title="feather-toggle-right"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-trash"
                            data-bs-toggle="tooltip"
                            title="feather-trash"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-trash-2"
                            data-bs-toggle="tooltip"
                            title="feather-trash-2"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-trending-down"
                            data-bs-toggle="tooltip"
                            title="feather-trending-down"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-trending-up"
                            data-bs-toggle="tooltip"
                            title="feather-trending-up"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-triangle"
                            data-bs-toggle="tooltip"
                            title="feather-triangle"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-truck"
                            data-bs-toggle="tooltip"
                            title="feather-truck"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-tv"
                            data-bs-toggle="tooltip"
                            title="feather-tv"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-twitter"
                            data-bs-toggle="tooltip"
                            title="feather-twitter"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-type"
                            data-bs-toggle="tooltip"
                            title="feather-type"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-umbrella"
                            data-bs-toggle="tooltip"
                            title="feather-umbrella"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-underline"
                            data-bs-toggle="tooltip"
                            title="feather-underline"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-unlock"
                            data-bs-toggle="tooltip"
                            title="feather-unlock"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-upload"
                            data-bs-toggle="tooltip"
                            title="feather-upload"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-upload-cloud"
                            data-bs-toggle="tooltip"
                            title="feather-upload-cloud"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-user"
                            data-bs-toggle="tooltip"
                            title="feather-user"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-user-check"
                            data-bs-toggle="tooltip"
                            title="feather-user-check"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-user-minus"
                            data-bs-toggle="tooltip"
                            title="feather-user-minus"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-user-plus"
                            data-bs-toggle="tooltip"
                            title="feather-user-plus"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-user-x"
                            data-bs-toggle="tooltip"
                            title="feather-user-x"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-users"
                            data-bs-toggle="tooltip"
                            title="feather-users"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-video"
                            data-bs-toggle="tooltip"
                            title="feather-video"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-video-off"
                            data-bs-toggle="tooltip"
                            title="feather-video-off"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-voicemail"
                            data-bs-toggle="tooltip"
                            title="feather-voicemail"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-volume"
                            data-bs-toggle="tooltip"
                            title="feather-volume"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-volume-1"
                            data-bs-toggle="tooltip"
                            title="feather-volume-1"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-volume-2"
                            data-bs-toggle="tooltip"
                            title="feather-volume-2"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-volume-x"
                            data-bs-toggle="tooltip"
                            title="feather-volume-x"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-watch"
                            data-bs-toggle="tooltip"
                            title="feather-watch"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-wifi"
                            data-bs-toggle="tooltip"
                            title="feather-wifi"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-wifi-off"
                            data-bs-toggle="tooltip"
                            title="feather-wifi-off"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-wind"
                            data-bs-toggle="tooltip"
                            title="feather-wind"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-x"
                            data-bs-toggle="tooltip"
                            title="feather-x"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-x-circle"
                            data-bs-toggle="tooltip"
                            title="feather-x-circle"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-x-square"
                            data-bs-toggle="tooltip"
                            title="feather-x-square"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-zap"
                            data-bs-toggle="tooltip"
                            title="feather-zap"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-zap-off"
                            data-bs-toggle="tooltip"
                            title="feather-zap-off"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-zoom-in"
                            data-bs-toggle="tooltip"
                            title="feather-zoom-in"
                          />
                        </li>
                        <li>
                          <i
                            className="feather-zoom-out"
                            data-bs-toggle="tooltip"
                            title="feather-zoom-out"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Chart */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feather;
