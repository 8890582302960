import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  colors,
  fontFamily,
  formats,
  INTERNSHIP_STATUS,
  modules,
  SALARY_PAY_DURATION,
  USER_STATUS,
} from "../utils/enum";
import { autoCompleteStyles, loginTextField } from "../utils/styles";
import {
  INTERNSHIP_WORK_MODE,
  SALARYPAYDURATION_ARRAY,
  skillsOptions,
} from "../utils/defaultArray";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ReactQuill from "react-quill";
import moment from "moment";
import CurriculumListing from "../_components/curriculumList";
import { internshipController } from "../api/internship";
import { debounce } from "lodash";
import { addJobValidation } from "../utils/validation";
import Loading from "react-loading";
import { toast } from "react-toastify";

const EditInternship = () => {
  const location = useLocation();
  const data = location.state;

  const [state, setState] = useState({
    title: "",
    noOfJobs: "",
    experience: "",
    qualification: "",
    description: "",
    location: "",
    skills: [],
    amount: "",
    currency: "$",
    from: "",
    to: "",
    isOpen: true,
    workMode: "",
    rate: "",
  });
  const [error, setError] = useState({
    title: "",
    noOfJobs: "",
    experience: "",
    qualification: "",
    description: "",
    location: "",
    skills: [],
    amount: "",
    currency: "$",
    from: "",
    to: "",
    isOpen: true,
    workMode: "",
    rate: `per ${SALARY_PAY_DURATION.HOUR}`,
  });

  const inputChangeHandler = (e) => {
    let { id, value } = e.target;
    setState({ ...state, [id]: value });
  };
  const [loading, setLoading] = useState(true);
  const [workMode, setWorkMode] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [description, setDescription] = useState("");
  const [skills, setSkills] = useState([]);

  const handleChange = (content, delta, source, editor) => {
    setDescription(editor.getHTML());
    setState({ ...state, description: editor.getHTML() });
    setError({ ...error, description: "" });
  };

  const workModeChangeHandler = (e, newValue, id) => {
    if (id === "workMode") {
      setWorkMode(newValue);
      setState({ ...state, [id]: newValue.label });
    }
    if (id === "skills") {
      setSkills(newValue);

      const newSkills = newValue.map((val) => val.label);
      setState({ ...state, [id]: newSkills });
    }
  };

  const dateChangeHandler = (newDate, id) => {
    if (id === "from") {
      setStartDate(newDate);
      setState({ ...state, [id]: moment(newDate._d).unix() });
    }
    if (id === "to") {
      setEndDate(newDate);
      setState({ ...state, [id]: moment(newDate._d).unix() });
    }
  };
  const [page, setPage] = useState(1);
  const [curriculumData, setCurriculumData] = useState(null);
  const [curriculumLoading, setCurriculumLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const pageChangeHandler = (e, newPage) => {
    setPage(newPage);
    setCurriculumLoading(true);
    let body = {
      status: USER_STATUS.ACTIVE,
      pageSize: 11,
    };
    if (newPage) {
      body.page = newPage;
    }
    if (search) {
      body.search = search;
    }
    getCurriculumList(body);
  };
  const [curriculumValue, setCurriCulumValue] = useState("");
  const handleSelectRadio = (e) => {
    setCurriCulumValue(e.target.value);
  };

  const debouncedSearch = useCallback(
    debounce((body) => {
      getCurriculumList(body);
    }, 300),
    []
  );

  const searchCurriculum = (e) => {
    setSearch(e.target.value);
    let body = {
      page: page,
      status: USER_STATUS.ACTIVE,
      pageSize: 11,
    };
    setCurriculumLoading(true);
    if (e.target.value) {
      body.search = e.target.value;
    }
    debouncedSearch(body);
  };

  const getCurriculumList = (body) => {
    internshipController
      .getCurriculum(body)
      .then((res) => {
        setCurriculumData(res.data.data);
        setCurriculumLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setCurriculumLoading(true);
      });
  };

  useEffect(() => {
    if (data) {
      setState({
        title: data.title,
        noOfJobs: data.noOfJobs,
        experience: data.experience,
        qualification: data.qualification,
        amount: data.stipend.amount,
        description: data.description,
        from: data.duration.from,
        to: data.duration.to,
        rate: data.stipend.rate,
        isOpen: data.status,
        workMode: data.workMode,
        skills: data.skills,
      });
      setWorkMode({
        label: data.workMode,
      });
      setStartDate(moment.unix(data.duration.from));
      setEndDate(moment.unix(data.duration.to));
      setDescription(data.description);
      const initialSkills = data.skills.map((val) => ({ label: val }));
      setSkills(initialSkills);
      setCurriCulumValue(data.curriculum._id);
      setLoading(false);
    }
  }, [data]);
  const navigate = useNavigate();

  const submitHandler = () => {
    if (addJobValidation(state, setError, error)) {
      setSubmitLoading(true);
      let body = {
        title: state.title,
        noOfJobs: state.noOfJobs,
        qualification: state.qualification,
        description: state.description,
        skills: JSON.stringify(state.skills),
        stipend: {
          currency: state.currency,
          amount: state.amount,
          rate: state.rate,
        },
        duration: {
          from: state.from,
          to: state.to,
        },

        workMode: state.workMode,
        experience: state.experience,
        status: INTERNSHIP_STATUS.PUBLISHED,
      };

      if (curriculumValue) {
        body.curriculumId = curriculumValue;
      }
      if (data._id) {
        body._id = data._id;
      }

      internshipController
        .editJobs(body)
        .then((res) => {
          toast.success(res.data.message);
          navigate("/internship-management");
          setSubmitLoading(false);
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) || err.message;
          toast.error(errMessage);
          setSubmitLoading(false);
        });
    }
  };

  useEffect(() => {
    const body = {
      page: page,
      pageSize: 10,
      status: USER_STATUS.ACTIVE,
    };
    getCurriculumList(body);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Edit Internship</title>
      </Helmet>
      <Header />
      <Sidebar />
      <Box className="page-wrapper">
        <Backdrop
          sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Box sx={{ p: 5 }}>
          <Typography
            sx={{
              fontSize: 20,
              fontFamily: fontFamily.roboto,
              fontWeight: 600,
            }}
          >
            Edit Internship
          </Typography>
          <Grid container spacing={4}>
            <Grid item lg={7}>
              <Grid container mt={2} spacing={3}>
                <Grid item lg={6}>
                  <TextField
                    label="Title*"
                    fullWidth
                    sx={{ ...loginTextField }}
                    value={state.title}
                    onChange={inputChangeHandler}
                    id="title"
                  />
                </Grid>
                <Grid item lg={6}>
                  <TextField
                    label="Vacancies*"
                    fullWidth
                    sx={{ ...loginTextField }}
                    value={state.noOfJobs}
                    onChange={inputChangeHandler}
                    id="noOfJobs"
                  />
                </Grid>
                <Grid item lg={6}>
                  <TextField
                    label="Qualification*"
                    fullWidth
                    sx={{ ...loginTextField }}
                    value={state.qualification}
                    id="qualification"
                    onChange={inputChangeHandler}
                  />
                </Grid>
                <Grid item lg={6}>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Work Mode*"
                        fullWidth
                        sx={{ ...loginTextField }}
                        id="workMode"
                      />
                    )}
                    options={INTERNSHIP_WORK_MODE}
                    renderOption={(props, option) => (
                      <Box {...props}>
                        <Typography
                          fontSize={14}
                          fontFamily={fontFamily.roboto}
                        >
                          {option.label}
                        </Typography>
                      </Box>
                    )}
                    sx={{ ...autoCompleteStyles }}
                    value={workMode}
                    onChange={(e, newValue) =>
                      workModeChangeHandler(e, newValue, "workMode")
                    }
                    id="workMode"
                  />
                </Grid>
                <Grid item lg={6}>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <Select
                          sx={{
                            "& fieldSet": {
                              border: "none !important",
                            },
                          }}
                          value={state.rate}
                        >
                          {SALARYPAYDURATION_ARRAY.map((val, i) => (
                            <MenuItem key={i} value={val.label}>
                              <Typography
                                textTransform={"capitalize"}
                                fontSize={13}
                                fontFamily={fontFamily.roboto}
                              >
                                per {val.label}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Select>
                      ),
                    }}
                    sx={{ ...loginTextField }}
                    fullWidth
                    type="number"
                    label="Stipend (in $)*"
                    id="amount"
                    value={state.amount}
                  />
                </Grid>
                <Grid item lg={6}>
                  <TextField
                    label="Experience (in Years)*"
                    sx={{ ...loginTextField }}
                    fullWidth
                    id="experience"
                    value={state.experience}
                  />
                </Grid>
                <Grid item lg={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      sx={{ ...loginTextField, width: "100%" }}
                      label="Start Date*"
                      disablePast
                      format="DD-MM-YYYY"
                      value={startDate}
                      onChange={(newDate) => dateChangeHandler(newDate, "from")}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item lg={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      sx={{ ...loginTextField, width: "100%" }}
                      label="End Date*"
                      disablePast
                      format="DD-MM-YYYY"
                      value={endDate}
                      onChange={(newDate) => dateChangeHandler(newDate, "to")}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item lg={12}>
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    placeholder="Enter Internship Description*"
                    value={description}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item lg={12}>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Skills*"
                        sx={{ ...loginTextField }}
                      />
                    )}
                    options={skillsOptions}
                    getOptionLabel={(option) => option.label}
                    multiple
                    filterSelectedOptions
                    renderOption={(props, options) => (
                      <Box {...props}>
                        <Typography
                          fontSize={14}
                          fontFamily={fontFamily.roboto}
                        >
                          {options.label}
                        </Typography>
                      </Box>
                    )}
                    limitTags={3}
                    sx={{ ...autoCompleteStyles }}
                    value={skills}
                    onChange={(e, newValue) =>
                      workModeChangeHandler(e, newValue, "skills")
                    }
                  />
                </Grid>
              </Grid>
              <Button
                sx={{
                  background: colors.linearGradient,
                  color: "#000",
                  fontSize: 12,
                  mt: 2,
                  ":hover": {
                    background: "transparent",
                  },
                  p: 1,
                  fontFamily: fontFamily.roboto,
                  border: "1px solid #f15d17",
                  textTransform: "initial",
                  transition: "0.5s ease all",
                }}
                onClick={submitHandler}
              >
                {submitLoading ? (
                  <Loading
                    type="bars"
                    width={20}
                    height={20}
                    className="m-auto"
                    color="#000"
                  />
                ) : (
                  "Publish Internship"
                )}
              </Button>
            </Grid>
            <Grid item lg={5}>
              <CurriculumListing
                data={curriculumData}
                onSearch={searchCurriculum}
                page={page}
                onPageChange={pageChangeHandler}
                loading={curriculumLoading}
                onSelect={handleSelectRadio}
                curriculumValue={curriculumValue}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default EditInternship;
