import { ConfirmationNumberOutlined } from "@mui/icons-material";
import {
  Box,
  Card,
  Divider,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { DollarSign, Edit, Percent } from "react-feather";
import { DISCOUNT_TYPE, USER_STATUS } from "../utils/enum";
import { useNavigate } from "react-router-dom";
const CouponCard = ({ val, status, statusHandler }) => {
  const navigate = useNavigate();

  const routeEditPage = (id) => {
    navigate(`/coupon-management/edit-coupon/${id}`);
  };

  return (
    <div>
      <Card
        sx={{
          p: 1,
          background:
            status[val._id] === USER_STATUS.ACTIVE
              ? "transparent"
              : "transparent",
          borderRadius: 2,
          height: 200,
          position: "relative",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"start"}
          mt={1}
        >
          <Stack direction={"row"} spacing={1}>
            <ConfirmationNumberOutlined
              sx={{ rotate: "90deg", fontSize: 25 }}
              htmlColor={
                status[val._id] === USER_STATUS.ACTIVE ? "#000" : "#000"
              }
            />
            <Box>
              {val.discountType === DISCOUNT_TYPE.FIXED ? (
                <Typography fontSize={13} fontFamily={"roboto_slab"}>
                  <DollarSign size={10} /> {val.discountValue}
                </Typography>
              ) : (
                <Typography fontSize={13} fontFamily={"roboto_slab"}>
                  {val.discountValue} <Percent size={10} />
                </Typography>
              )}
              <Typography
                fontSize={12}
                fontWeight={"600 !important"}
                fontFamily={"roboto_slab"}
              >
                {val.code}
              </Typography>
              <Typography
                fontSize={10}
                fontFamily={"roboto_slab"}
                fontWeight={400}
              >
                {val.description}
              </Typography>
            </Box>
          </Stack>
          <IconButton onClick={() => routeEditPage(val._id)}>
            <Edit size={15} color="#000" />
          </IconButton>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ px: 1 }}>
          <Typography fontSize={12} fontFamily={"roboto_slab"} fontWeight={400}>
            Activated on :{" "}
            {moment.unix(val.startTimestamp).format("DD-MM-YYYY")}
          </Typography>
          {val.expiryTimestamp && (
            <Typography
              fontSize={12}
              fontFamily={"roboto_slab"}
              fontWeight={400}
            >
              Expired on :{" "}
              {moment.unix(val.expiryTimestamp).format("DD-MM-YYYY")}
            </Typography>
          )}
          <Typography
            fontSize={12}
            fontFamily={"roboto_slab"}
            fontWeight={400}
            textTransform={"initial"}
          >
            Coupon type : {val.type}
          </Typography>
          <Typography
            fontSize={12}
            fontFamily={"roboto_slab"}
            fontWeight={400}
            textTransform={"initial"}
          >
            User limit : {val.perUserLimit}
          </Typography>
        </Box>
        <Box sx={{ position: "absolute", bottom: 5, width: "100%" }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            px={1}
          >
            <Typography fontSize={10} fontFamily={"plus"} fontWeight={600}>
              {status[val._id]}
            </Typography>
            <Switch
              checked={status[val._id] === USER_STATUS.ACTIVE}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                  backgroundColor: "#f15d17",
                },
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "#f15d17",
                },
              }}
              onChange={() =>
                statusHandler(
                  val._id,
                  val.status === USER_STATUS.ACTIVE
                    ? USER_STATUS.InActive
                    : USER_STATUS.ACTIVE
                )
              }
            />
          </Stack>
        </Box>
      </Card>
    </div>
  );
};

export default CouponCard;
