import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Select2 from 'react-select2-wrapper'
import Header from '../layouts/Header'
import Sidebar from '../layouts/Sidebar'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'
import Select from 'react-select'
import { img10 } from '../_components/imagepath'

const CreateModule = () => {

  const [state, setState] = useState({
    ModuleName: '',
    Read: '',
    Add: '',
    Update: '',
    Delete: '',
    roleName: '',
  })
  const inputHandler = (e) => {
    let { id, value } = e.target
    setState({...state, [id]: value })
  }

  const RoleOptions = [
    { value: 'Admin', label: 'Admin' },
    { value: 'Student', label: 'Student' },
    { value: 'Parent', label: 'Parent' },
    { value: 'Company', label: 'Company' },
  ]

  const selectHandler = (e) => {
    let { value } = e
    setState({ ...state, roleName: value })
  }
  const submitHandler = (e) => {
    e.preventDefault()
    console.log('first', state)
  }

  const [menu, setMenu] = useState(false)
  const onSubmit = (e) => {
    e.preventDefault()
  }
  const toggleMobileMenu = () => {
    setMenu(!menu)
  }

  // const [date, setDate] = useState(new Date());
  // const [RoleOptions, setRoleOptions] = useState([
  // RoleOptions={inputHandler},
  //   { id: 1, text: 'Admin' },
  //   { id: 2, text: 'Student' },
  //   { id: 3, text: 'Parent' },
  //   { id: 4, text: 'Company' },
  // ])

  // const RoleOptions = [
  //   { id: 1, text: 'Admin' },
  //   { id: 2, text: 'Student' },
  //   { id: 3, text: 'Parent' },
  //   { id: 4, text: 'Company' },
  // ]
  return (
    <>
      <div className={`main-wrapper ${menu ? 'slide-nav' : ''}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Page Header */}
            <div className="page-header">
              <div className="content-page-header">
                <h5>Add Modules</h5>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-md-12">
                <form action="#" onSubmit={submitHandler}>
                  <div className="card-body">
                    <div className="form-group-item">
                      {/* <div className="profile-picture">
                        <div className="upload-profile">
                          <div className="profile-img">
                            <img
                              id="blah"
                              className="avatar"
                              src={img10}
                              alt=""
                            />
                          </div>
                          <div className="add-profile">
                            <h5>Upload a New Photo</h5>
                            <span>Profile-pic.jpg</span>
                          </div>
                        </div>
                        <div className="img-upload">
                          <label className="btn btn-primary">
                            Upload <input type="file" />
                          </label>
                          <Link className="btn btn-remove">Remove</Link>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label> Module Name </label>
                            <input
                              type="text"
                              className="form-control module_input"
                              placeholder="Enter Name"
                              id="ModuleName"
                              onChange={inputHandler}
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>Email</label>
                            <input
                              type="email"
                              className="form-control module_input"
                              placeholder="Enter Email Address"
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-lg-4 col-md-6 col-sm-12">
                          {/* <div className="form-group">
                            <label>Phone</label>
                            <IntlTelInput
                              containerClassName="intl-tel-input"
                              inputClassName="form-control mail-icon2"
                              placeholder=""
                            />
                          </div> */}
                        {/* </div>  */}
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>Role Name</label>
                            {/* <Select2
                              id="roleName"
                              className="w-100"
                              onChange={selectHandler}
                              options={RoleOptions}
                              options={{
                                placeholder: 'Role Name',
                                value: { RoleOptions },
                              }} */}
                            {/* /> */}
                            <Select
                              onChange={selectHandler}
                              options={RoleOptions}
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>Website</label>
                            <input
                              
                              type="text"
                              className="form-control module_input"
                              placeholder="Enter Website Address"
                            />
                          </div>
                        </div> */}
                        {/* <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>Notes</label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter Your Notes"
                            />
                          </div>
                        </div> */}
                      </div>
                    </div>
                    {/* <div className="form-group-item">
                      {/* <div className="row">
                        <div className="col-md-6">
                          <div className="billing-btn mb-2">
                            <h5 className="form-title">Billing Address</h5>
                          </div>
                          <div className="form-group">
                            <label>Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Name"
                            />
                          </div>
                          <div className="form-group">
                            <label>Address Line 1</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Address 1"
                            />
                          </div>
                          <div className="form-group">
                            <label>Address Line 2</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Address 1"
                            />
                          </div>
                          <div className="row">
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group">
                                <label>Country</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Country"
                                />
                              </div>
                              <div className="form-group">
                                <label>City</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="City"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group">
                                <label>State</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="State"
                                />
                              </div>
                              <div className="form-group">
                                <label>Pincode</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Pincode"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="billing-btn">
                            <h5 className="form-title mb-0">
                              Shipping Address
                            </h5>
                            <Link to="#" className="btn btn-primary">
                              Copy from Billing
                            </Link>
                          </div>
                          <div className="form-group">
                            <label>Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Name"
                            />
                          </div>
                          <div className="form-group">
                            <label>Address Line 1</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Address 1"
                            />
                          </div>
                          <div className="form-group">
                            <label>Address Line 2</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Address 1"
                            />
                          </div>
                          <div className="row">
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group">
                                <label>Country</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Country"
                                />
                              </div>
                              <div className="form-group">
                                <label>City</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="City"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group">
                                <label>State</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="State"
                                />
                              </div>
                              <div className="form-group">
                                <label>Pincode</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Pincode"
                                />
                              </div>
                            </div>
                          </div>
                        </div> */}
                    {/* </div>  */}
                    {/* </div> */}
                    <div className="form-group-customer customer-additional-form">
                      <div className="row">
                        {/* <div className="col-lg-4 col-md-6 col-sm-12">
                            {/* <div className="form-group">
                                <h5 className="form-title">
                                Additional Details{" "}
                                <span className="optional">(Optional)</span>
                                </h5>
                            </div> */}
                        {/* <div className="form-group">
                                <label>
                                Discount <span className="optional">(%)</span>
                                </label>
                                <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Discount"
                                />
                            </div> */}
                        {/* <div className="form-group notes-form-group">
                                <label>Notes</label>
                                <textarea
                                className="form-control"
                                placeholder="Enter Your Description"
                                defaultValue={""}
                                />
                            </div> */}
                        {/* <div className="form-group">
                                <div className="check-mark-status d-flex justify-content-between">
                                <h6 className="label-text">TDS</h6>
                                <div className="status-toggle">
                                    <input
                                    id="rating_1"
                                    className="check"
                                    type="checkbox"
                                    defaultChecked="true"
                                    />
                                    <label
                                    htmlFor="rating_1"
                                    className="checktoggle checkbox-bg"
                                    >
                                    checkbox
                                    </label>
                                </div>
                                </div>
                            </div> */}
                        {/* </div>  */}
                        {/* <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="form-group">
                                <h5 className="form-title">
                                Company Details{" "}
                                <span className="optional">(Optional)</span>
                                </h5>
                            </div>
                            <div className="form-group">
                                <label>Company Name</label>
                                <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Company Name"
                                />
                            </div>
                            {/* <div className="form-group">
                                <label>GST No</label>
                                <input
                                type="text"
                                className="form-control"
                                placeholder="Enter GST Nuber"
                                />
                            </div> */}
                        {/* </div>  */}
                        <div className="col-lg-4 col-md-12 col-sm-12">
                          <div className="form-group">
                            <h5 className="form-title">
                              Module Action{' '}
                              {/* <span className="optional">(Optional)</span> */}
                            </h5>
                          </div>
                          <div className="form-group">
                            <label>Permissions</label>
                            <div className="">
                              <label className="custom_radio mx-3">
                                <input
                                  type="checkbox"
                                  id="Read"
                                  name="Read"
                                  onChange={inputHandler}
                                />
                                <span className="checkmark" /> Read
                              </label>
                              <label className="custom_radio mx-3">
                                <input
                                  type="checkbox"
                                  id="Add"
                                  name="add"
                                  onChange={inputHandler}
                                />
                                <span className="checkmark" /> Add
                              </label>
                              <label className="custom_radio mx-3">
                                <input
                                  type="checkbox"
                                  id="Update"
                                  name="Update"
                                  onChange={inputHandler}
                                />
                                <span className="checkmark" /> Update
                              </label>
                              <label className="custom_radio">
                                <input
                                  type="checkbox"
                                  id="Delete"
                                  name="Delete"
                                  onChange={inputHandler}
                                />
                                <span className="checkmark" /> Delete
                              </label>
                            </div>
                          </div>
                          {/* <div className="form-group">
                                <label>Debit Amount</label>
                                <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Debit Amount"
                                />
                            </div> */}
                          {/* <div className="form-group">
                                <label>Balance</label>
                                <input
                                type="text"
                                className="form-control bg-blue-light border-0"
                                placeholder="$ 100"
                                />
                            </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="add-customer-btns text-end">
                      {/* <Link className="btn btn-primary me-2">Cencle</Link> */}
                      {/* <Link className="btn btn-primary me-2">Submit</Link> */}
                      <button type="reset" className="btn btn-primary ">
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary ml-3">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CreateModule;
