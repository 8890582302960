export default {
  Data: [
      {
        id: 1,
        Invoice: "#4987",
        Category: "Food",
        Created: "23 Mar 2023",
        Total: "$1,54,220",
        Paid: "$1,50,000",
        Payment: "Cash",
        Balance: "$2,54,00",
        Due: "25 Mar 2023",
        Status: "Paid",
        Action: "",
      },
      {
        id: 2,
        Invoice: "#4988",
        Category: "Advertising",
        Created: "16 Mar 2022",
        Total: "$3,54,220",
        Paid: "$2,50,000",
        Payment: "Cheque",
        Balance: "$4,220",
        Due: "16 Jan 2023",
        Status: "Paid",
        Action: "",
      },
      {
        id:3,
        Invoice: "#4989",
        Category: "Marketing",
        Created: "25 Feb 2023",
        Total: "$1,54,220",
        Paid: "$1,50,000",
        Payment: "Cash",
        Balance: "$4,220",
        Due: "16 Jan 2023",
        Status: "Paid",
        Action: "",
      },
      {
        id:4,
        Invoice: "#4990",
        Category: "Repairs",
        Created: "25 Mar 2022",
        Total: "$1,54,220",
        Paid: "$1,50,000",
        Payment: "Cash",
        Balance: "$4,220",
        Due: "12 May 2023",
        Status: "Paid",
        Action: "",
      },
      {
        id:5,
        Invoice: "#4991",
        Category: "Software",
        Created: "12 May 2022",
        Total: "$5,54,220",
        Paid: "$3,50,000",
        Payment: "Cheque",
        Balance: "$4,220",
        Due: "18 May 2023",
        Status: "Paid",
        Action: "",
      },
      {
        id:6,
        Invoice: "#4992",
        Category: "Stationary",
        Created: "16 Nov 2022",
        Total: "$5,54,220",
        Paid: "$6,50,000",
        Payment: "Cash",
        Balance: "$4,220",
        Due: "25 Feb 2023",
        Status: "Paid",
        Action: "",
      },
    ],
};
