import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { hideModal } from "../../redux/reducers/modal";

export default function Modal() {
  const dispatch = useDispatch();
  const content = useSelector((state) => state.modal.content);

  function closeModal() {
    const modalBackground = document.querySelector(".modal__background");
    const modalContent = document.querySelector(".modal__content");

    if (modalBackground && modalContent) {
      modalBackground.classList.remove("open");
      modalContent.classList.remove("open");
      setTimeout(() => {
        dispatch(hideModal());
      }, 300); // Wait for the transition to complete before hiding the modal
    }
  }

  return (
    <div
      onClick={(e) => {
        if (e.target.classList.contains("modal__background")) {
          closeModal();
        }
      }}
      className={`modal__background ${content ? "open" : ""}`}
    >
      {content && (
        <div className={`modal__content ${content ? "open" : ""}`}>
          {/* <span className={"modal__content__close pointer"} onClick={closeModal}>
            ✕
          </span> */}
          {content}
        </div>
      )}
    </div>
  );
}
