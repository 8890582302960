import { Add, TextSnippet } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { colors, USER_STATUS } from "../utils/enum";
import { internshipController } from "../api/internship";
import { loginTextField } from "../utils/styles";
import TabPanel from "../_components/tabPanel";
import CollapsableTable from "../_components/collapableTable";
import Loading from "react-loading";
import CustomToast from "../_components/customToast";
import { handleToastClose } from "../utils/functions";
import { debounce } from "lodash";

const status = [
  {
    label: USER_STATUS.ALL,
  },
  {
    label: USER_STATUS.ACTIVE,
  },
  {
    label: USER_STATUS.InActive,
  },
];

const Curriculum = () => {
  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [listLoading, setListLoading] = useState(true);

  const [tabValue, setTabValue] = useState(0);
  const [search, setSearch] = useState("");
  const [curriculumStatus, setCurriculumStatus] = useState("");

    const debouncedGetCurriculum = useCallback(
      debounce((body) => {
        getAddedCurriculum(body);
      }, 300),
      []
    );

  const searchHandler = (e) => {
    setListLoading(true);
    setSearch(e.target.value);
    let body = {
      page: page,
      pageSize: pageSize,
    };
    if (curriculumStatus) {
      body.status = curriculumStatus;
    }

    if (e.target.value) {
      body.search = e.target.value;
    }
    debouncedGetCurriculum(body);
  };

  const tabChangeHandler = (e, newValue) => {
    setListLoading(true);
    setTabValue(newValue);
    const tabStatus =
      e.target.textContent !== USER_STATUS.ALL && e.target.textContent;
    setCurriculumStatus(tabStatus);
    let body = {
      page: page,
      pageSize: pageSize,
    };
    if (tabStatus === USER_STATUS.ALL) {
    }
    if (tabStatus === USER_STATUS.ACTIVE) {
      body.status = tabStatus;
    }
    if (tabStatus === USER_STATUS.InActive) {
      body.status = tabStatus;
    }
    if (search) {
      body.search = search;
    }
    getAddedCurriculum(body);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const addCurriculumModal = () => {
    navigate("/add-curriculum");
  };
  const [id, setId] = useState("");
  const [toast, setToast] = useState({
    message: "",
    open: false,
    variant: "",
  });
  const changeStatusHandler = ({ id, status }) => {
    setListLoading(true);
    let data = {
      id: id,
      status: status,
    };
    internshipController
      .activeInActiveStatusChange(data)
      .then((res) => {
        let body = {
          page: page,
          pageSize: pageSize,
        };
        setToast({
          ...toast,
          open: true,
          message: res.data.message,
          variant: "success",
        });
        getAddedCurriculum(body);
        setListLoading(false);
      })
      .catch((err) => {
        let errMessage =
          (err.response && err.response.data.message) || err.message;
        setToast({
          ...toast,
          open: true,
          message: errMessage,
          variant: "error",
        });
        setListLoading(false);
      });
  };

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState(null);
  const getAddedCurriculum = (data) => {
    internshipController
      .getCurriculum(data)
      .then((res) => {
        const response = res.data.data;
        setData(response);
        setListLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setListLoading(false);
      });
  };

  useEffect(() => {
    let body = {
      page: page,
      pageSize: pageSize,
    };
    getAddedCurriculum(body);
  }, []);

  useEffect(() => {
    const id = localStorage.getItem("curriculumId");
    if (id) {
      localStorage.removeItem("curriculumId");
    }
  }, []);
  return (
    <div className="page-wrapper">
      <Header onMenuClick={toggleMobileMenu} />
      <Sidebar PathName={location.pathname} />
      <Box sx={{ p: 2 }}>
        <Card sx={{ mt: 2 }}>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
            px={2}
          >
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              {/* <Box
                sx={{
                  background: colors.linearGradient,
                  width: 20,
                  height: 20,
                }}
              ></Box> */}
              <TextSnippet />
              <Typography fontSize={18} fontWeight={600}>
                Curriculum
              </Typography>
            </Stack>
            <Tabs
              sx={{
                "& .MuiTab-root": {
                  ":hover": {
                    color: "#f15d17",
                    backgroundColor: "transparent",
                  },
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#f15d17",
                },
                "& .Mui-selected": {
                  color: "#f17d15 !important",
                },
              }}
              selectionFollowsFocus
              value={tabValue}
              onChange={tabChangeHandler}
            >
              {status.map((val, i) => (
                <Tab
                  label={
                    <Typography key={i} fontSize={12} fontWeight={550}>
                      {val.label}
                    </Typography>
                  }
                />
              ))}
            </Tabs>
            <Button
              sx={{
                background: colors.linearGradient,
                color: "#fff",
                fontSize: 12,
                fontWeight: 500,
              }}
              startIcon={<Add />}
              onClick={addCurriculumModal}
            >
              Add Curriculum
            </Button>
          </Stack>
          <Divider sx={{ borderColor: "#d7d7d7" }} />
          <Box sx={{ p: 1, mt: 1 }}>
            <TextField
              fullWidth
              sx={{ ...loginTextField }}
              label="Search"
              onChange={searchHandler}
            />
          </Box>
          <Box sx={{ p: 1 }}>
            {status.map((val, i) => (
              <TabPanel value={tabValue} index={i}>
                {listLoading ? (
                  <Loading
                    type="bars"
                    className="m-auto"
                    width={20}
                    height={20}
                    color="#000"
                  />
                ) : data?.docs.length ? (
                  <CollapsableTable
                    data={data}
                    statusChangeHandler={changeStatusHandler}
                    setId={setId}
                    id={id}
                  />
                ) : (
                  <Typography
                    sx={{ fontSize: 14, textAlign: "center", fontWeight: 600 }}
                  >
                    No Curriculum Found
                  </Typography>
                )}
              </TabPanel>
            ))}
          </Box>
          <CustomToast
            open={toast.open}
            message={toast.message}
            variant={toast.variant}
            handleClose={() => handleToastClose({ toast, setToast })}
          />
        </Card>
      </Box>
    </div>
  );
};

export default Curriculum;
