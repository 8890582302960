import { img1, img11, img2, img4, img5, img6, img7, img8, img9 } from "../../_components/imagepath";

export default {
  Data: [
    {
      Id: 1,
      Ticket: "#4987",
      Subject: "Support for theme",
      Assigned: "Charles Hafner",
      AssignedDate: "17 Dec 2022",
      CreatedOn: "19 Dec 2022, 6:12PM",
      DueDate: "16 Jan 2023",
      Img:img7,
      img:img1,
      Assignee: "John Smith",
      Phone:"+1 843-443-3282",
      LastReply: "18 Jan 2023",
      Priority: "Medium",
      Status: "Paid",
      StatusPending: "Pending",
      StatusOverdue: "Overdue",
      StatusDraft: "Paid",
      StatusRecurring: "Recurring",
      StatusCancelled: "Cancelled",
      Action: "",
    },
    {
      Id: 2,
      Ticket: "#4988",
      Subject: "verify your email",
      Assigned: "Michel",
      AssignedDate: "21 Dec 2022",
      CreatedOn: "23 Dec 2022, 6:12PM",
      DueDate: "28 Feb 2023",
      Img:img2,
      img:img5,
      Assignee: "John Smith",
      Phone:"+1 843-443-4298",
      LastReply: "21 Jan 2023",
      Priority: "Low",
      Status: "Paid",
      StatusPending: "Pending",
      StatusOverdue: "Overdue",
      StatusDraft: "Paid",
      StatusRecurring: "Recurring",
      StatusCancelled: "Cancelled",
      Action: "",
    },
    {
      Id: 3,
      Ticket: "#4989",
      Subject: "Calling for help",
      Assigned: "Sharonda",
      AssignedDate: "28 Jan 2022",
      CreatedOn: "29 Jan 2022, 6:12PM",
      DueDate: "28 Mar 2023",
      Img:img4,
      img:img6,
      Assignee: "Pricilla",
      Phone:"+1 843-443-5298",
      LastReply: "21 Jan 2023",
      Priority: "High",
      Status: "Paid",
      StatusPending: "Pending",
      StatusOverdue: "Overdue",
      StatusDraft: "Paid",
      StatusRecurring: "Recurring",
      StatusCancelled: "Cancelled",
      Action: "",
    },
    {
      Id: 4,
      Ticket: "#4990",
      Subject: "Management",
      Assigned: "Sharonda",
      AssignedDate: "27 Feb 2022",
      CreatedOn: "28 Feb 2022, 6:12PM",
      DueDate: "29 Mar 2023",
      Img:img8,
      img:img9,
      Assignee: "Randall",
      Phone:"+1 843-443-5298",
      LastReply: "25 Jan 2023",
      Priority: "Medium",
      Status: "Paid",
      StatusPending: "Pending",
      StatusOverdue: "Overdue",
      StatusDraft: "Paid",
      StatusRecurring: "Recurring",
      StatusCancelled: "Cancelled",
      Action: "",
    },
    {
      Id: 5,
      Ticket: "#4991",
      Subject: "verify your email",
      Assigned: "Esther",
      AssignedDate: "21 Dec 2022",
      CreatedOn: "23 Dec 2022, 6:12PM",
      DueDate: "28 Feb 2023",
      Img:img11,
      img:img7,
      Assignee: "Charles Hafner",
      Phone:"+1 843-443-4298",
      LastReply: "21 Jan 2023",
      Priority: "Low",
      Status: "Paid",
      StatusPending: "Pending",
      StatusOverdue: "Overdue",
      StatusDraft: "Paid",
      StatusRecurring: "Recurring",
      StatusCancelled: "Cancelled",
      Action: "",
    },
    {
      Id: 6,
      Ticket: "#4992",
      Subject: "Calling for help",
      Assigned: "Randall",
      AssignedDate: "28 Jan 2022",
      CreatedOn: "30 Jan 2022, 6:12PM",
      DueDate: "29 Mar 2023",
      Img:img9,
      img:img4,
      Assignee: "Sharonda",
      Phone:"+1 843-443-4987",
      LastReply: "21 Jan 2023",
      Priority: "High",
      Status: "Paid",
      StatusPending: "Pending",
      StatusOverdue: "Overdue",
      StatusDraft: "Paid",
      StatusRecurring: "Recurring",
      StatusCancelled: "Cancelled",
      Action: "",
    },
    {
      Id: 7,
      Ticket: "#4992",
      Subject: "Support for theme",
      Assigned: "Charles Hafner",
      AssignedDate: "17 Dec 2022",
      CreatedOn: "19 Dec 2022, 6:12PM",
      DueDate: "16 Jan 2023",
      Img:img7,
      img:img1,
      Assignee: "John Smith",
      Phone:"+1 843-443-3282",
      LastReply: "18 Jan 2023",
      Priority: "Medium",
      Status: "Paid",
      StatusPending: "Pending",
      StatusOverdue: "Overdue",
      StatusDraft: "Paid",
      StatusRecurring: "Recurring",
      StatusCancelled: "Cancelled",
      Action: "",
    },
    {
      Id: 8,
      Ticket: "#4993",
      Subject: "verify your email",
      Assigned: "Michel",
      AssignedDate: "21 Dec 2022",
      CreatedOn: "23 Dec 2022, 6:12PM",
      DueDate: "28 Feb 2023",
      Img:img2,
      img:img5,
      Assignee: "John Smith",
      Phone:"+1 843-443-4298",
      LastReply: "21 Jan 2023",
      Priority: "Low",
      Status: "Paid",
      StatusPending: "Pending",
      StatusOverdue: "Overdue",
      StatusDraft: "Paid",
      StatusRecurring: "Recurring",
      StatusCancelled: "Cancelled",
      Action: "",
    },
    {
      Id: 9,
      Ticket: "#4994",
      Subject: "Calling for help",
      Assigned: "Sharonda",
      AssignedDate: "28 Jan 2022",
      CreatedOn: "29 Jan 2022, 6:12PM",
      DueDate: "28 Mar 2023",
      Img:img4,
      img:img6,
      Assignee: "Pricilla",
      Phone:"+1 843-443-5298",
      LastReply: "21 Jan 2023",
      Priority: "High",
      Status: "Paid",
      StatusPending: "Pending",
      StatusOverdue: "Overdue",
      StatusDraft: "Paid",
      StatusRecurring: "Recurring",
      StatusCancelled: "Cancelled",
      Action: "",
    },
  ],
};
