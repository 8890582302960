import React, { useCallback, useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import Authcontrollers from "../../api/authController";
// import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import "./index.css";
// import { Table } from 'react-bootstrap'
import { Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../pagination/pagination";
import Loading from "../Loading";
import userController from "../../api/users";
import { colors, USER_GROUP } from "../../utils/enum";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import CustomButton from "../buttons/customButton";
import Input from "../input";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { DownloadForOffline, EscalatorWarning } from "@mui/icons-material";
import { tabs } from "../../utils/defaultArray";
import { loginTextField, tabSection } from "../../utils/styles";
import CustomToast from "../customToast";
import { handleToastClose } from "../../utils/functions";
import TabPanel from "../tabPanel";
import ParentTable from "./parentTable";
import { debounce } from "lodash";
const Getstudent = () => {
  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const [tabValue, setTabValue] = useState(0);

  const tabChangeHandler = (e, newValue) => {
    setTabValue(newValue);
  };
  const [statuses, setStatuses] = useState({});

  const [list, setList] = useState(null);

  const [totalPages, setTotalPages] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const parentList = (value) => {
    userController
      .getUsers(value)
      .then((res) => {
        setList(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const pageChangeHandler = (e, newPage) => {
    setLoading(true);
    setPage(newPage);
    let body = {
      pageSize: pageSize,
      group: USER_GROUP.PARENT,
    };
    if (newPage) {
      body.page = newPage + 1;
    }

    parentList(body);
  };

  const navigate = useNavigate();
  const Navigation = () => {
    navigate("/profilepage", { state: list });
  };

  const [toast, setToast] = useState({ message: "", variant: "", open: false });
  const [csvLoading, setCsvLoading] = useState(false);
  const exportCsv = (user) => {
    const a = document.createElement("a");
    setCsvLoading(true);
    userController
      .csvDownload(user)
      .then((res) => {
        const blob = new Blob([res.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `${user}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

        document.body.removeChild(a);
        setCsvLoading(false);
      })
      .catch((err) => {
        let errMessage =
          (err.response && err.response.data.message) || err.message;
        setToast({
          ...toast,
          message: errMessage,
          variant: "error",
          open: true,
        });
      });
  };

  const [sortBy, setSortBy] = useState({ field: "", order: "asc" });
  const [search, setSearch] = useState("");

  const getFilterParentList = useCallback(
    debounce((body) => {
      parentList(body);
    }, 300),
    []
  );

  const searchHandler = (e) => {
    setSearch(e.target.value);
    let body = { page: page, pageSize: pageSize, group: USER_GROUP.PARENT };
    setLoading(true);
    if (sortBy.field) {
      body.sortBy = { [sortBy.field]: sortBy.order.toUpperCase() };
    }
    if (e.target.value) {
      body.search = e.target.value;
    }
    getFilterParentList(body);
  };

  const sortingHandler = (e, name) => {
    let newOrder = "asc";
    setLoading(true);

    if (sortBy.field === name) {
      newOrder = sortBy.order === "asc" ? "desc" : "asc";
    }

    setSortBy({ field: name, order: newOrder });

    const body = {
      group: USER_GROUP.STUDENT,
      page: page,
      pageSize: pageSize,
      sortBy: { [name]: newOrder.toUpperCase() },
    };

    if (search) {
      body.search = search;
    }

    parentList(body);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    sortingHandler();
  };
  useEffect(() => {
    // student_list();
    let body = {
      page: page,
      pageSize: pageSize,
      group: USER_GROUP.PARENT,
    };
    parentList(body);
  }, []);

  useEffect(() => {
    if (list && list.docs) {
      const initialStatuses = list.docs.reduce((acc, curr) => {
        acc[curr._id] = curr.status;
        return acc;
      }, {});
      setStatuses(initialStatuses);
    }
  }, [list?.docs]);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header
        onMenuClick={(value) => toggleMobileMenu()}
        onSearch={searchHandler}
        onSubmit={submitHandler}
      />
      <Sidebar />
      {/* <div className="page-wrapper">
        <div className=" pb-2">
          <div className="d-flex align-items-center justify-content-between  mt-2">
            <div
              className="d-flex align-items-center"
              style={{ marginLeft: "15px" }}
            >
              <div
                className="square-box"
                style={{ width: "20px", height: "20px" }}
              ></div>
              <h5>Registered Parents</h5>
            </div>

            <button
              className="f-13 pointer btn btn-primary"
              style={{ marginRight: "15px" }}
              onClick={() => exportCsv(USER_GROUP.PARENT)}
            >
              Export CSV
            </button>
          </div>
        </div>

        {loading ? (
          <Loading
            type="bars"
            className="m-auto"
            color="#f15d17"
            width={25}
            height={25}
          />
        ) : (
          <div style={{ marginLeft: "15px", marginRight: "15px" }}>
            <Table bordered hover responsive>
              <thead style={{ backgroundColor: "#eee" }}>
                <tr style={{ backgroundColor: "#eee" }}>
                  {tablehead.map((val, i) => (
                    <th key={i}>
                      <div className="d-flex align-items-end justify-content-between">
                        {val.name}
                        {val.icon ? (
                          <div className="icon__filter">
                            <FontAwesomeIcon
                              icon={faCaretUp}
                              size={2}
                              onClick={() => sortingHandler("ASC", val.name)}
                              className="filter_up pointer"
                            />
                            <br />
                            <FontAwesomeIcon
                              icon={faCaretDown}
                              size={2}
                              onClick={() => sortingHandler("DESC", val.name)}
                              className="pointer"
                            />
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody style={{ backgroundColor: "#ffffff" }}>
                {list.map((val, i) => (
                  <tr key={i}>
                    <td>{val.name}</td>
                    <td>{val.email}</td>
                    <td>{val.phoneNo ? val.phoneNo : "--"}</td>
                    <td>
                      {val.createdAt
                        ? moment(val.createdAt).format("MMM-DD-YYYY")
                        : "--"}
                    </td>
                    <td>
                      {val.lastLogin
                        ? moment(val.lastLogin).format("MMM-DD-YYYY")
                        : "--"}
                    </td>
                  </tr>
                ))}
                <tr></tr>
              </tbody>
            </Table>
            <Pagination
              pageCount={totalPages}
              onPageChange={(e) =>
                parentList({
                  group: USER_GROUP.PARENT,
                  page: e.selected + 1,
                  pageSize: 20,
                })
              }
            />
          </div>
        )}
      </div> */}
      <Box className="page-wrapper">
        <Box sx={{ p: 5 }}>
          <Card>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              px={2}
              py={0.5}
            >
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <EscalatorWarning />
                <Typography
                  fontFamily={"roboto_slab"}
                  fontSize={15}
                  fontWeight={550}
                >
                  Parents
                </Typography>
              </Stack>
              <Tabs
                sx={{ ...tabSection }}
                onChange={tabChangeHandler}
                value={tabValue}
              >
                {tabs.map((val, i) => (
                  <Tab
                    label={
                      <Typography
                        sx={{
                          fontFamily: "plus",
                          fontSize: 12,
                          fontWeight: 550,
                        }}
                      >
                        {val.label}
                      </Typography>
                    }
                    key={i}
                  />
                ))}
              </Tabs>
              <Button
                sx={{
                  background: colors.linearGradient,
                  color: "#000",
                  fontSize: 12,
                  width: 150,
                  p: 1,
                }}
                startIcon={
                  csvLoading ? (
                    <CircularProgress size={20} sx={{ color: "#000" }} />
                  ) : (
                    <DownloadForOffline />
                  )
                }
                onClick={() => exportCsv(USER_GROUP.PARENT)}
              >
                {csvLoading ? "Exporting CSV.." : "Export CSV"}
              </Button>
            </Stack>
            <Divider />
            <Box sx={{ p: 1 }}>
              <TextField
                sx={{ ...loginTextField }}
                label="Search"
                fullWidth
                onChange={searchHandler}
              />
            </Box>
            {tabs.map((val, i) => (
              <TabPanel index={i} value={tabValue}>
                {loading ? (
                  <Loading
                    type="bars"
                    width={20}
                    height={20}
                    color="#000"
                    className="m-auto"
                  />
                ) : (
                  <ParentTable
                    data={list}
                    parentStatus={statuses}
                    setParentStatus={setStatuses}
                    page={page}
                    pageSize={pageSize}
                    pageChangeHandler={pageChangeHandler}
                  />
                )}
              </TabPanel>
            ))}
          </Card>
        </Box>
        <CustomToast
          open={toast.open}
          message={toast.message}
          variant={toast.variant}
          handleClose={() => handleToastClose({ toast, setToast })}
        />
      </Box>
    </div>
  );
};
export default Getstudent;
