import React, { useEffect, useState } from 'react'
import Sidebar from '../../layouts/Sidebar'
import Header from '../../layouts/Header'
import { Pagination, Table } from "antd";

const Vroar_staff = () => {
    const [menu, setMenu] = useState(false)
  const toggleMobileMenu = () => {
    setMenu(!menu)
  }
  return (
    <div className={`main-wrapper ${menu ? 'slide-nav' : ''}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div style={{ marginLeft: '270px' }}>
        <h3 style={{ marginTop: '80px', marginBottom:'30px' }}>Staff List</h3>
        <div>
            <Table>

            </Table>
        </div>
      </div>
      </div>
  )
}

export default Vroar_staff;