import React from "react";

const CustomButton = (props) => {
  return (
    <button
      className={props.className}
      onClick={props.onClick}
      style={{
        height: props.height,
        width: props.width,
        padding: props.padding,
        border: props.border,
        borderRadius: props.rounded,
        zIndex: 0,
        marginRight: props.mr,
      }}
      type={props.type}
    >
      {props.children}
    </button>
  );
};

export default CustomButton;
