import { configureStore } from "@reduxjs/toolkit";
import modal from "../reducers/modal";

export default configureStore({
  reducer: { modal },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
