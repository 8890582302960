import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FilterChart, search } from '../../_components/imagepath'
const AddUsersVendor = ({ show, setShow, title }) => {
  return (
    <>
      <div className={`toggle-sidebar ${show ? 'open-filter' : ''}`}>
        <div className="sidebar-layout-filter">
          <div className="sidebar-header">
            <h5>Filter</h5>
            <Link
              to="#"
              className="sidebar-closes"
              onClick={() => setShow(!show)}
            >
              <i className="fa-regular fa-circle-xmark" />
            </Link>
          </div>
          <div className="sidebar-body">
            <form action="#" autoComplete="off">
              {/* Customer */}
              <div className="accordion" id="accordionMain1">
                <div className="card-header-new" id="headingOne">
                  <h6 className="filter-title">
                    <Link
                      to="#"
                      className="w-100"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {title === '' ? 'Customer' : title}
                      {/* Customer */}
                      <span className="float-end">
                        <i className="fa-solid fa-chevron-down" />
                        search
                      </span>
                    </Link>
                  </h6>
                </div>
                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample1"
                >
                  <div className="card-body-chat">
                    <div className="row">
                      <div className="col-md-12">
                        <div id="checkBoxes1">
                          <div className="form-custom">
                            <input
                              type="text"
                              className="form-control"
                              id="member_search1"
                              placeholder="Search by name"
                            />
                            <span>
                              <img src={search} alt="img" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion" id="accordionMain2">
                <div className="card-header-new" id="headingTwo">
                  <h6 className="filter-title">
                    <Link
                      to="#"
                      className="w-100 collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      Select Date
                      <span className="float-end">
                        <i className="fa-solid fa-chevron-down" />
                      </span>
                    </Link>
                  </h6>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample2"
                >
                  <div className="card-body-chat">
                    <div className="form-group">
                      <label className="form-control-label">From</label>
                      <div className="cal-icon">
                        <input
                          type="email"
                          className="form-control datetimepicker"
                          placeholder="DD-MM-YYYY"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-control-label">To</label>
                      <div className="cal-icon">
                        <input
                          type="email"
                          className="form-control datetimepicker"
                          placeholder="DD-MM-YYYY"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion" id="accordionMain3">
                <div className="card-header-new" id="headingThree">
                  <h6 className="filter-title">
                    <Link
                      to="#"
                      className="w-100 collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      By Status
                      <span className="float-end">
                        <i className="fa-solid fa-chevron-down" />
                      </span>
                    </Link>
                  </h6>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample3"
                >
                  <div className="card-body-chat">
                    <div id="checkBoxes2">
                      <div className="form-custom">
                        <input
                          type="text"
                          className="form-control"
                          id="member_search2"
                          placeholder="Search here"
                        />
                        <span>
                          <img src={search} alt="img" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className="d-inline-flex align-items-center justify-content-center btn w-100 login-bttn"
              >
                <span>
                  <img src={FilterChart} className="me-2" alt="search" />
                </span>
                Search
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddUsersVendor;
