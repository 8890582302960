export const USER_GROUP = {
  VROAR: "VROAR",
  PARENT: "PARENT",
  STUDENT: "STUDENT",
  COMPANY: "COMPANY",
  COMPANY_STAFF: "COMPANY_STAFF",
  VROAR_STAFF: "VROAR_STAFF",
};
export const USER_PERMISSION = {
  READ: "READ",
  ADD: "ADD",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

export const colors = {
  transparent: "transparent",
  primary: "#f15d17",
  linearGradient: "linear-gradient(#f15b65, #fcb52e)",
};

export const USER_STATUS = {
  ALL: "ALL",
  ACTIVE: "ACTIVE",
  BANNED: "BANNED",
  InActive: "INACTIVE",
};

export const SORT = {
  NAME: "Name",
  LASTLOGIN: "Last Login",
};

export const SUBSCRIPTION_PLAN_TYPE = {
  COURSE: "COURSE",
  INTERNSHIP: "INTERNSHIP",
};

export const DISCOUNT_TYPE = {
  PERCENTAGE: "PERCENTAGE",
  FIXED: "FIXED",
};

export const PROMOTION_TYPE = {
  VOUCHER: "VOUCHER",
  DISCOUNT: "DISCOUNT",
  PROMOTION: "PROMOTION",
  OFFER: "OFFER",
};

export const PROMOTION_SCENARIOS = {
  FIRST_TIME_LOGIN: "FIRST_TIME_LOGIN",
  SUBSCRIPTION: "SUBSCRIPTION",
  ALL: "ALL",
};

export const fontFamily = {
  roboto: "roboto_slab",
  plus: "plus",
};

export const SUBSCRIPTION_TIER = {
  BASIC: "BASIC",
  PREMIUM: "PREMIUM",
  PRO: "PRO",
  ENTERPRISE: "ENTERPRISE",
};

export const PLAN_DURATION_TYPE = {
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY",
  CUSTOM: "CUSTOM",
};

export const SALARY_PAY_DURATION = {
  HOUR: "hour",
  ANNUM: "annum",
};

export const WORK_MODE = {
  ON_SITE: "On-site",
  REMOTE: "Remote",
  Hybrid: "Hybrid",
};

export const INTERNSHIP_STATUS = {
  OPEN: "Open",
  CLOSED: "Closed",
  ON_HOLD: "On Hold",
  DRAFT: "Draft",
  PUBLISHED: "Published",
  AWAITING: "Awaiting",
  ALL: "All",
};


export const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
};

export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];
