import { Loyalty } from "@mui/icons-material";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Loading from "react-loading";
import ReactQuill from "react-quill";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomToast from "../_components/customToast";
import { subscriptionController } from "../api/subscriptionController";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { colors, SUBSCRIPTION_PLAN_TYPE } from "../utils/enum";
import { handleToastClose } from "../utils/functions";
import { loginTextField } from "../utils/styles";
import { AddSubscriptionValidation } from "../utils/validation";
import { Helmet } from "react-helmet";

const subscriptionPlanType = [
  {
    label: SUBSCRIPTION_PLAN_TYPE.INTERNSHIP,
  },
  {
    label: SUBSCRIPTION_PLAN_TYPE.COURSE,
  },
];

const EditSubscription = () => {
  const [details, setDetails] = useState(null);
  const location = useLocation();
  console.log("test", location);
  const [state, setState] = useState({
    name: details?.name,
    monthlyPrice: details?.monthlyPrice,
    yearlyPrice: details?.yearlyPrice,
    startTimestamp: details?.startTimestamp,
    endTimestamp: details?.endTimestamp,
    type: details?.type,
    description: details?.description,
    shortDescription: "",
  });
  const [error, setError] = useState({
    name: "",
    monthlyPrice: "",
    yearlyPrice: "",
    startTimestamp: "",
    endTimestamp: "",
    type: "",
    description: "",
    shortDescription: "",
  });
  const { subscriptionId } = useParams();

  const [editorContent, setEditorContent] = useState("");
  const handleChange = (content, delta, source, editor) => {
    setEditorContent(editor.getHTML());
    setState({ ...state, description: editor.getHTML() });
    setError({ ...error, description: "" });
  };

  const inputChangeHandler = (e) => {
    let { id, value } = e.target;
    setState({ ...state, [id]: value });
    setError({ ...error, [id]: "" });
  };
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleStartDate = (newDate) => {
    setStartDate(newDate);
    const validDate = moment(newDate).isValid();
    if (validDate) {
      setState({ ...state, startTimestamp: moment(newDate?._d).unix() });
      setError({ ...error, startTimestamp: "" });
    } else {
      setError({ ...error, startTimestamp: "Please Enter Valid Date" });
    }
  };
  const handleEndDate = (newDate) => {
    setEndDate(newDate);
    const validDate = moment(newDate).isValid();
    if (validDate) {
      setState({ ...state, endTimestamp: moment(newDate?._d).unix() });
      setError({ ...error, endTimestamp: "" });
    } else {
      setError({ ...error, endTimestamp: "Please Enter Valid Date" });
    }
  };

  const [subscriptionType, setSubscriptionType] = useState(null);

  const handleSubscriptionType = (e, newValue) => {
    setSubscriptionType(newValue);
    if (newValue) {
      setState({ ...state, type: newValue?.label });
      setError({ ...error, type: "" });
    }
  };

  const [editLoading, setEditLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const addSubscription = () => {
    setLoading(true);
    let body = { ...state };
    if (subscriptionId) {
      body.id = subscriptionId;
    }
    subscriptionController
      .addSubscription(body)
      .then((res) => {
        setToast({
          ...toast,
          message: res.data.message,
          variant: "successs",
          open: true,
        });
        setLoading(false);
        navigate("/subscription-management");
      })
      .catch((err) => {
        let errMessage =
          (err.response && err.response.data.message) || err.message;
        setToast({
          ...toast,
          message: errMessage,
          variant: "error",
          open: true,
        });
        setLoading(false);
      });
  };
  const [toast, setToast] = useState({
    message: "",
    variant: "",
    open: false,
  });

  const addSubsriptionHandler = () => {
    if (AddSubscriptionValidation({ state, error, setError })) {
      addSubscription();
    } else {
    }
  };

  useEffect(() => {
    if (subscriptionId) {
      subscriptionController
        .getSubscriptionDetails(subscriptionId)
        .then((res) => {
          const response = res.data.data;
          if (response) {
            setState({
              ...state,
              name: response?.name,
              monthlyPrice: response?.monthlyPrice,
              yearlyPrice: response?.yearlyPrice,
              description: response?.description,
              startTimestamp: response?.startTimestamp,
              endTimestamp: response?.endTimestamp,
              type: response?.type,
              shortDescription: response?.shortDescription,
            });
            setDetails(response);
            setEditorContent(response?.description);
            setSubscriptionType({
              label: response?.type,
            });
            setStartDate(moment.unix(response?.startTimestamp));
            setEndDate(moment.unix(response?.endTimestamp));
            setEditLoading(false);
          }
        })
        .catch((err) => {
          //   console.log("errr", err);
          let errMessage =
            (err.response && err.response.data.message) || err.message;
          setToast({
            ...toast,
            message: errMessage,
            open: true,
            variant: "error",
          });
        });
    }
  }, [subscriptionId]);

  return (
    <div>
      <Helmet>
        <title>Edit Subscription</title>
      </Helmet>
      <Sidebar />
      <Header />
      <Box className="page-wrapper">
        {editLoading && (
          <Backdrop
            sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
            open={editLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Box sx={{ p: 3 }}>
          <Typography sx={{ fontSize: 20, fontWeight: 550 }}>
            <Loyalty sx={{ mr: 1 }} /> Edit Subscription
          </Typography>

          <Grid container mt={2} spacing={4}>
            <Grid item lg={4}>
              <TextField
                sx={{ ...loginTextField }}
                fullWidth
                label="Subscription Title*"
                id="name"
                onChange={inputChangeHandler}
                error={Boolean(error.name)}
                helperText={error.name}
                value={state.name}
                focused={Boolean(state.name)}
              />
            </Grid>
            <Grid item lg={4}>
              <TextField
                sx={{ ...loginTextField }}
                fullWidth
                label="Monthly Price*"
                id="monthlyPrice"
                onChange={inputChangeHandler}
                error={Boolean(error.monthlyPrice)}
                helperText={error.monthlyPrice}
                type="number"
                value={state.monthlyPrice}
                focused={Boolean(state.monthlyPrice)}
              />
            </Grid>
            <Grid item lg={4}>
              <TextField
                sx={{ ...loginTextField }}
                fullWidth
                label="Annual Price*"
                id="yearlyPrice"
                onChange={inputChangeHandler}
                error={Boolean(error.yearlyPrice)}
                helperText={error.yearlyPrice}
                type="number"
                value={state.yearlyPrice}
                focused={Boolean(state.yearlyPrice)}
              />
            </Grid>
            <Grid item lg={4}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Start Date*"
                  sx={{ ...loginTextField, width: "100%" }}
                  onChange={handleStartDate}
                  value={startDate}
                  slotProps={{
                    textField: {
                      error: Boolean(error.startTimestamp),
                      helperText: error.startTimestamp,
                    },
                  }}
                  disablePast
                  format="DD-MM-YYYY"
                />
              </LocalizationProvider>
            </Grid>
            <Grid item lg={4}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="End Date*"
                  sx={{ ...loginTextField, width: "100%" }}
                  onChange={handleEndDate}
                  value={endDate}
                  slotProps={{
                    textField: {
                      error: Boolean(error.endTimestamp),
                      helperText: error.endTimestamp,
                    },
                  }}
                  minDate={startDate}
                  format="DD-MM-YYYY"
                />
              </LocalizationProvider>
            </Grid>
            <Grid item lg={4}>
              <Autocomplete
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ ...loginTextField }}
                    label="Subscription Type*"
                    error={Boolean(error.type)}
                    helperText={error.type}
                  />
                )}
                sx={{
                  "& .MuiOutlinedInput-root ": {
                    padding: "4px",
                  },
                }}
                options={subscriptionPlanType}
                renderOption={(props, options) => (
                  <Box {...props}>
                    <Typography sx={{ fontSize: 14 }}>
                      {options.label}
                    </Typography>
                  </Box>
                )}
                onChange={handleSubscriptionType}
                value={subscriptionType}
              />
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item lg={12}>
              <TextareaAutosize
                style={{
                  width: "100%",
                  minHeight: 100,
                  backgroundColor: "transparent",
                  borderRadius: "5px",
                  border: error.shortDescription
                    ? "1px solid #ff0000"
                    : "1px solid #d7d7d7",
                  padding: "5px",
                }}
                placeholder="Enter Short Description*"
                onChange={inputChangeHandler}
                id="shortDescription"
                value={state.shortDescription}
              />
              {error.shortDescription && (
                <Typography fontSize={12} clor="#ff0000">
                  {error.description}
                </Typography>
              )}
            </Grid>

            <Grid item lg={12} mt={2}>
              <ReactQuill
                theme="snow"
                onChange={handleChange}
                value={editorContent}
                style={{ border: error.description && "1px solid #ff0000" }}
              />
              {Boolean(error.description) && (
                <Typography fontSize={12} color="#ff0000">
                  {error.description}
                </Typography>
              )}
            </Grid>
            <Button
              sx={{
                mt: 2,
                background: colors.linearGradient,
                color: "#000",
                fontSize: 12,
                p: 1,
                textTransform: "initial",
                width: 150,
              }}
              onClick={addSubsriptionHandler}
              disabled={loading}
            >
              {loading ? (
                <Loading
                  type="bars"
                  width={20}
                  height={20}
                  className="m-auto"
                  color="#000"
                />
              ) : (
                "Add Subscription"
              )}
            </Button>
          </Grid>
          <CustomToast
            open={toast.open}
            message={toast.message}
            variant={toast.variant}
            handleClose={() => handleToastClose({ toast, setToast })}
          />
        </Box>
      </Box>
    </div>
  );
};

export default EditSubscription;
