import React, { useEffect, useState } from "react";
import Sidebar from "../layouts/Sidebar";
import Header from "../layouts/Header";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Loyalty } from "@mui/icons-material";
import { colors, SUBSCRIPTION_PLAN_TYPE, USER_STATUS } from "../utils/enum";
import { useNavigate } from "react-router-dom";
import { subscriptionController } from "../api/subscriptionController";
import { loginTextField, tabSection } from "../utils/styles";
import TabPanel from "../_components/tabPanel";
import SubscriptionTable from "./subscriptionTable";
import CustomToast from "../_components/customToast";
import { handleToastClose } from "../utils/functions";

const tabStatus = [
  {
    label: USER_STATUS.ALL,
  },
  {
    label: USER_STATUS.ACTIVE,
  },
  {
    label: USER_STATUS.InActive,
  },
];

const internshipOptions = [
  {
    label: SUBSCRIPTION_PLAN_TYPE.COURSE,
  },
  {
    label: SUBSCRIPTION_PLAN_TYPE.INTERNSHIP,
  },
];

const SubscriptionManagement = () => {
  const navigate = useNavigate();
  const [subscriptionStatuses, setSubscriptionStatuses] = useState({});
  const handleAddSubscription = () => {
    navigate("/subscription-management/add-subscription");
  };
  const [toast, setToast] = useState({
    message: "",
    variant: "",
    open: false,
  });
  const [tabValue, setTabValue] = useState(0);
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const tabChangeHandler = (e, newValue) => {
    setTabValue(newValue);

    const text = e.target.textContent;
    let body = {
      page: page,
      pageSize: pageSize,
      type: SUBSCRIPTION_PLAN_TYPE.INTERNSHIP,
    };

    setLoading(true);
    if (text === USER_STATUS.ALL) {
      getSubscriptionList(body);
    }
    if (text === USER_STATUS.ACTIVE) {
      body.status = text;
      setSubscriptionStatus(text);
    }
    if (text === USER_STATUS.InActive) {
      body.status = text;
      setSubscriptionStatus(text);
    }
    getSubscriptionList(body);
  };
  const [loading, setLoading] = useState(true);
  const subscriptionsPlansStatusChange = ({ id, status }) => {
    setLoading(true);
    let body = {
      id: id,
      status: status,
    };
    if (tabStatus) {
      // body.status
    }
    subscriptionController
      .changeStatusSubscriptions(body)
      .then((res) => {
        let body = {
          page: page,
          pageSize: pageSize,
          type: SUBSCRIPTION_PLAN_TYPE.INTERNSHIP,
        };
        getSubscriptionList(body);
      })
      .catch((err) => {
        let errMessage =
          (err.response && err.response.data.message) || err.message;
        setToast({
          ...toast,
          open: true,
          message: errMessage,
          variant: "error",
        });
      });
  };

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState(null);
  const getSubscriptionList = (body) => {
    subscriptionController
      .getSubscription(body)
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    let body = {
      page: page,
      pageSize: pageSize,
      type: SUBSCRIPTION_PLAN_TYPE.INTERNSHIP,
    };
    getSubscriptionList(body);
  }, []);

  return (
    <div>
      <Sidebar />
      <Header />
      <Box className="page-wrapper">
        <Box sx={{ p: 3 }}>
          <Card>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              px={2}
            >
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <Loyalty />
                <Typography sx={{ fontSize: 15, fontWeight: 550 }}>
                  Subscriptions Management
                </Typography>
              </Stack>
              <Tabs
                sx={{ ...tabSection }}
                value={tabValue}
                onChange={tabChangeHandler}
              >
                {tabStatus.map((val, i) => (
                  <Tab
                    label={
                      <Typography key={i} fontSize={12}>
                        {val.label}
                      </Typography>
                    }
                  />
                ))}
              </Tabs>
              <Button
                sx={{
                  color: "#000",
                  background: colors.linearGradient,
                  fontSize: 12,
                  textTransform: "initial",
                }}
                startIcon={<Add />}
                onClick={handleAddSubscription}
              >
                Add Subscription
              </Button>
            </Stack>
            <Divider />
            <Grid container p={1} spacing={2}>
              <Grid item lg={4}>
                <Autocomplete
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Subscription Plan Type"
                      sx={{ ...loginTextField }}
                    />
                  )}
                  sx={{
                    "& .MuiOutlinedInput-root ": {
                      padding: "4px",
                    },
                  }}
                  options={internshipOptions}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box {...props}>
                      <Typography fontSize={14}>{option.label}</Typography>
                    </Box>
                  )}
                />
              </Grid>
              <Grid item lg={8}>
                <TextField
                  sx={{ ...loginTextField }}
                  fullWidth
                  label="Search"
                />
              </Grid>
            </Grid>
            {tabStatus.map((val, i) => (
              <TabPanel value={tabValue} index={i}>
                <SubscriptionTable
                  data={data}
                  subscriptionStatuses={subscriptionStatuses}
                  setSubscriptionStatuses={setSubscriptionStatuses}
                  onStatusChange={subscriptionsPlansStatusChange}
                  loading={loading}
                />
              </TabPanel>
            ))}
          </Card>
          <CustomToast
            open={toast.open}
            message={toast.message}
            variant={toast.variant}
            handleClose={() => handleToastClose({ toast, setToast })}
          />
        </Box>
      </Box>
    </div>
  );
};

export default SubscriptionManagement;
