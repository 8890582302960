export default {
    Data: [
        {
          Id: 1,
          Title: "Duis aute irure in reprehenderit in voluptate velit?",
          Category: "19 Dec 2022, 06:12 PM",
          Details: "Ut enim ad minima veniam, quis nostrum exercitationem",
          Action: ""
        },
        {
          Id: 2,
          Title: "Duis aute irure in reprehenderit in voluptate velit?",
          Category: "19 Dec 2022, 06:12 PM",
          Details: "At vero eos et accusamus et iusto odio dignissimos",
          Action: ""
        },
        {
          Id: 3,
          Title: "Excepteur occaecat cupidatat non proident?",
          Category: "19 Dec 2022, 06:12 PM",
          Details: "Nam libero tempore, cum soluta nobis est eligendi optio",
          Action: ""
        },
        {
          Id: 4,
          Title: "Sed ut perspiciatis unde omnis iste natus?",
          Category: "19 Dec 2022, 06:12 PM",
          Details: "Sed ut perspiciatis unde omnis iste natus",
          Action: ""
        },
        {
          Id: 5,
          Title: "Nemo enim ipsam voluptatem quia voluptas sit?",
          Category: "12 Dec 2022, 06:12 PM",
          Details: "Et harum quidem rerum facilis est et expedita distinctio",
          Action: ""
        },
        {
          Id: 6,
          Title: "Neque porro quisquam est, qui dolorem ipsum?",
          Category: "19 Dec 2022, 06:12 PM",
          Details: "Temporibus autem quibusdam et officiis debitis aut rerum",
          Action: ""
        },
        {
          Id: 7,
          Title: "Sed ut perspiciatis unde omnis iste natus?",
          Category: "15 Dec 2022, 06:12 PM",
          Details: "Sed ut perspiciatis unde omnis iste natus",
          Action: ""
        },
        {
          Id: 8,
          Title: "Nemo enim ipsam voluptatem quia voluptas sit?",
          Category: "17 Dec 2022, 06:12 PM",
          Details: "Et harum quidem rerum facilis est et expedita distinctio",
          Action: ""
        },
        {
          Id: 9,
          Title: "Neque porro quisquam est, qui dolorem ipsum?",
          Category: "21 Dec 2022, 06:12 PM",
          Details: "Temporibus autem quibusdam et officiis debitis aut rerum",
          Action: ""
        }
       ]
}