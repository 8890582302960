import { Discount } from "@mui/icons-material";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { discountType, promotionList } from "../utils/defaultArray";
import { colors, USER_STATUS } from "../utils/enum";
import { loginTextField } from "../utils/styles";
import moment from "moment";
import { couponValidation } from "../utils/validation";
import { subscriptionController } from "../api/subscriptionController";
import CustomToast from "../_components/customToast";
import { handleToastClose } from "../utils/functions";
import { useNavigate, useParams } from "react-router-dom";
const EditCoupon = () => {
  const { couponId } = useParams();

  const [state, setState] = useState({
    code: "",
    type: "",
    discountType: "",
    discountValue: "",
    minPurchaseAmount: "",
    maxDiscountAmount: "",
    startTimestamp: "",
    expiryTimestamp: "",
    perUserLimit: "",
    totalUsageLimit: "",
    applicableTo: [],
    status: USER_STATUS.ACTIVE,
    description: "",
  });
  const [error, setError] = useState({
    code: "",
    type: "",
    discountType: "",
    discountValue: "",
    minPurchaseAmount: "",
    maxDiscountAmount: "",
    startTimestamp: "",
    expiryTimestamp: "",
    perUserLimit: "",
    totalUsageLimit: "",
    applicableTo: [],
    status: USER_STATUS.ACTIVE,
    description: "",
  });

  const statusHandler = (e) => {
    setState({
      ...state,
      status: e.target.checked ? USER_STATUS.ACTIVE : USER_STATUS.InActive,
    });
  };

  const navigate = useNavigate();
  const inputChangeHandler = (e) => {
    let { id, value } = e.target;
    setState({ ...state, [id]: value });
    setError({ ...error, [id]: "" });
  };
  const [discountNumber, setDiscountNumber] = useState(null);
  const discountTypeHandler = (e, newValue) => {
    setDiscountNumber(newValue);
    if (newValue) {
      setState({ ...state, discountType: newValue.label });
      setError({ ...error, discountType: "" });
    } else {
      setError({ ...error, discountType: "Please Select Discount type" });
    }
  };

  const [promotionType, setPromotionType] = useState(null);

  const promotionTypeHandler = (e, newValue) => {
    setPromotionType(newValue);
    if (newValue) {
      setState({ ...state, type: newValue.label });
      setError({ ...error, type: "" });
    } else {
      setError({ ...error, type: "Please Select Promotion type" });
    }
  };

  const [startDate, setStartDate] = useState(null);

  const startDateHandler = (newDate) => {
    setStartDate(newDate);
    const validDate = moment(newDate).isValid();
    if (validDate) {
      setState({ ...state, startTimestamp: moment(newDate?._d).unix() });
      setError({ ...error, startTimestamp: "" });
    } else {
      setError({ ...error, startTimestamp: "Please Select Start Date" });
    }
  };

  const [endDate, setEndDate] = useState(null);

  const endDateHandler = (newDate) => {
    setEndDate(newDate);
    const validDate = moment(newDate).isValid();
    if (validDate) {
      setState({ ...state, expiryTimestamp: moment(newDate?._d).unix() });
      setError({ ...error, expiryTimestamp: "" });
    } else {
      setError({ ...error, expiryTimestamp: "Please Enter End Date" });
    }
  };

  // const [subscriptionData, setSubscriptionData] = useState([]);
  // const [subscriptionLoading, setSubscritionLoading] = useState(true);
  // const getSubscriptionPlans = (body) => {
  //   subscriptionController
  //     .getSubscription(body)
  //     .then((res) => {
  //       setSubscriptionData(res.data.data.docs);
  //       setSubscritionLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // };

  // useEffect(() => {
  //   let body = {
  //     page: 1,
  //     pageSize: 100,
  //     status: USER_STATUS.ACTIVE,
  //   };
  //   getSubscriptionPlans(body);
  // }, []);

  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    message: "",
    variant: "",
    open: false,
  });
  const submitHandler = () => {
    if (couponValidation({ error, setError, state })) {
      const filteredState = Object.fromEntries(
        Object.entries(state).filter(([key, value]) => value !== "")
      );

      let body = { ...filteredState };
      if (couponId) {
        body.id = couponId;
      }
      setLoading(true);
      subscriptionController
        .addCoupon(body)
        .then((res) => {
          setToast({
            ...toast,
            message: res.data.message,
            variant: "success",
            open: true,
          });
          setLoading(false);
          navigate("/coupon-management");
        })
        .catch((err) => {
          let errMessage =
            (err.response && err.response.data.message) || err.message;
          setToast({
            ...toast,
            message: errMessage,
            variant: "error",
            open: true,
          });
          setLoading(false);
        });
    } else {
    }
  };

  const [couponDetailsLoading, setCouponLoading] = useState(true);
  const fetchCouponDetails = (id) => {
    subscriptionController
      .getCouponDetails(id)
      .then((res) => {
        const response = res.data.data;
        if (response) {
          setState({
            ...state,
            code: response.code,
            type: response.type,
            discountType: response.discountType,
            discountValue: response.discountValue,
            startTimestamp: response.startTimestamp,
            expiryTimestamp: response.expiryTimestamp,
            perUserLimit: response.perUserLimit,
            totalUsageLimit: response.totalUsageLimit,
            description: response.description,
            status: response.status,
          });
          setPromotionType({ label: response.type });
          setDiscountNumber({ label: response.discountType });
          setStartDate(moment.unix(response.startTimestamp));
          setEndDate(moment.unix(response.expiryTimestamp) || null);
        }
        setCouponLoading(false);
      })
      .catch((err) => {
        // console.log("err", err);
        let errMessage =
          (err.response && err.response.data.message) || err.message;
        setToast({
          ...toast,
          message: errMessage,
          variant: "error",
          open: true,
        });
      });
  };
  useEffect(() => {
    if (couponId) {
      fetchCouponDetails(couponId);
    }
  }, [couponId]);

  return (
    <div>
      <Helmet>
        <title>Edit Coupon</title>
      </Helmet>

      <Sidebar />
      <Header />
      <Box className="page-wrapper">
        <Backdrop
          open={couponDetailsLoading}
          sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        >
          <CircularProgress sx={{ color: "#fff" }} size={30} />
        </Backdrop>

        <Box sx={{ p: 3 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
              <Discount />
              <Typography fontSize={18} fontWeight={550}>
                Edit Coupon
              </Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
              <Typography textTransform={"initial"} fontSize={12}>
                {USER_STATUS.InActive}
              </Typography>
              <Switch
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                    backgroundColor: "#f15d17",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "#f15d17",
                  },
                }}
                checked={state.status === USER_STATUS.ACTIVE ? true : false}
                onChange={statusHandler}
              />
              <Typography textTransform={"initial"} fontSize={12}>
                {USER_STATUS.ACTIVE}
              </Typography>
            </Stack>
          </Stack>
          <Grid container mt={3} spacing={3}>
            <Grid item lg={4}>
              <TextField
                sx={{ ...loginTextField }}
                fullWidth
                label="Coupon Code*"
                id="code"
                onChange={inputChangeHandler}
                error={Boolean(error.code)}
                helperText={error.code}
                value={state.code}
                focused={Boolean(state.code)}
              />
            </Grid>
            <Grid item lg={4}>
              <Autocomplete
                renderInput={(params) => (
                  <TextField
                    label="Promotion Type*"
                    fullWidth
                    sx={{ ...loginTextField }}
                    {...params}
                    error={Boolean(error.type)}
                    helperText={error.type}
                  />
                )}
                options={promotionList}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box {...props}>
                    <Typography sx={{ fontSize: 12 }}>
                      {option.label}
                    </Typography>
                  </Box>
                )}
                sx={{
                  "& .MuiOutlinedInput-root ": {
                    padding: "4px",
                  },
                }}
                onChange={promotionTypeHandler}
                value={promotionType}
              />
            </Grid>
            <Grid item lg={4}>
              <Autocomplete
                renderInput={(params) => (
                  <TextField
                    label="Discount Type*"
                    fullWidth
                    sx={{ ...loginTextField }}
                    {...params}
                    error={Boolean(error.discountType)}
                    helperText={error.discountType}
                  />
                )}
                options={discountType}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box {...props}>
                    <Typography sx={{ fontSize: 12 }}>
                      {option.label}
                    </Typography>
                  </Box>
                )}
                sx={{
                  "& .MuiOutlinedInput-root ": {
                    padding: "4px",
                  },
                }}
                onChange={discountTypeHandler}
                value={discountNumber}
              />
            </Grid>

            <Grid item lg={4}>
              <TextField
                sx={{ ...loginTextField }}
                fullWidth
                label="Discount Amount*"
                onChange={inputChangeHandler}
                id="discountValue"
                error={Boolean(error.discountValue)}
                helperText={error.discountValue}
                value={state.discountValue}
              />
            </Grid>
            {/* <Grid item lg={4}>
              <TextField
                sx={{ ...loginTextField }}
                fullWidth
                label=" Minimum Spend*"
              />
            </Grid>
            <Grid item lg={4}>
              <TextField
                sx={{ ...loginTextField }}
                fullWidth
                label=" Maximum Spend*"
              />
            </Grid> */}
            <Grid item lg={4}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Coupon Start Date*"
                  format="DD-MM-YYYY"
                  sx={{ ...loginTextField, width: "100%" }}
                  disablePast
                  onChange={startDateHandler}
                  value={startDate}
                  slotProps={{
                    textField: {
                      error: Boolean(error.startTimestamp),
                      helperText: error.startTimestamp,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item lg={4}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Coupon End Date"
                  format="DD-MM-YYYY"
                  sx={{ ...loginTextField, width: "100%" }}
                  disablePast
                  onChange={endDateHandler}
                  value={endDate}
                  minDate={startDate}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item lg={4}>
              <TextField
                sx={{ ...loginTextField }}
                fullWidth
                label="Usage limit per user*"
                error={Boolean(error.perUserLimit)}
                helperText={error.perUserLimit}
                id="perUserLimit"
                onChange={inputChangeHandler}
                value={state.perUserLimit}
                focused={Boolean(state.perUserLimit)}
              />
            </Grid>
            <Grid item lg={4}>
              <TextField
                sx={{ ...loginTextField }}
                fullWidth
                label="Usage limit per coupon*"
                error={Boolean(error.totalUsageLimit)}
                helperText={error.totalUsageLimit}
                id="totalUsageLimit"
                onChange={inputChangeHandler}
                value={state.totalUsageLimit}
                focused={Boolean(state.totalUsageLimit)}
              />
            </Grid>
            {/* <Grid item lg={4}>
              <Autocomplete
                renderInput={(params) => (
                  <TextField
                    label="Applicable to*"
                    fullWidth
                    sx={{ ...loginTextField }}
                    {...params}
                  />
                )}
                options={promotionScenariosList}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box {...props}>
                    <Typography sx={{ fontSize: 12 }}>
                      {option.label}
                    </Typography>
                  </Box>
                )}
                sx={{
                  "& .MuiOutlinedInput-root ": {
                    padding: "4px",
                  },
                }}
              />
            </Grid>
            <Grid item lg={4}>
              <Autocomplete
                renderInput={(params) => (
                  <TextField
                    label="Applicable to*"
                    fullWidth
                    sx={{ ...loginTextField }}
                    {...params}
                  />
                )}
                options={subscriptionData}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box {...props}>
                    <Typography sx={{ fontSize: 12 }}>{option.name}</Typography>
                  </Box>
                )}
                loading={subscriptionLoading}
                sx={{
                  "& .MuiOutlinedInput-root ": {
                    padding: "4px",
                  },
                }}
              />

            </Grid> */}
            <Grid item lg={4}>
              <TextField
                sx={{ ...loginTextField }}
                fullWidth
                label="Description*"
                onChange={inputChangeHandler}
                error={Boolean(error.description)}
                helperText={error.description}
                id="description"
                value={state.description}
                focused={Boolean(state.description)}
              />
            </Grid>
            <Grid item lg={12}>
              <Button
                sx={{
                  background: colors.linearGradient,
                  color: "#000",
                  width: 150,
                }}
                onClick={submitHandler}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "#000" }} size={25} />
                ) : (
                  "Edit Coupon"
                )}
              </Button>
            </Grid>
          </Grid>
          <CustomToast
            open={toast.open}
            message={toast.message}
            variant={toast.variant}
            handleClose={() => handleToastClose({ toast, setToast })}
          />
        </Box>
      </Box>
    </div>
  );
};

export default EditCoupon;
