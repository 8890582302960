import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InvoiceLogo1, Logo } from "../_components/imagepath";

import Image from "../../src/assets/img/wave.svg";
import Loading from "../_components/Loading";
import Input from "../_components/input";
import CustomButton from "../_components/buttons/customButton";
import Authcontrollers from "../api/authController";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    oldPassword: "",
    newPassword: "",
    email: "",
  });
  const inputHandler = (e) => {
    let { id, value } = e.target;
    setState({ ...state, [id]: value });
  };

  const changeUserPassword = () => {
    let body = {
      oldPassword: state.oldPassword,
      newPassword: state.newPassword,
      email: state.email,
    };
    Authcontrollers.changePassword(body)
      .then((res) => {
        localStorage.setItem("accesstoken", res.data.data.accessToken);
        toast.success(res.data.data.message);
        navigate("/dashboard");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    changeUserPassword();
  };
  return (
    <>
      <div className="main-wrapper login-body">
        <div className="login-wrapper">
          <div className="container ">
            <div className="loginbox ">
              <div className="login_bg">
                <div className="login-right m-auto">
                  <div className="login-right-wrap">
                    <img
                      className="img-fluid logo-dark "
                      width="150px"
                      src={Logo}
                      alt="Logo"
                    />
                    <div>
                      {/* <form onSubmit={onSubmit}>
                        <div className="form-group input_text">
                          <label className="form-control-label">
                            Email Address
                          </label>
                          <Controller
                            name="email"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <input
                                className={`form-control  ${
                                  errors?.email ? "error-input" : " "
                                }`}
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="false"
                              />
                            )}
                          />
                          <small>{errors?.email?.message}</small>
                        </div>
                        <div className="form-group input_text login-eye">
                          <label className="form-control-label">Password</label>
                          <Controller
                            name="password"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <div className="pass-group">
                                <input
                                  type={eye ? "password" : "text"}
                                  className={`form-control  ${
                                    errors?.password ? "error-input" : ""
                                  }`}
                                  value={Password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  autoComplete="false"
                                />
                                <span
                                  onClick={onEyeClick}
                                  className={`fa toggle-password" ${
                                    eye ? "fa-eye-slash" : "fa-eye"
                                  }`}
                                />
                              </div>
                            )}
                          />
                          <small>{errors?.password?.message}</small>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-6">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="cb1"
                                />
                                <label
                                  className="custom-control-label ms-1"
                                  htmlFor="cb1"
                                >
                                  Remember me
                                </label>
                              </div>
                            </div>
                            <div className="col-6 text-end">
                              <Link
                                className="forgot-link"
                                to="/forgot-password"
                                //  to="/forgot-password"
                              >
                                {" "}
                                Forgot Password ?{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                        <p className="text-danger my-2">{error}</p>
                        <button
                          className="btn btn-lg btn-block w-100 login-bttn w-100"
                          type="submit"
                        >
                          {loading ? (
                            <Loading
                              type={"bars"}
                              width={25}
                              height={25}
                              className="m-auto"
                            />
                          ) : (
                            "Login"
                          )}
                        </button>
                      </form> */}
                      <form onSubmit={submitHandler}>
                        <Input
                          type="password"
                          placeholder="Old Password"
                          width="100%"
                          rounded="8px"
                          padding="8px"
                          border="none"
                          id="oldPassword"
                          onChange={inputHandler}
                          //   className="mb-2"
                        />
                        <div className="my-3">
                          <Input
                            type="password"
                            placeholder="New Password"
                            width="100%"
                            rounded="8px"
                            padding="8px"
                            border="none"
                            id="newPassword"
                            onChange={inputHandler}
                          />
                        </div>
                        <Input
                          type="email"
                          placeholder="Email"
                          width="100%"
                          rounded="8px"
                          padding="8px"
                          border="1px solid #d7d7d7"
                          id="email"
                          onChange={inputHandler}
                        />
                        <div className="text-center">
                          <CustomButton
                            className="btn btn-primary mt-3"
                            type="submit"
                            width={150}
                          >
                            Submit{" "}
                          </CustomButton>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* <img src={Image} className="wave" alt="" /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
