import React, { useCallback, useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import "./index.css";
import { DownloadForOffline, People } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { debounce } from "lodash";
import moment from "moment";
import Loading from "react-loading";
import { useNavigate } from "react-router-dom";
import userController from "../../api/users";
import student from "../../assets/img/icons/student.png";
import { colors, USER_GROUP, USER_STATUS } from "../../utils/enum";
import { handleToastClose } from "../../utils/functions";
import { loginTextField } from "../../utils/styles";
import CustomToast from "../customToast";
import TabPanel from "../tabPanel";
const tabStatus = [
  {
    label: USER_STATUS.ALL,
  },
  {
    label: USER_STATUS.ACTIVE,
  },
  {
    label: USER_STATUS.InActive,
  },
];

const Getstudent = () => {
  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [list, setList] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);

  const student_list = (value) => {
    userController
      .getUsers(value)
      .then((res) => {
        setList(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //

  const navigate = useNavigate();
  const Navigation = () => {
    navigate("/profilepage", { state: list });
  };
  //

  const [csvLoading, setCsvLoading] = useState(false);
  const exportCsv = (user) => {
    const a = document.createElement("a");
    setCsvLoading(true);
    userController
      .csvDownload(user)
      .then((res) => {
        const blob = new Blob([res.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `${user}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

        document.body.removeChild(a);
        setCsvLoading(false);
      })
      .catch((err) => {
        let errMessage =
          (err.response && err.response.data.message) || err.response;
        setToast({
          ...toast,
          message: errMessage,
          variant: "error",
          open: true,
        });
        setCsvLoading(false);
      });
  };
  const changeStatusHandler = (e, item) => {
    console.log("e", e);
    console.log("item", item);
  };

  const [tabValue, setTabValue] = useState(0);
  const [toast, setToast] = useState({
    open: false,
    message: "",
    variant: "",
  });
  const tabChangeHandler = (e, newValue) => {
    setTabValue(newValue);
  };

  const tableHead = [
    {
      name: "Name",
      sort: true,
    },

    {
      name: "Phone No",
    },
    {
      name: "Registered On",
      sort: true,
    },
    {
      name: "Last Login",
    },
    {
      name: "Status",
    },
    {
      name: "Actions",
    },
  ];

  const [sortBy, setSortBy] = useState({ field: "", order: "asc" });
  const [search, setSearch] = useState("");
  const debouncedGetStudentList = useCallback(
    debounce((body) => {
      student_list(body);
    }, 300),
    []
  );

  const searchHandler = (e) => {
    setSearch(e.target.value);
    setLoading(true);

    let body = {
      group: USER_GROUP.STUDENT,
      page: page,
      pageSize: pageSize,
    };

    if (sortBy.field) {
      body.sortBy = { [sortBy.field]: sortBy.order.toUpperCase() };
    }
    if (e.target.value) {
      body.search = e.target.value;
    }

    debouncedGetStudentList(body);
  };

  const pageChangeHandler = (e, newPage) => {
    setLoading(true);
    setPage(newPage);
    let body = {
      page: newPage + 1,
      pageSize: pageSize,
      group: USER_GROUP.STUDENT,
    };
    if (search) {
      body.search = search;
    }
    if (sortBy.field) {
      sortBy.order = sortBy.order.toUpperCase();
    }

    if (sortBy.order.toUpperCase()) {
      if (sortBy.field) {
        body.sortBy = { [sortBy.field]: sortBy.order.toUpperCase() };
      }
    }
    student_list(body);
  };

  const pageSizeHandler = (e) => {
    setPageSize(e.target.value);
    setLoading(true);
    let body = {
      page: page,
      pageSize: e.target.value,
      group: USER_GROUP.STUDENT,
    };
    if (search) {
      body.search = search;
    }
    if (sortBy.field) {
      body.sortBy = { [sortBy.field]: sortBy.order.toUpperCase() };
    }
    student_list(body);
  };

  const sortingHandler = (e, name) => {
    let newOrder = "asc";
    setLoading(true);

    if (sortBy.field === name) {
      newOrder = sortBy.order === "asc" ? "desc" : "asc";
    }

    setSortBy({ field: name, order: newOrder });

    const body = {
      group: USER_GROUP.STUDENT,
      page: page,
      pageSize: pageSize,
      sortBy: { [name]: newOrder.toUpperCase() },
    };

    if (search) {
      body.search = search;
    }

    student_list(body);
  };
  const submitHandler = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    let body = {
      group: USER_GROUP.STUDENT,
      page: page,
      pageSize: pageSize,
    };
    student_list(body);
  }, []);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header
        onMenuClick={(value) => toggleMobileMenu()}
        onSearch={searchHandler}
        onSubmit={submitHandler}
      />
      <Sidebar />

      <div className="page-wrapper">
        <Box sx={{ p: 2 }}>
          <Card sx={{ boxShadow: "0px 0px 1px 1px #d7d7d7" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
              px={2}
            >
              <Stack direction={"row"} spacing={2} alignItems="center">
                <People />
                <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
                  Registered Students
                </Typography>
              </Stack>
              <Tabs
                sx={{
                  "& .MuiTab-root": {
                    ":hover": {
                      color: "#f15d17",
                      backgroundColor: "transparent",
                    },
                  },
                  "& .Mui-selected": {
                    color: `${colors.primary} !important`,
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: `${colors.primary} !important`,
                  },
                }}
                onChange={tabChangeHandler}
                value={tabValue}
              >
                {tabStatus.map((val, i) => (
                  <Tab
                    label={<Typography fontSize={13}>{val.label}</Typography>}
                    key={i}
                  />
                ))}
              </Tabs>

              <Button
                startIcon={
                  csvLoading ? (
                    <CircularProgress size={20} sx={{ color: "#000" }} />
                  ) : (
                    <DownloadForOffline />
                  )
                }
                sx={{
                  background: colors.linearGradient,
                  color: "#000",
                  fontSize: 12,
                  width: 150,
                  p: 1,
                }}
                onClick={() => exportCsv(USER_GROUP.STUDENT)}
                disabled={csvLoading}
              >
                {csvLoading ? "Exporting..." : " Export CSV"}
              </Button>
            </Stack>
            <Divider />
            <Box sx={{ p: 1, mt: 2 }}>
              <Grid container spacing={4}>
                <Grid item lg={12}>
                  <TextField
                    sx={{ ...loginTextField }}
                    label="Search"
                    fullWidth
                    onChange={searchHandler}
                  />
                </Grid>
              </Grid>
            </Box>
            {tabStatus.map((val, i) => (
              <TabPanel index={i} value={tabValue}>
                <TableContainer sx={{ p: 1 }}>
                  <Table>
                    <TableHead sx={{ backgroundColor: "#d7d7d7" }}>
                      <TableRow>
                        {tableHead.map((val, i) =>
                          val.sort ? (
                            <TableSortLabel
                              key={i}
                              active={sortBy.field === val.name}
                              direction={
                                sortBy.field === val.name
                                  ? sortBy.order.toLowerCase()
                                  : "asc"
                              }
                              onClick={(e) => sortingHandler(e, val.name)}
                              sx={{
                                padding: "12px",
                                "& .MuiTableSortLabel-icon": {
                                  fill: "#000",
                                  opacity: 1,
                                },
                              }}
                            >
                              <Typography fontSize={14} fontWeight={550}>
                                {val.name}
                              </Typography>
                            </TableSortLabel>
                          ) : (
                            <TableCell key={i} sx={{ padding: "12px" }}>
                              <Typography fontSize={14} fontWeight={550}>
                                {val.name}
                              </Typography>
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    </TableHead>
                    {loading ? (
                      <Box textAlign={"center"}>
                        <Loading
                          type="bars"
                          color="#000"
                          width={20}
                          height={20}
                          className="m-auto"
                        />
                      </Box>
                    ) : list?.docs.length === 0 ? (
                      <Typography fontSize={14} textAlign={"center"}>
                        No data Found
                      </Typography>
                    ) : (
                      <TableBody>
                        {list?.docs.map((item, index) => (
                          <TableRow>
                            <TableCell sx={{ padding: "12px", width: "20%" }}>
                              <Stack
                                direction={"row"}
                                alignItems="center"
                                spacing={1}
                              >
                                <IconButton>
                                  <Avatar>
                                    <img
                                      src={item?.avatar || student}
                                      alt="avatar"
                                    />
                                  </Avatar>
                                </IconButton>
                                <Box>
                                  <Typography
                                    fontSize={14}
                                    sx={{
                                      cursor: "pointer",
                                      ":hover": {
                                        color: "#f15d17",
                                        textDecoration: "underline",
                                      },
                                      textDecoration: "underline",
                                      textTransform: "capitalize",
                                      fontWeight: 550,
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography fontSize={12}>
                                    {item.email}
                                  </Typography>
                                </Box>
                              </Stack>
                            </TableCell>
                            <TableCell sx={{ padding: "12px" }}>
                              <Typography fontSize={14}>
                                {item.phoneNo || "--"}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ padding: "12px" }}>
                              <Typography fontSize={14}>
                                {moment(item.updatedAt).format("DD-MM-YYYY")}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ padding: "12px" }}>
                              <Typography fontSize={14}>
                                {moment(item.lastLogin).format("DD-MM-YYYY")}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ padding: "12px" }}>
                              <Box
                                sx={{
                                  backgroundColor:
                                    item.status === USER_STATUS.ACTIVE
                                      ? "#00800049"
                                      : "#ff000049",
                                  color:
                                    item.status === USER_STATUS.ACTIVE
                                      ? "#008000"
                                      : "#ff0000",
                                  fontSize: 12,
                                  width: 70,
                                  p: 1,
                                  borderRadius: 4,
                                  textAlign: "center",
                                }}
                              >
                                {item.status}
                              </Box>
                            </TableCell>
                            <TableCell sx={{ padding: "12px" }}>
                              <Switch
                                checked={item.status === USER_STATUS.ACTIVE}
                                sx={{
                                  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                                    {
                                      backgroundColor: "#008000",
                                    },
                                  "& .MuiSwitch-switchBase.Mui-checked": {
                                    color: "#008000",
                                  },
                                }}
                                onChange={(e) => changeStatusHandler(e, item)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </TabPanel>
            ))}
            <TablePagination
              component={"div"}
              rowsPerPage={pageSize}
              page={page}
              count={list?.totalDocs}
              onPageChange={pageChangeHandler}
              onRowsPerPageChange={pageSizeHandler}
            />
          </Card>
        </Box>
      </div>
      <CustomToast
        message={toast.message}
        variant={toast.variant}
        open={toast.open}
        handleClose={() => handleToastClose({ toast, setToast })}
      />
    </div>
  );
};
export default Getstudent;
