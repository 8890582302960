import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import CustomButton from "../_components/buttons/customButton";
import Authcontrollers from "../api/authController";
import { USER_GROUP } from "../utils/enum";
import userController from "../api/users";
import { Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Loading from "../_components/Loading";
import { getActionsbyModuleName } from "../utils/functions";

const StaffManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tableHeader = [
    { name: "Name" },
    { name: "Email" },
    { name: "Role" },
    { name: "Last Login" },
    { name: "Registered on" },
  ];

  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const [loading, setLoading] = useState(true);
  const [staffList, setStaffList] = useState([]);
  const [actions, setActions] = useState({});

  // Fetching staff members
  const getStaff = () => {
    const body = {
      page: 1,
      pageSize: 20,
      group: USER_GROUP.VROAR_STAFF,
    };

    userController
      .getUsers(body)
      .then((res) => {
        setStaffList(res.data.data.docs);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };
  const fetchActions = () => {
    const moduleActions = getActionsbyModuleName("Staff Management");
    setActions(moduleActions);
  };

  useEffect(() => {
    getStaff();
    fetchActions();
  }, []);

  return (
    <div>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={toggleMobileMenu} />
        <Sidebar PathName={location.pathname} />
        <div className="page-wrapper">
          <div className="d-flex align-items-center justify-content-between p-2">
            <div className="d-flex align-items-center">
              <div
                className="square-box"
                style={{ width: "20px", height: "20px", marginLeft: "15px" }}
              ></div>
              <h5>VROAR Staff</h5>
            </div>

            {/* Conditional rendering for the Add button */}
            {actions.ADD && (
              <CustomButton
                className="btn btn-primary"
                mr="15px"
                onClick={() => navigate("/add-user")}
              >
                Add Staff +
              </CustomButton>
            )}
          </div>

          {loading ? (
            <Loading
              type="bars"
              color="#f15d17"
              width={25}
              height={25}
              className="m-auto"
            />
          ) : (
            <div className="table-container">
              {staffList.length ? (
                <Table bgcolor="#ffffff" hover bordered>
                  <thead style={{ backgroundColor: "#eee" }}>
                    <tr>
                      {tableHeader.map((val, i) => (
                        <th key={i}>{val.name}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {staffList.map((val, i) => (
                      <tr key={i}>
                        <td className="text-capitalize">{val.name}</td>
                        <td>{val.email}</td>
                        <td>{val.roleName}</td>
                        <td>{moment(val.lastLogin).format("MMM-DD-YYYY")}</td>
                        <td>{moment(val.createdAt).format("MMM-DD-YYYY")}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p className="text-center">No Staff Member Found</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StaffManagement;
