import {
  AttachMoneyOutlined,
  CalendarMonthOutlined,
  ExpandMore,
  LocationOnOutlined,
  PlayCircleOutline,
  Upload,
  WorkHistoryOutlined,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Edit } from "react-feather";
import { Helmet } from "react-helmet";
import Loading from "react-loading";
import { useNavigate, useParams } from "react-router-dom";
import { internshipController } from "../api/internship";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { colors, fontFamily, INTERNSHIP_STATUS } from "../utils/enum";

const InternshipDetail = () => {
  const { internshipId } = useParams();
  const navigate = useNavigate();
  //   console.log("id", internshipId);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const getInternshipDetails = (id) => {
    internshipController
      .getInternshipDetail(id)
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const startDate = moment.unix(data?.duration?.from);
  const endDate = moment.unix(data?.duration?.to);
  const internshipInfo = [
    {
      icon: <PlayCircleOutline />,
      label: "Start Date",
      value: moment.unix(data?.duration?.from).format("DD-MM-YYYY"),
    },
    {
      icon: <LocationOnOutlined />,
      label: "Work Mode",
      value: data?.workMode,
    },
    {
      icon: <CalendarMonthOutlined />,
      label: "Duration",
      value: `${endDate.diff(startDate, "weeks")} weeks`,
    },
    {
      icon: <AttachMoneyOutlined />,
      label: "Stipend",
      value: data?.stipend?.amount
        ? data?.stipend?.rate
          ? `${data?.stipend.amount} ${data?.stipend.rate}`
          : `${data?.stipend.amount}`
        : "Not Disclosed",
    },
    {
      icon: <WorkHistoryOutlined />,
      label: "Experience",
      value: data?.experience
        ? data?.experience === "0"
          ? "Fresher"
          : `${data.experience} years`
        : "Not Disclosed",
    },
  ];
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const editInternship = () => {
    navigate("/internship-management/edit-internship", {
      state: data,
    });
  };

  const [toast, setToast] = useState({
    open: false,
    message: "",
    variant: "",
  });
  const [publishLoading, setPublishLoading] = useState(false);
  const publishInternship = () => {
    let body = {
      status: INTERNSHIP_STATUS.PUBLISHED,
      _id: data?._id,
    };
    setPublishLoading(true);
    internshipController
      .editJobs(body)
      .then((res) => {
        setToast({
          ...toast,
          open: true,
          message: res.data.message,
          variant: "success",
        });
        setPublishLoading(false);
        navigate("/internship-management");
      })
      .catch((err) => {
        let errMessage =
          (err.response && err.response.data.message) || err.message;

        setToast({
          ...toast,
          open: true,
          message: errMessage,
          variant: "error",
        });
        setPublishLoading(false);
      });
  };

  useEffect(() => {
    if (internshipId) {
      getInternshipDetails(internshipId);
    }
  }, [internshipId]);
  return (
    <div>
      <Helmet>
        <title>Internship Detail</title>
      </Helmet>
      <Sidebar />
      <Header />
      <Box className="page-wrapper">
        <Box sx={{ p: 5 }}>
          {loading ? (
            <Loading
              type="bars"
              width={20}
              height={20}
              className="m-auto"
              color="#000"
            />
          ) : (
            <Box>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box>
                  <Avatar sx>
                    <img src={data?.company?.logo} alt="" />
                  </Avatar>
                  <Typography
                    fontSize={30}
                    fontFamily={fontFamily.roboto}
                    textTransform={"capitalize"}
                    fontWeight={600}
                  >
                    {data?.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontFamily: fontFamily.plus,
                      fontWeight: 550,
                    }}
                  >
                    {data?.company?.companyName}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 12, fontFamily: fontFamily.plus }}
                  >
                    {data?.company?.companyType}
                  </Typography>
                </Box>
                {/* <IconButton onClick={editInternship}>
                  <Edit />
                </IconButton> */}
              </Stack>

              <Grid container mt={2}>
                {internshipInfo.map((val, i) => (
                  <>
                    <Grid item lg={2} key={i} p={2}>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        {val.icon}
                        <Typography
                          fontSize={14}
                          fontFamily={fontFamily.plus}
                          fontWeight={500}
                        >
                          {val.label}
                        </Typography>
                      </Stack>
                      <Typography
                        fontSize={13}
                        fontFamily={fontFamily.plus}
                        mt={0.5}
                        ml={4}
                      >
                        {val.value}
                      </Typography>
                    </Grid>
                    {i !== internshipInfo.length - 1 && (
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ bordercolor: "#000" }}
                      />
                    )}
                  </>
                ))}
              </Grid>
              <Divider sx={{ borderColor: "#000", mt: 3 }} />
              <Box mt={2}>
                <div
                  className="custom_description"
                  dangerouslySetInnerHTML={{ __html: data?.description }}
                ></div>
              </Box>
              <Divider sx={{ borderColor: "#000", mt: 3 }} />
              <Typography
                mt={2}
                fontFamily={fontFamily.roboto}
                fontSize={20}
                fontWeight={600}
              >
                Learnings :{" "}
              </Typography>
              <Typography fontSize={18} fontFamily={fontFamily.roboto} mt={2}>
                {data?.curriculum?.title}
              </Typography>
              {data?.curriculum?.sessions.map((val, i) => (
                <Accordion
                  sx={{
                    mt: 2,

                    backgroundColor: "transparent",
                  }}
                  key={i}
                  expanded={expanded === `panel${i}`}
                  onChange={handleChange(`panel${i}`)}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography
                      fontSize={17}
                      fontFamily={fontFamily.roboto}
                      fontWeight={550}
                    >
                      {i + 1}. {val.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ ml: 4 }}>
                    <div
                      className="custom_description"
                      dangerouslySetInnerHTML={{ __html: val.description }}
                    ></div>
                  </AccordionDetails>
                </Accordion>
              ))}
              {data?.status === INTERNSHIP_STATUS.AWAITING && (
                <Stack direction={"row"} alignItems="center" spacing={2} mt={2}>
                  <Button
                    sx={{
                      background: colors.linearGradient,
                      color: "#fff",
                      ":hover": {
                        color: colors.primary,
                        background: "transparent",
                      },
                      border: "1px solid #f15d17",
                      fontSize: 14,
                      fontFamily: fontFamily.roboto,
                      width: 150,
                    }}
                    startIcon={<Edit size={15} />}
                    onClick={editInternship}
                  >
                    Edit
                  </Button>
                  <Button
                    sx={{
                      background: "transparent",
                      color: colors.primary,
                      ":hover": {
                        color: "#fff",
                        background: colors.linearGradient,
                      },
                      border: "1px solid #f15d17",
                      fontSize: 14,
                      fontFamily: fontFamily.roboto,
                      width: 150,
                    }}
                    startIcon={
                      publishLoading ? (
                        <CircularProgress size={20} sx={{ color: "#000" }} />
                      ) : (
                        <Upload />
                      )
                    }
                    disabled={publishLoading}
                    onClick={publishInternship}
                  >
                    {publishLoading ? "Publishing..." : "Publish"}
                  </Button>
                </Stack>
              )}
            </Box>
          )}
        </Box>

        <Snackbar
          open={toast.open}
          autoHideDuration={2000}
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
        >
          <Alert
            onClose={() => setToast({ ...toast, open: false })}
            variant="filled"
            severity={toast.variant}
          >
            {toast.message}
          </Alert>
        </Snackbar>
      </Box>
    </div>
  );
};

export default InternshipDetail;
