import {
  Box,
  Card,
  Divider,
  FormControlLabel,
  Pagination,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Loading from "react-loading";
import { colors, fontFamily } from "../utils/enum";
import { loginTextField } from "../utils/styles";

const CurriculumListing = ({
  data,
  onSelect,
  curriculumValue,
  onSearch,
  loading,
  page,
  onPageChange,
}) => {
  return (
    <div>
      <Card sx={{ p: 2, backgroundColor: "transparent" }}>
        <Typography fontSize={20} fontFamily={fontFamily.roboto}>
          Curriculum Listing
        </Typography>
        <Divider sx={{ borderColor: "#000", mt: 2 }} />
        <TextField
          label="Search"
          sx={{
            ...loginTextField,
            "& .MuiOutlinedInput-input": {
              padding: "12px",
            },
            "& label": {
              fontSize: 12,
            },
            mt: 2,
          }}
          fullWidth
            onChange={onSearch}
        />

        {loading ? (
          //   <Skeleton count={11} enableAnimation height={200} />
          <Loading
            type="bars"
            width={20}
            height={20}
            color="#000"
            className="m-auto"
          />
        ) : data?.docs.length === 0 ? (
          <Typography fontSize={13} fontFamily={fontFamily.roboto}>
            No Curriculum Found
          </Typography>
        ) : (
          data?.docs.map((val, i) => (
            <Box sx={{ my: 2 }} key={i}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={curriculumValue}
                  onChange={onSelect}
                >
                  <FormControlLabel
                    value={val._id}
                    control={
                      <Radio
                        sx={{
                          "&.MuiRadio-root.Mui-checked": {
                            color: colors.primary,
                          },
                        }}
                      />
                    }
                  />
                </RadioGroup>
                <Box>
                  <Typography
                    sx={{
                      fontSize: 14,
                      textTransform: "capitalize",
                      fontFamily: fontFamily.roboto,
                    }}
                  >
                    {val.title}
                  </Typography>
                  <Typography fontSize={12} fontFamily={fontFamily.plus}>
                    {val.sessions.length} sessions
                  </Typography>
                </Box>
              </Stack>
            </Box>
          ))
        )}

        <Pagination
          count={data?.totalPages}
          page={page}
          onChange={onPageChange}
          siblingCount={0}
          boundaryCount={2}
        />
      </Card>
    </div>
  );
};

export default CurriculumListing;
