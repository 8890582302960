import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InvoiceLogo1, Logo } from "../_components/imagepath";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Image from "../../src/assets/img/wave.svg";
import * as yup from "yup";
import { alphaNumericPattern, emailrgx } from "../assets/constant";
import Authcontrollers from "../api/authController";
import { toast } from "react-toastify";
import Loading from "../_components/Loading";

const schema = yup.object({
  email: yup
    .string()
    .matches(emailrgx, "Email is required")
    .required("Email is required")
    .trim(),
  password: yup.string().min(6).max(9).required("Password is required").trim(),
});

const Login = (props) => {
  let navigate = useNavigate();
  const [eye, seteye] = useState(true);
  const [email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  // const [emailerror, setEmailError] = useState('')
  // const [nameerror, setNameError] = useState('')
  // const [passworderror, setPasswordError] = useState('')
  // const [formgroup, setFormGroup] = useState('')
  // const [inputValues, setInputValues] = useState({
  //   email: '',
  //   password: '',
  // })
  // let { email, password } = inputValues
  // let validation = () => {
  //   if (email === '') {
  //     return false
  //   } else if (password === '') {
  //     return false
  //   } else {
  //     return true
  //   }
  // }

  // const submitHandler = (e) => {}
  // let process = { email, Password }
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [error, setformErr] = useState("");
  const [loading, setLoading] = useState(false);
  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    let body = {
      email: email,
      password: Password,
    };

    Authcontrollers.login(body)
      .then((res) => {
        // console.log(res);
        setLoading(false);
        toast.success(res.data.message);
        if (res.data.statusCode === 1063) {
          navigate("/change-password");
        } else {
          navigate("/dashboard");
          localStorage.setItem("accesstoken", res.data.data.accessToken);
        }
      })
      .catch((err) => {
        // console.log(err);
        let errMessage =
          (err.response.data && err.response.data.message) ||
          setformErr(errMessage);
        setLoading(false);
        toast.error(err.response.data.message);
      });

    // if (data.password != 'Pass@1234') {
    //   setError('password', {
    //     message: 'password is mismatch',
    //   })
    // } else {
    //   clearErrors('password')
    //   props.history.push('index')
    // }
  };

  const onEyeClick = () => {
    seteye(!eye);
  };
  function login() {
    console.warn("data", email, Password);
  }

  return (
    <>
      <div className="main-wrapper login-body">
        <div className="login-wrapper">
          <div className="container ">
            <div className="loginbox ">
              <div className="login_bg">
                <div className="login-right m-auto">
                  <div className="login-right-wrap">
                    <img
                      className="img-fluid logo-dark "
                      width="150px"
                      src={Logo}
                      alt="Logo"
                    />
                    <div>
                      <form onSubmit={onSubmit}>
                        <div className="form-group input_text">
                          <label className="form-control-label">
                            Email Address
                          </label>
                          <Controller
                            name="email"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <input
                                className={`form-control  ${
                                  errors?.email ? "error-input" : " "
                                }`}
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="false"
                              />
                            )}
                          />
                          <small>{errors?.email?.message}</small>
                        </div>
                        <div className="form-group input_text login-eye">
                          <label className="form-control-label">Password</label>
                          <Controller
                            name="password"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <div className="pass-group">
                                <input
                                  type={eye ? "password" : "text"}
                                  className={`form-control  ${
                                    errors?.password ? "error-input" : ""
                                  }`}
                                  value={Password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  autoComplete="false"
                                />
                                <span
                                  onClick={onEyeClick}
                                  className={`fa toggle-password" ${
                                    eye ? "fa-eye-slash" : "fa-eye"
                                  }`}
                                />
                              </div>
                            )}
                          />
                          <small>{errors?.password?.message}</small>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-6">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="cb1"
                                />
                                <label
                                  className="custom-control-label ms-1"
                                  htmlFor="cb1"
                                >
                                  Remember me
                                </label>
                              </div>
                            </div>
                            <div className="col-6 text-end">
                              <Link
                                className="forgot-link"
                                to="/forgot-password"
                                //  to="/forgot-password"
                              >
                                {" "}
                                Forgot Password ?{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                        <p className="text-danger my-2">{error}</p>
                        <button
                          className="btn btn-lg btn-block w-100 login-bttn w-100"
                          type="submit"
                        >
                          {loading ? (
                            <Loading
                              type={"bars"}
                              width={25}
                              height={25}
                              className="m-auto"
                            />
                          ) : (
                            "Login"
                          )}
                        </button>
                      </form>

                      {/* <div className="login-or">
                  <span className="or-line" />
                  <span className="span-or">or</span>
                </div> */}
                      {/* Social Login */}
                      {/* <div className="social-login mb-3">
                  <span>Login with</span>
                  <Link  to="#" className="facebook">
                    <i className="fab fa-facebook-f" />
                  </Link>
                  <Link  to="#" className="google">
                    <i className="fab fa-google" />
                  </Link>
                </div> */}
                      {/* /Social Login */}
                      {/* <div className="text-center dont-have">
                  Don't have an account yet?{" "}
                  <Link to="/register">Register</Link>
                </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <img src={Image} className="wave mt-5" alt="" /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
