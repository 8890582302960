import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Sidebar from "../layouts/Sidebar";
import Header from "../layouts/Header";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { fontFamily } from "../utils/enum";
import { List } from "react-feather";
import { internshipController } from "../api/internship";
import { loginTextField } from "../utils/styles";
import { Search } from "@mui/icons-material";
import { waitlistTableHeader } from "../utils/defaultArray";
import moment from "moment";
import { debounce } from "lodash";
import Loading from "react-loading";

const Waitlist = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const getList = (body) => {
    internshipController
      .getWaitlist(body)
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(true);
      });
  };
  const debouncedSearch = useCallback(
    debounce((body) => {
      getList(body);
    }, 300),
    []
  );

  const pageSizeHandler = (e) => {
    setPageSize(e.target.value);
    setLoading(true);
    let body = {
      page: page,
    };
    if (search) {
      body.search = search;
    }
    if (e.target.value) {
      body.pageSize = e.target.value;
    }

    getList(body);
  };

  const pageChangeHandler = (e, newPage) => {
    setPage(newPage);
    let body = {
      pageSize: pageSize,
    };

    // console.log("pppp", newPage);
    setLoading(true);

    if (newPage) {
      body.page = newPage + 1;
    }
    if (search) {
      body.search = search;
    }
    getList(body);
  };

  const searchHandler = (e) => {
    setSearch(e.target.value);
    setLoading(true);
    let body = {
      page: page,
      pageSize: pageSize,
    };
    if (e.target.value) {
      body.search = e.target.value;
    }

    debouncedSearch(body);
  };

  useEffect(() => {
    let body = {
      page: page,
      pageSize: pageSize,
    };
    getList(body);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Waitlist</title>
      </Helmet>
      <Sidebar />
      <Header />
      <Box className="page-wrapper">
        <Box sx={{ p: 4 }}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <List />
            <Typography
              fontSize={20}
              fontWeight={600}
              fontFamily={fontFamily.roboto}
            >
              Waitlist
            </Typography>
          </Stack>
          <TextField
            label="Search"
            sx={{ ...loginTextField, width: 350, mt: 2 }}
            InputProps={{
              startAdornment: <Search />,
            }}
            onChange={searchHandler}
          />

          <TableContainer sx={{ mt: 2 }}>
            <Table>
              <TableHead sx={{ backgroundColor: "#d7d7d7" }}>
                <TableRow>
                  {waitlistTableHeader.map((val, i) => (
                    <TableCell key={i}>
                      <Typography
                        fontSize={14}
                        fontWeight={550}
                        fontFamily={fontFamily.roboto}
                      >
                        {val.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <Loading
                        type="bars"
                        color="#000"
                        width={20}
                        height={20}
                        className="m-auto"
                      />
                    </TableCell>
                  </TableRow>
                ) : data?.docs?.length ? (
                  data?.docs.map((val, i) => (
                    <TableRow key={i} hover>
                      <TableCell>
                        <Typography
                          fontSize={13}
                          fontFamily={fontFamily.plus}
                          fontWeight={500}
                        >
                          {val.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          fontSize={13}
                          fontFamily={fontFamily.plus}
                          fontWeight={500}
                        >
                          {val.email}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          fontSize={13}
                          fontFamily={fontFamily.plus}
                          fontWeight={500}
                        >
                          {`+ ${val.countryCode}`} {val.phoneNo || "--"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          fontSize={13}
                          fontFamily={fontFamily.plus}
                          fontWeight={500}
                          textTransform={"capitalize"}
                        >
                          {val.school}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          fontSize={13}
                          fontFamily={fontFamily.plus}
                          fontWeight={500}
                        >
                          {val.grade}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          fontSize={13}
                          fontFamily={fontFamily.plus}
                          fontWeight={500}
                        >
                          {val.role}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          fontSize={13}
                          fontFamily={fontFamily.plus}
                          fontWeight={500}
                        >
                          {moment(val.createdAt).format("DD-MM-YYYY")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <Typography
                        fontSize={14}
                        fontWeight={550}
                        fontFamily={fontFamily.roboto}
                        textAlign={"center"}
                      >
                        No Data Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component={"div"}
              count={data?.totalDocs}
              page={page}
              rowsPerPage={pageSize}
              onPageChange={pageChangeHandler}
              onRowsPerPageChange={pageSizeHandler}
            />
          </TableContainer>
        </Box>
      </Box>
    </div>
  );
};

export default Waitlist;
