import { Visibility, VisibilityOff } from "@material-ui/icons";
import React from "react";
import "./index.css";
import { useState } from "react";
const Input = (props) => {
  const [show, setShow] = useState(false);
  if (props.type === "password") {
    const togglePassword = () => {
      setShow(!show);
    };
    return (
      <div className="border_wrapper">
        <input
          type={show ? "text" : "password"}
          placeholder={props.placeholder}
          value={props.value}
          style={{
            width: props.width,
            height: props.height,
            padding: props.padding,
            fontSize: props.fontSize,
            borderRadius: props.rounded,
            border: props.border,
            backgroundColor: props.bg,
            marginLeft: props.ml,
            marginTop: props.mr,
          }}
          id={props.id}
          minLength={props.minLength}
          onChange={props.onChange}
        />
        <div className="icon_eye pointer" onClick={togglePassword}>
          {show ? (
            <VisibilityOff fontSize="small" />
          ) : (
            <Visibility fontSize="small" />
          )}
        </div>
      </div>
    );
  }
  return (
    <input
      className={props.className}
      onChange={props.onChange}
      type={props.type}
      id={props.id}
      style={{
        width: props.width,
        height: props.height,
        padding: props.padding,
        fontSize: props.fontSize,
        borderRadius: props.rounded,
        border: props.border,
        backgroundColor: props.bg,
      }}
      placeholder={props.placeholder}
      value={props.value}
    />
  );
};

export default Input;
